import { Link } from '@mui/material';
import React, { FC } from 'react';

import Logo from '../Logo';

type TResetDone = {
  dataset: {
    loginLink: string;
  };
};

const ResetDone: FC<TResetDone> = ({ dataset }) => {
  return (
    <div className='auth-form'>
      <Logo />
      <h3 className='password-reset'>メール送信が完了しました</h3>
      <p>
        ご登録のメールアドレスに、パスワードリセット用のURLを送信しましたのでご確認ください。
        <br />
        しばらく経ってもメールが配信されない場合、迷惑メールフォルダに入っている場合があります。
      </p>
      <Link href={dataset.loginLink} underline='hover'>
        ログイン画面に戻る
      </Link>
    </div>
  );
};

export default ResetDone;
