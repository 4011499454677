import { FlashMessageType } from './const';

export type TDataSet = {
  modalName: string;
  loading?: boolean;
  link?: string;
  dataLink?: string;
  visitId?: number;
  patientId?: number;
  name?: string;
  patientName?: string;
  birthDate?: string | Date;
  registrationDate?: string | Date;
  hospitalName?: string;
  hospitalPatientId?: string;
  dScore?: string;
  gender?: string;
  email?: string;
  emailConfirm?: string;
  uuidDicom?: string;
  uuidNii?: string;
  prepaidCode?: string;
  brainID?: number;
  reportStatus?: boolean;
  isDisabled?: boolean;
  onAccept?: (removeModalCb?: () => void, disableModalCb?: (value: boolean) => void) => void;
  onReject?: () => void;
};

export type TReportLayoutServer = {
  reportLayoutCompanyName: string | null;
  reportLayoutDoctorName: string | null;
  reportLayoutHospitalName: string | null;
  reportLayoutImg: string | null;
  reportLayoutImgSize: string | null;
  reportLayoutPosition: string | null;
};

export type TVisitTag = {
  full_label: string;
  hospital: string;
  tag_id: number;
  tag_label: string;
};

export type TVisit = {
  availableVisitTags: Array<TVisitTag>;
  birthDate: string | Date;
  gender: 'male' | 'female' | 'other';
  cantabToggle: boolean;
  checkInDate: string | null | Date;
  hospitalPatientId: string;
  hospital_id: number;
  id: number;
  isArchived: boolean;
  isReportConfirmed: boolean;
  patientId: number;
  patientKanjiName: string;
  patientName: string;
  visitMriCount: number;
  prevVisits?: Array<TVisit>;
  registrationDate: string | Date;
  statusCognitive: boolean;
  statusCognitiveDate: string | Date;
  statusMri: boolean;
  statusMriDate: string | Date;
  statusQuestionnaire: boolean;
  statusQuestionnaireDate: string | Date;
  tagId: number;
  visitTagFullLabel: string;
  prepaidCode: string;
  statusAll: string; // Dummy type for proper work of sorting.
};

export type TFormValues = {
  finishedFilter: 'null' | 'mri' | 'report-a' | 'report-a-b';
  searchQuery: string;
  checkInDate: string;
  startDate: string;
  endDate: string;
};

export enum ReportType {
  A = 'A',
  B = 'B',
  S = 'S',
}

export enum MriViewType {
  RAW_AX = 'ax',
  RAW_COR = 'cor',
  RAW_SAG = 'sag',
  RAW_ANIM = 'raw_preview',
}

export enum ContrastType {
  NULL = '',
  //  T1weighted2D = 'T1-weighted (2D)',
  T1weighted3D = 'T1-weighted (3D)',
  T1T2PD2D = 'T1/T2/PD (2D)',
  //  T2PDweighted = 'T2/PD-weighted',
  FLAIR = 'FLAIR',
  MRAngioToF = 'MRAngio (ToF)',
}

export const MRIStatus = {
  RECEIVED: 'received',
  INPROCESS: 'inprocess',
  DONE: 'done',
  FAILED: 'failed',
};
export interface MRIRecord {
  hospitalId: number;
  patientId: number;
  visitId: number;
  mriId: number;
  mriUuid: string;
  acqdate: string;
  normalizedContrast: ContrastType;
  contrast: string;
  status: Lowercase<keyof typeof MRIStatus>;
  institutionName: string;
  seriesDescription: string;
  protocolName: string;
  mriViewerUrl: string;
  mriBrainViewerUrl: string;

  // Useless data
  SaInstanceState: any;
  statusChanged: string;
  created: string;
  md5: string;
  doctorCommentBs: string;
  niiLocation: string;
  doctorCommentLpixel: string;
  hippocampusVolumeL: number;
  status1: string | null;
  status2: string | null;
  status3: string | null;
  status4: string | null;
  status5: string | null;
  hippocampusVolumeR: number;
  eTIV: number;
  manufacturer: string;
  stationName: string;
}
export interface LocalStorageUpdateDetail<T> {
  key: string;
  newValue: T;
}

export interface IDefaultApiResponse {
  msg: string;
  msgUser: string;
  msgCode: string;
  msgType: FlashMessageType;
}
