import axios from 'axios';

const ShowUnreadNotificationInit = async () => {
  const unreadNotificationElements = Array.from(document.querySelectorAll('.unread-notification'));

  if (unreadNotificationElements.length) {
    const response = await axios.get('/api/hospital/notification/unread');
    const { unreadNotification } = response.data;

    unreadNotificationElements.forEach((elem) => {
      elem.innerText = unreadNotification;
      elem.style.display = unreadNotification ? 'flex' : 'none';
    });
  }
};

export default ShowUnreadNotificationInit;
