import Encoding from 'encoding-japanese';
import moment from 'moment';

// List of Tags
// There have some rules to naming!
//   1 - If it's time field, object key must contains `Time`.
//   2 - If it's date field, object key must contains `Date`.
const TAGS = {
  id: '00100020',
  birthDate: '00100030',
  description: '0008103E',
  acquisitionDate: '00080022',
  acquisitionTime: '00080032',
  // acquisitionNumber: `00200012`,
};

// Anonymize data toggle
const anonymizeDataToggle = document.querySelector('#anonymize-data');
if (!anonymizeDataToggle?.checked) {
  TAGS['name'] = '00100010';
}

// Tag Bytes Length
const TAG = 4;
// Value Representation Bytes Length
const VR = 2;
// Value Length Bytes Length
const VL = 2;

const EQUALITY_SYMBOL = 61;
const VALUE_NOT_FOUND_MESSAGE = '-';
const MAX_INDEX_SIZE = 15000;

let offsetIndex = 0;

const hexTagToByteArray = (hexStr) => {
  const result = [];

  for (let i = 0; i < hexStr.length; i += 2) {
    result.push(parseInt(hexStr.substr(i, 2), 16));
  }

  return ([result[0], result[1], result[2], result[3]] = [result[1], result[0], result[3], result[2]]);
};

const byteArrayToString = (byteArray, itsName) => {
  const newByteArray = [];
  let string = '';
  let offsetIndex = 0;
  let detected;

  const decoder = (elem) => {
    detected = Encoding.detect(elem);
    elem = Encoding.convert(elem, {
      to: 'UNICODE',
      from: detected,
      type: 'string',
    });
    string += elem;
  };

  if (itsName) {
    for (let i = 0; i < byteArray.length; i++) {
      if (byteArray[i] === EQUALITY_SYMBOL) {
        newByteArray.push(byteArray.slice(offsetIndex, i));
        newByteArray.push(byteArray.slice(i, i + 1));
        offsetIndex = i + 1;
      }
    }

    newByteArray.push(byteArray.slice(offsetIndex));
    newByteArray.forEach((elem) => decoder(elem));
  } else {
    decoder(byteArray);
  }

  return string.trim();
};

const parseTime = (timeString) => {
  const indexToCut = timeString.indexOf('.');
  const acquisitionTime = timeString.slice(0, indexToCut === -1 ? timeString.length : indexToCut);
  let acqTime = '';

  for (let i = 0; i < acquisitionTime.length; i += 2) {
    acqTime = acqTime + acquisitionTime[i] + acquisitionTime[i + 1] + (i === 4 ? '' : ':');
  }

  return acqTime;
};

const getIndex = (byteArray, byteArrayTag, valueLength = false) => {
  let currentIndex = byteArray.indexOf(byteArrayTag[0], offsetIndex);

  try {
    if (
      valueLength
        ? byteArray[currentIndex + 1] === byteArrayTag[1]
        : byteArray[currentIndex + 1] === byteArrayTag[1] &&
          byteArray[currentIndex + 2] === byteArrayTag[2] &&
          byteArray[currentIndex + 3] === byteArrayTag[3]
    ) {
      offsetIndex = 0;
      return currentIndex;
    }

    offsetIndex = currentIndex + 1;
    return getIndex(byteArray, byteArrayTag, valueLength);
  } catch {
    offsetIndex = 0;
    return false;
  }
};

const getValue = (byteArray, byteArrayTag, itsName = false) => {
  const index = getIndex(byteArray, byteArrayTag);

  if (!index || index > MAX_INDEX_SIZE) {
    return VALUE_NOT_FOUND_MESSAGE;
  }

  const valueLengthIndex = index + TAG + VR;
  const valueLength = byteArray[valueLengthIndex] + byteArray[valueLengthIndex + 1];

  const valueFieldIndexStart = valueLengthIndex + VL;
  let valueFieldIndexEnd = valueFieldIndexStart + valueLength;

  if (!valueLength) {
    offsetIndex = valueFieldIndexStart;
    valueFieldIndexEnd = getIndex(byteArray, byteArrayTag, true);
  }

  const valueFieldByteArray = byteArray.slice(valueFieldIndexStart, valueFieldIndexEnd);
  let result = byteArrayToString(valueFieldByteArray, itsName);

  if (!result.length) {
    return VALUE_NOT_FOUND_MESSAGE;
  }

  return result;
};

const generateResult = (byteArray) => {
  const result = {};

  for (const key in TAGS) {
    const byteArrayTag = hexTagToByteArray(TAGS[key]);
    let value = getValue(byteArray, byteArrayTag, key === 'name' ? true : false);

    if (key.includes('Time')) {
      value = value === VALUE_NOT_FOUND_MESSAGE ? value : parseTime(value);
    }

    if (key.includes('Date')) {
      value = value === VALUE_NOT_FOUND_MESSAGE ? value : moment(`${value}`).format('YYYY-MM-DD');
    }

    result[key] = value;
  }

  return result;
};

export const getMriHeaderInfo = (buffer) => {
  const byteArray = [...new Uint8Array(buffer)];

  return generateResult(byteArray);
};
