const ConfirmationSheetInit = () => {
  if (document.querySelector('.confirmation-sheet')) {
    const MAX_COLUMN = 9;
    const INCREASE_RATIO = 2;
    const selectElement = document.querySelector('[name="visit-selector"]');
    Array.from(selectElement.children).forEach((elem) => {
      if (elem.value === selectElement.dataset.value) elem.selected = true;
    });

    const hideEmptyColumns = () => {
      const columns = [...document.querySelectorAll('.confirmation-sheet__hide')];

      if (columns.length) {
        columns.forEach((column) => {
          column.style.background = 'none';
          column.style.border = 'none';
        });
      }
    };

    const collapseColumns = () => {
      const columns = [...document.querySelectorAll('.confirmation-sheet__empty')];
      const columnLeft = document.querySelector('.confirmation-sheet__empty--left');
      const columnRight = document.querySelector('.confirmation-sheet__empty--right');

      if (columns.length) {
        columns.forEach((column) => {
          column.style.borderLeft = 'none';
          column.style.borderRight = 'none';
        });
      }

      if (columnLeft) {
        columnLeft.style.borderRight = 'none';
      }

      if (columnRight) {
        columnRight.style.borderLeft = 'none';
      }
    };

    const applyExpand = () => {
      const tablesWrappers = [...document.querySelectorAll('.confirmation-sheet__expand')];

      if (tablesWrappers.length) {
        tablesWrappers.forEach((tableWrapper) => {
          if (tableWrapper.innerText.length) {
            const button = tableWrapper.querySelector('button');
            const tableToExpand = [
              ...tableWrapper.querySelectorAll('.confirmation-sheet__wrapper ~ table'),
              ...tableWrapper.querySelectorAll('.confirmation-sheet__wrapper ~ div'),
            ];

            button.addEventListener('click', (evt) => {
              const target = evt.target;

              if (target.classList.contains('confirmation-sheet__button--open')) {
                tableToExpand.forEach((table) => (table.style.display = 'none'));
                target.classList.remove('confirmation-sheet__button--open');
              } else {
                tableToExpand.forEach((table) => (table.style.display = 'table'));
                target.classList.add('confirmation-sheet__button--open');
              }
            });
          }
        });
      }
    };

    const resize = () => {
      const wrapperSize = parseInt(window.getComputedStyle(document.querySelector('.wrapper')).width, 10) / MAX_COLUMN;
      const columns = [...document.querySelectorAll('.confirmation-sheet__width--resize')];
      const columnsDouble = [...document.querySelectorAll('.confirmation-sheet__width--resize-double')];

      if (columns.length) {
        columns.forEach((column) => (column.style.width = `${wrapperSize}px`));
      }

      if (columnsDouble.length) {
        columnsDouble.forEach((column) => (column.style.width = `${wrapperSize * INCREASE_RATIO}px`));
      }
    };

    hideEmptyColumns();
    collapseColumns();
    applyExpand();
    resize();

    selectElement.addEventListener('change', ({ target }) => {
      window.location.href = target.value;
    });
    window.addEventListener('resize', resize);
  }
};

export default ConfirmationSheetInit;
