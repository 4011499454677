import { flashMessageTime } from '../const';

const FlashMessageInit = () =>
  setTimeout(() => {
    const flash = document.querySelector('#flash-message');

    if (flash) {
      flash.style.opacity = 1;

      setInterval(() => {
        if (flash.style.opacity > 0.1) {
          flash.style.opacity -= 0.1;
        } else {
          flash.remove();
        }
      }, 80);
    }
  }, flashMessageTime);

export default FlashMessageInit;
