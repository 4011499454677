import axios from 'axios';
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import moment from 'moment';

import { loadingScreenOn, loadingScreenOff } from '../../components/js/loading-screen';
import { delayForLoadingScreen } from '../const';

const PatientVisitInit = () => {
  const datepicker = document.querySelector('#patient-datepicker');

  if (datepicker) {
    const date = datepicker.querySelector('span');
    date.innerText = moment(date.innerText).format('YYYY-MM-DD HH:mm');
    const picker = flatpickr(datepicker, {
      locale: Japanese,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
    });

    picker.config.onOpen.push(() => picker.setDate(date.innerText));
    picker.config.onClose.push((_, dateStr) => {
      if (date.innerText !== dateStr) {
        loadingScreenOn();
        const form = new FormData();
        form.append('date', dateStr);
        axios
          .post(datepicker.dataset.link, form)
          .then(() => {
            date.innerText = dateStr;
            setTimeout(loadingScreenOff, delayForLoadingScreen);
          })
          .catch(() => {
            setTimeout(loadingScreenOff, delayForLoadingScreen);
          });
      }
    });
  }
};

export default PatientVisitInit;
