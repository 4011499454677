import axios, { AxiosResponse } from 'axios';

import { TVisit } from './types';

const extractIdsFromUrl = () => {
  const url = window.location.href;
  const regex = /\/(\d{3,})\b/g; // Detect minimum 3 digits
  const matches = [...url.matchAll(regex)].map((match) => parseInt(match[1], 10));

  if (matches.length >= 2) {
    return {
      patientId: matches[matches.length - 2],
      visitId: matches[matches.length - 1],
    };
  }
  return {
    patientId: null,
    visitId: null,
  };
};

export const getPatientIdFormUrl = (): number => {
  const { patientId } = extractIdsFromUrl();
  return patientId || 0;
};

export const getVisitIdFormUrl = (): number => {
  const { visitId } = extractIdsFromUrl();
  return visitId || 0;
};

export class Modal {
  private modal: HTMLElement;
  private destroy: boolean;
  private overlay: HTMLDivElement;

  constructor(modal: Element, destroy = false) {
    this.modal = modal as HTMLElement;
    this.destroy = destroy;
    this.overlay = document.querySelector<HTMLDivElement>('#overlay') as HTMLDivElement;

    this.hideModal = this.hideModal.bind(this);
    this.addListener = this.addListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
  }

  hideModal(evt: MouseEvent | KeyboardEvent) {
    if (evt instanceof MouseEvent) {
      const { target } = evt;
      if (target === this.overlay) {
        this.destroy ? this.modal.remove() : (this.modal.style.display = 'none');
        this.overlay.style.display = 'none';
        this.removeListener();
        return;
      }
    }

    if (evt instanceof KeyboardEvent) {
      const { key } = evt;
      if (key === 'Escape') {
        this.destroy ? this.modal.remove() : (this.modal.style.display = 'none');
        this.overlay.style.display = 'none';
        this.removeListener();
      }
    }
  }

  addListener() {
    this.overlay.style.display = 'block';
    this.modal.style.display = 'flex';
    document.addEventListener('keydown', this.hideModal);
    document.addEventListener('click', this.hideModal);
  }

  removeListener() {
    this.overlay.style.display = 'none';
    this.destroy ? this.modal.remove() : (this.modal.style.display = 'none');
    document.removeEventListener('keydown', this.hideModal);
    document.removeEventListener('click', this.hideModal);
  }
}

export const formattingFields = (data: Array<TVisit>) => {
  data.forEach((elem) => {
    elem.birthDate = new Date(elem.birthDate);
    elem.statusCognitiveDate = new Date(elem.statusCognitiveDate);
    elem.statusMriDate = new Date(elem.statusMriDate);
    elem.statusQuestionnaireDate = new Date(elem.statusQuestionnaireDate);
    elem.registrationDate = new Date(elem.registrationDate);
    elem.checkInDate = elem.checkInDate ? new Date(elem.checkInDate) : null;
    elem.hospitalPatientId = elem.hospitalPatientId && elem.hospitalPatientId.length >= 32 ? '' : elem.hospitalPatientId;
  });
  return data;
};

// Set Intersection Observer for overlay
const overlay = document.querySelector<HTMLDivElement>('#overlay');
export const overlayObserver = new IntersectionObserver((entries) => {
  if (entries[entries.length - 1].isIntersecting || Array.from(document.querySelectorAll('[data-react-modal]')).length) {
    overlay!.style.display = 'block';
  } else {
    overlay!.style.display = 'none';
  }
});

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const generateReportLink = (url: string, method: 'get' | 'post' = 'get'): Promise<Array<string | undefined>> =>
  new Promise((resolve, reject) => {
    axios({
      url,
      method,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
      .then(({ data, headers }: AxiosResponse) => {
        const contentType = headers['content-type'];
        const contentDisposition = headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        let fileName = '';
        if (match) {
          fileName = match[1].replace(/['"]/g, '');
        }
        const content = window.URL.createObjectURL(new Blob([data], { type: contentType }));
        resolve([content, fileName, undefined]);
      })
      .catch((err) => {
        let message = 'Server not response.';
        if (err?.response?.data) {
          const data = new TextDecoder('utf-8').decode(new Uint8Array(err.response.data));
          if (data.includes('No such file or directory')) {
            message = "Please reupload MRI's.";
          } else {
            message = JSON.parse(data).msgUser;
          }
        }

        reject([undefined, undefined, message]);
      });
  });

const modeElement = document.querySelector<HTMLDivElement>('#h-mode')?.innerText || '';
export const isDevMode = (): boolean => modeElement === 'development';

export const STORAGE_KEY_PATIENT_INFO = `${getPatientIdFormUrl()}_${getVisitIdFormUrl()}_patient_info`;
export const STORAGE_KEY_PATIENT_MRI = `${getPatientIdFormUrl()}_${getVisitIdFormUrl()}_patient_mri`;
