import React, { FC } from 'react';

import { TDataSet } from '../types';

type TQRCodeModal = {
  dataset: TDataSet;
};

const QRCodeModal: FC<TQRCodeModal> = ({ dataset }) => {
  const { link, hospitalPatientId } = dataset;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p style={{ fontSize: '1.125rem', fontWeight: 700 }}>院内ID: {hospitalPatientId}</p>
      <img src={link} alt='QRコード' />
      <p>オンラインテストを提供するにはこのQRコードをスキャンしてください。</p>
    </div>
  );
};

export default QRCodeModal;
