import React, { FC } from 'react';

import { TPatientPersonalInfoWarningModal } from './PatientPersonalInfoWarningModal.types';

const PatientPersonalInfoWarningModal: FC<TPatientPersonalInfoWarningModal> = ({ dataset, removeModal, removeModalLight }) => {
  const { onAccept, onReject } = dataset;
  return (
    <div className='react-components-ppiwm'>
      <h3>編集した情報を保存しますか？</h3>
      <p>※「保存しない」をクリックすると編集した情報は削除します。</p>
      <div style={{ display: 'flex', gap: '12px' }}>
        <button
          className='btn btn--additional'
          type='button'
          onClick={() => {
            removeModal(true);
            if (onReject) onReject();
          }}
        >
          保存しない
        </button>
        <button
          className='btn btn--main'
          type='button'
          onClick={() => {
            removeModalLight();
            if (onAccept) onAccept();
          }}
        >
          保存
        </button>
      </div>
    </div>
  );
};

export default PatientPersonalInfoWarningModal;
