import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import API from '../API';
import { FlashMessageType } from '../const';

type TReportLayout = {
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const InputLabelNames = {
  Company: '法人名',
  Hospital: '病院名',
  Doctor: '医師名',
};

const ReportLayout: FC<TReportLayout> = ({ isDisabled, setIsDisabled, removeModal }) => {
  const [formValues, setFormValues] = useState({
    companyName: '',
    hospitalName: '',
    doctorName: '',
  });

  useEffect(() => {
    setIsDisabled(true);

    API.getReportLayout()
      .then(({ data }) =>
        setFormValues({
          companyName: data.reportLayoutCompanyName || '',
          hospitalName: data.reportLayoutHospitalName || '',
          doctorName: data.reportLayoutDoctorName || '',
        }),
      )
      .catch(() => {})
      .finally(() => setIsDisabled(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setIsDisabled(true);

    API.setReportLayout(formValues)
      .then((response) => {
        const { data } = response;
        FlashMessageCreate({ message: data.msgUser, type: FlashMessageType.Success, isSelfDestroyable: true });
        removeModal();
      })
      .catch((err) => {
        let message = 'サーバーでエラーが発生しました。管理者にご連絡ください。';
        if (err.response?.data) {
          message = err.response.data.msgUser;
        }
        FlashMessageCreate({ message, type: FlashMessageType.Error, isSelfDestroyable: true });
        setIsDisabled(false);
      });
  };

  return (
    <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
      <h2 style={{ marginTop: 0, textAlign: 'center' }}>表紙レイアウトの変更</h2>
      <FormControl sx={{ mb: 1 }} variant='outlined' size='small' disabled={isDisabled}>
        <InputLabel htmlFor='companyName'>{InputLabelNames.Company}</InputLabel>
        <OutlinedInput
          id='companyName'
          name='companyName'
          value={formValues.companyName}
          label={InputLabelNames.Company}
          onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        />
      </FormControl>
      <FormControl sx={{ mb: 1 }} variant='outlined' size='small' disabled={isDisabled}>
        <InputLabel htmlFor='hospitalName'>{InputLabelNames.Hospital}</InputLabel>
        <OutlinedInput
          id='hospitalName'
          name='hospitalName'
          value={formValues.hospitalName}
          label={InputLabelNames.Hospital}
          onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        />
      </FormControl>
      <FormControl sx={{ mb: 1 }} variant='outlined' size='small' disabled={isDisabled}>
        <InputLabel htmlFor='doctorName'>{InputLabelNames.Doctor}</InputLabel>
        <OutlinedInput
          id='doctorName'
          name='doctorName'
          value={formValues.doctorName}
          label={InputLabelNames.Doctor}
          onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        />
      </FormControl>
      <div style={{ display: 'flex' }}>
        <button style={{ marginRight: '8px' }} className='btn btn--additional btn--fix' type='button' disabled={isDisabled} onClick={removeModal}>
          キャンセル
        </button>
        <button className={`btn btn--main btn--fix ${isDisabled ? 'btn--loading' : ''}`} type='submit' disabled={isDisabled}>
          確定
        </button>
      </div>
    </form>
  );
};

export default ReportLayout;
