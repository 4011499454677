import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IDefaultApiResponse, MriViewType, ReportType, TFormValues, TReportLayoutServer, TVisit } from './types';
import { getPatientIdFormUrl, getVisitIdFormUrl } from './utils';

class API {
  private URN_BASE: string;
  private URN_API: string;
  private URN_VISITS: string;
  private URN_EMPLOYEES: string;
  private URN_FILTER_VISITS: string;
  private URN_ARCHIVED_VISITS: string;
  private URN_CHECKIN_VISITS: string;
  private URN_TAG_VISITS: string;
  private URN_TRANSFER_VISITS: string;
  private URN_REPORT: string;
  private URN_LAYOUT: string;
  private DRAG_DROP: string;
  private DRAG_DROP_CONTRAST: string;
  private DRAG_DROP_PATIENT: string;
  private DRAG_DROP_VISIT: string;
  private DRAG_DROP_IMG: string;
  private REGISTRATION: string;
  private REGISTRATION_QR_CODE: string;
  private REGISTRATION_EMAIL: string;
  private REGISTRATION_VISIT: string;
  private DOCTOR_COMMENT: string;
  private PREPAID: string;
  private PREPAID_VALIDATE: string;
  private PREPAID_REGISTER: string;
  private URN_API_PATIENT: string;
  private api: AxiosInstance;

  constructor() {
    this.URN_BASE = window.location.origin;
    this.URN_API = 'api/hospital';
    this.URN_VISITS = 'visits';
    this.URN_EMPLOYEES = 'employees';
    this.URN_FILTER_VISITS = `${this.URN_VISITS}/filter`;
    this.URN_ARCHIVED_VISITS = 'archive';
    this.URN_CHECKIN_VISITS = 'check-in';
    this.URN_TAG_VISITS = 'visittag';
    this.URN_TRANSFER_VISITS = 'transfer';
    this.URN_REPORT = 'report';
    this.URN_LAYOUT = 'layout';
    this.DRAG_DROP = 'mri-drag-drop';
    this.DRAG_DROP_CONTRAST = `${this.DRAG_DROP}/contrast`;
    this.DRAG_DROP_PATIENT = 'patient';
    this.DRAG_DROP_VISIT = 'visit';
    this.DRAG_DROP_IMG = 'img';
    this.REGISTRATION = 'registration';
    this.REGISTRATION_QR_CODE = 'qrcode';
    this.REGISTRATION_EMAIL = 'email';
    this.REGISTRATION_VISIT = 'visit';
    this.DOCTOR_COMMENT = 'doctor-comment';
    this.PREPAID = 'prepaid';
    this.PREPAID_VALIDATE = `${this.PREPAID}/validate`;
    this.PREPAID_REGISTER = `${this.PREPAID}/register`;
    this.URN_API_PATIENT = 'patient';

    this.api = axios.create({
      baseURL: `${this.URN_BASE}/${this.URN_API}`,
    });
  }

  // Visits part.
  getVisits(): Promise<AxiosResponse<Array<TVisit>>> {
    return this.api.get(this.URN_VISITS);
  }

  getFilteredVisits(data: TFormValues): Promise<AxiosResponse<Array<TVisit>>> {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value);
    }
    return this.api.post(this.URN_FILTER_VISITS, formData);
  }

  setVisitsArchived(patientId: number, visitId: number, value: boolean) {
    return this.api.put(`${this.URN_VISITS}/${patientId}/${visitId}/${this.URN_ARCHIVED_VISITS}`, { value });
  }

  setVisitsCheckin(patientId: number, visitId: number, value: boolean) {
    return this.api.put(`${this.URN_VISITS}/${patientId}/${visitId}/${this.URN_CHECKIN_VISITS}`, { value });
  }

  updateVisitTag(patientId: number, visitId: number, value: string) {
    return this.api.put(`${this.URN_VISITS}/${patientId}/${visitId}/${this.URN_TAG_VISITS}`, { value });
  }

  removeVisit(patientId: number, visitId: number) {
    return this.api.delete(`${this.URN_VISITS}/${patientId}/${visitId}/${this.URN_TRANSFER_VISITS}`);
  }

  getReport(type: ReportType, patientId: number, visitId: number, config?: AxiosRequestConfig) {
    return this.api.get(`${this.URN_REPORT}/${type}/${patientId}/${visitId}`, config);
  }

  // Employees part.
  getEmployees() {
    return this.api.get(this.URN_EMPLOYEES);
  }

  createEmployees(email: string) {
    return this.api.post(this.URN_EMPLOYEES, { email });
  }

  resetEmployees(id: number) {
    return this.api.put(this.URN_EMPLOYEES, { employee_id: id });
  }

  deleteEmployees(id: number) {
    return this.api.delete(this.URN_EMPLOYEES, { data: { employee_id: id } });
  }

  // Report layout part.
  getReportLayout(): Promise<AxiosResponse<TReportLayoutServer>> {
    return this.api.get(this.URN_LAYOUT);
  }

  setReportLayout(data: any) {
    return this.api.post(this.URN_LAYOUT, data);
  }

  // Drag&Drop part.
  sendMRI<T>(data: FormData, config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.api.post(this.DRAG_DROP, data, config);
  }

  setContrast(data: any) {
    return this.api.post(this.DRAG_DROP_CONTRAST, data);
  }

  getImage(type: MriViewType, uuid: string): string {
    return `${this.URN_BASE}/${this.URN_API}/${this.DRAG_DROP}/${this.DRAG_DROP_IMG}/${type}/${uuid}`;
  }

  getMatchPatients<T = any, K = any>(params: T): Promise<AxiosResponse<K>> {
    return this.api.get(this.DRAG_DROP, { params });
  }

  setPatient(data: FormData) {
    return this.api.post(`${this.DRAG_DROP}/${this.DRAG_DROP_PATIENT}`, data);
  }

  setVisit(data: FormData) {
    return this.api.post(`${this.DRAG_DROP}/${this.DRAG_DROP_VISIT}`, data);
  }

  // Registration part.
  getQrcode(border?: number) {
    return `${this.URN_BASE}/${this.URN_API}/${this.REGISTRATION}/${this.REGISTRATION_QR_CODE}${border !== undefined ? `?border=${border}` : ''}`;
  }

  sendEmail(data: FormData) {
    return this.api.post(`${this.REGISTRATION}/${this.REGISTRATION_EMAIL}`, data);
  }

  setPatientManual(data: FormData) {
    return this.api.post(`${this.REGISTRATION}`, data);
  }

  setNewVisit<T>(patientId: number, visitId: number): Promise<AxiosResponse<IDefaultApiResponse & T, IDefaultApiResponse & T>> {
    return this.api.post(`${this.REGISTRATION}/${this.REGISTRATION_VISIT}/${patientId}/${visitId}`);
  }

  // Doctor comment
  setDoctorComment(patientId: number, visitId: number, data: FormData) {
    return this.api.post(`${this.DOCTOR_COMMENT}/${patientId}/${visitId}`, data);
  }

  // Prepaid part
  setPrepaidValidate(data: FormData) {
    return this.api.post(this.PREPAID_VALIDATE, data);
  }

  setPrepaidRegister(patientId: number, visitId: number, data: FormData) {
    return this.api.post(`${this.PREPAID_REGISTER}/${patientId}/${visitId}`, data);
  }

  removePrepaidRegister(patientId: number, visitId: number, data: FormData) {
    return this.api.delete(`${this.PREPAID_REGISTER}/${patientId}/${visitId}`, { data });
  }

  // Patient part
  getPatientInfo() {
    return this.api.get(`${this.URN_API_PATIENT}/${getPatientIdFormUrl()}/${getVisitIdFormUrl()}`);
  }

  setPatientInfo(data: FormData) {
    return this.api.post(`${this.URN_API_PATIENT}/${getPatientIdFormUrl()}/${getVisitIdFormUrl()}`, data);
  }
}

export default new API();
