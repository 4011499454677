import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import API from '../API';
import { MainModalCreate } from '../MainModal';
import { getGender } from '../components_tmp/patient-register/PatientRegisterConfirmation';
import { FlashMessageType } from '../const';

// Types
import { TVisit } from '../types';
import { isDevMode } from '../utils';

type TPatientTableList = {
  tableHeight: number;
  setTableHeight: (value: number) => void;
  setVisits: (data: Array<TVisit>) => void;
  visits: Array<TVisit> | undefined;
};
type TPatientTableRow = {
  visit: TVisit;
  handleClickDelete: (value: TVisit) => void;
  order: TOrder;
  orderBy: TOrderBy;
};
type TStatusIconBlock = {
  visit: TVisit;
  tooltip: string;
  whichStatus: 'statusMri' | 'statusQuestionnaire' | 'statusCognitive' | 'prepaidCode';
  size?: 'small' | 'inherit' | 'medium' | 'large' | undefined;
};
type TOrder = 'asc' | 'desc';
type TOrderBy =
  | 'patientKanjiName'
  | 'visitTagFullLabel'
  | 'hospitalPatientId'
  | 'registrationDate'
  | 'isReportConfirmed'
  | 'statusMri'
  | 'statusMriDate'
  | 'statusQuestionnaire'
  | 'statusCognitive'
  | 'prepaidCode';
interface THeadCell {
  id?: TOrderBy;
  label: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

const headCells: readonly THeadCell[] = [
  {
    id: undefined,
    label: '',
    align: 'center',
  },
  {
    id: 'patientKanjiName',
    label: '受診者名',
    align: 'left',
  },
  {
    id: 'hospitalPatientId',
    label: '院内ID',
    align: 'center',
  },
  {
    id: 'registrationDate',
    label: 'BrainSuite登録日',
    align: 'right',
  },
  {
    id: 'visitTagFullLabel',
    label: '受診回数',
    align: 'right',
  },
  {
    id: 'statusMriDate',
    label: 'MRI撮影日',
    align: 'right',
  },
  {
    id: 'isReportConfirmed',
    label: '結果確定',
    align: 'right',
  },
  {
    id: 'statusQuestionnaire',
    label: '問診',
    align: 'right',
  },
  {
    id: 'statusCognitive',
    label: 'テスト',
    align: 'right',
  },
  {
    id: 'prepaidCode',
    label: 'チケット番号',
    align: 'right',
  },
  {
    id: undefined,
    label: '登録削除',
    align: 'center',
  },
];

const handleClickStopPropagation = (evt: MouseEvent<HTMLElement>) => {
  evt.stopPropagation();
};
const convertDate = (date: moment.MomentInput) => {
  const parsedDate = moment(date);
  if (parsedDate.isValid()) {
    return parsedDate.format('YYYY-MM-DD');
  }
  return '--';
};
const descendingComparator = (a: TVisit, b: TVisit, orderBy: TOrderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
const getComparator = (order: TOrder, orderBy: TOrderBy) => {
  if (orderBy === 'patientKanjiName' || orderBy === 'visitTagFullLabel' || orderBy === 'hospitalPatientId') {
    return order === 'asc'
      ? (a: TVisit, b: TVisit) => a[orderBy].localeCompare(b[orderBy])
      : (a: TVisit, b: TVisit) => b[orderBy].localeCompare(a[orderBy]);
  }

  if (orderBy === 'prepaidCode') {
    return order === 'asc'
      ? (a: TVisit, b: TVisit) => (a.prepaidCode ? 1 : -1) - (b.prepaidCode ? 1 : -1)
      : (a: TVisit, b: TVisit) => (b.prepaidCode ? 1 : -1) - (a.prepaidCode ? 1 : -1);
  }

  return order === 'asc'
    ? (a: TVisit, b: TVisit) => descendingComparator(a, b, orderBy)
    : (a: TVisit, b: TVisit) => -descendingComparator(a, b, orderBy);
};

const StatusIconBlock: FC<TStatusIconBlock> = ({ visit, size, whichStatus }) => (
  <Tooltip title={whichStatus === 'prepaidCode' ? visit.prepaidCode || '' : visit[whichStatus] ? convertDate(visit[`${whichStatus}Date`]) : ''} arrow>
    <SquareRoundedIcon color={visit[whichStatus] ? 'primary' : 'disabled'} fontSize={size} />
  </Tooltip>
);

const PatientTableRow: FC<TPatientTableRow> = ({ visit, handleClickDelete, order, orderBy }) => {
  const parentRowElement = useRef(document.querySelector<HTMLTableRowElement>('#parent-table-row'));
  const childRowElement = useRef(document.querySelector<HTMLTableRowElement>('#child-row-element'));
  const [open, setOpen] = useState(false);
  const [tableMargin, setTableMargin] = useState(50);

  useEffect(() => setOpen(false), [order, orderBy]);
  useEffect(() => {
    if (parentRowElement.current && childRowElement.current && visit.prevVisits?.length) {
      const allColumnsParent = Array.from(parentRowElement.current.querySelectorAll('td'));
      const allColumnChild = Array.from(childRowElement.current.querySelectorAll('table tr:nth-child(1) td')) as Array<HTMLTableColElement>;
      const columnsForMargin = allColumnsParent.slice(0, 4);
      const columnsForAlignment = allColumnsParent.slice(4);
      if (allColumnChild.length) {
        columnsForAlignment.forEach((parentElement, i) => {
          allColumnChild[i].style.paddingRight = window.getComputedStyle(parentElement).paddingRight;
          allColumnChild[i].style.paddingLeft = window.getComputedStyle(parentElement).paddingLeft;
          allColumnChild[i].style.width = window.getComputedStyle(parentElement).width;
        });
      }
      const margin = columnsForMargin.reduce((accumulator, { clientWidth }) => accumulator + clientWidth, 0);
      setTableMargin(margin);
    }
  }, [open, visit.prevVisits?.length]);

  return (
    <>
      <TableRow
        onClick={() => (window.location.href = `${window.location.href}/${visit.patientId}/${visit.id}`)}
        hover
        ref={parentRowElement}
        id={visit.prevVisits?.length ? 'parent-table-row' : undefined}
        sx={{
          '& > td': {
            borderBottom: visit.prevVisits?.length ? 'unset' : '',
          },
          boxShadow: open ? 'inset 5px 0px 0px 0px rgb(0 185 239 / 50%)' : undefined,
          cursor: 'pointer',
        }}
      >
        {/* Button open */}
        <TableCell
          onClick={(event) => {
            setOpen(!open);
            event.stopPropagation();
          }}
          align='center'
        >
          {visit.prevVisits?.length ? (
            <>
              <span style={{ color: 'var(--color-cerulean)' }}>({visit.prevVisits.length}回)</span>
              <Tooltip title={open ? '閉じる' : '開く'} arrow>
                <IconButton size='small' color='primary'>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </TableCell>
        {/* Button open */}

        <TableCell>
          <div style={{ fontWeight: 600 }}>{`${visit.patientName}` ? `${visit.patientName}` : visit.patientKanjiName || ''}</div>
          <div style={{ fontSize: '11px' }}>{`${convertDate(visit.birthDate)} (${getGender(visit.gender)})`}</div>
        </TableCell>
        <TableCell align='center'>{visit.hospitalPatientId}</TableCell>
        <TableCell align='center'>{convertDate(visit.registrationDate)}</TableCell>
        <TableCell align='center'>{visit.visitTagFullLabel ? visit.visitTagFullLabel : '--'}</TableCell>
        <TableCell align='center' sx={{ p: 0 }}>
          {convertDate(visit.statusMriDate)}
          {isDevMode() && visit.visitMriCount > 1 ? ` (+${visit.visitMriCount - 1})` : null}
        </TableCell>
        <TableCell align='center'>
          {visit.isReportConfirmed ? <SquareRoundedIcon color='primary' fontSize='small' /> : <SquareRoundedIcon color='disabled' fontSize='small' />}
        </TableCell>
        <TableCell align='center' sx={{ p: 0 }}>
          <StatusIconBlock visit={visit} size='small' whichStatus='statusQuestionnaire' tooltip='オプション検査' />
        </TableCell>
        <TableCell align='center' sx={{ p: 0 }}>
          <StatusIconBlock visit={visit} size='small' whichStatus='statusCognitive' tooltip='オプション検査' />
        </TableCell>
        <TableCell align='center'>
          <StatusIconBlock visit={visit} size='small' whichStatus='prepaidCode' tooltip='チケット番号' />
        </TableCell>
        <TableCell align='center' onClick={handleClickStopPropagation}>
          <Tooltip title={'削除'} arrow>
            <span>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  handleClickDelete(visit);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      {visit.prevVisits?.length ? (
        <TableRow ref={childRowElement} id='child-row-element' sx={{ boxShadow: open ? 'inset 5px 0px 0px 0px rgb(0 185 239 / 50%)' : undefined }}>
          <TableCell sx={{ p: 0 }} colSpan={headCells.length}>
            <Collapse in={open} timeout='auto' unmountOnExit sx={{ m: 0, ml: `${tableMargin}px` }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  {visit.prevVisits.map((prevVisit, i) => (
                    <TableRow
                      onClick={() => (window.location.href = `${window.location.href}/${prevVisit.patientId}/${prevVisit.id}`)}
                      hover
                      key={prevVisit.id}
                      sx={{
                        '& > td': {
                          borderBottom: visit.prevVisits!.length - 1 === i ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
                          borderTop: 0 !== i ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
                        },
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell align='center'>{prevVisit.visitTagFullLabel ? prevVisit.visitTagFullLabel : '--'}</TableCell>
                      <TableCell align='center' sx={{ p: 0 }}>
                        {convertDate(prevVisit.statusMriDate)}
                        {isDevMode() && prevVisit.visitMriCount > 1 ? ` (+${prevVisit.visitMriCount - 1})` : null}
                      </TableCell>
                      <TableCell align='center'>
                        {prevVisit.isReportConfirmed ? (
                          <SquareRoundedIcon color='primary' fontSize='small' />
                        ) : (
                          <SquareRoundedIcon color='disabled' fontSize='small' />
                        )}
                      </TableCell>
                      <TableCell align='center' sx={{ p: 0 }}>
                        <StatusIconBlock visit={prevVisit} size='small' whichStatus='statusQuestionnaire' tooltip='オプション検査' />
                      </TableCell>
                      <TableCell align='center' sx={{ p: 0 }}>
                        <StatusIconBlock visit={prevVisit} size='small' whichStatus='statusCognitive' tooltip='オプション検査' />
                      </TableCell>
                      <TableCell align='center'>
                        <StatusIconBlock visit={prevVisit} size='small' whichStatus='prepaidCode' tooltip='チケット番号' />
                      </TableCell>
                      <TableCell align='center' onClick={handleClickStopPropagation}>
                        <Tooltip title={prevVisit.prepaidCode ? '予約番号がある場合は削除できません' : '削除'} arrow>
                          <span>
                            <IconButton
                              aria-label='delete'
                              disabled={prevVisit.prepaidCode ? true : false}
                              onClick={() => {
                                handleClickDelete(prevVisit);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

const PatientTableList: FC<TPatientTableList> = ({ setVisits, visits, tableHeight, setTableHeight }) => {
  const [order, setOrder] = useState<TOrder>('asc');
  const [orderBy, setOrderBy] = useState<TOrderBy>('registrationDate');
  const tableHeadRow = useRef<HTMLTableRowElement>(document.querySelector('#table-row-for-sticky'));

  const handleClickSort = (property: TOrderBy = orderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickDelete = (visit: TVisit) => {
    MainModalCreate({
      modalName: 'removeVisit',
      name: visit.patientName.length ? `${visit.patientName}` : visit.patientKanjiName,
      birthDate: visit.birthDate,
      registrationDate: visit.registrationDate,
      hospitalPatientId: visit.hospitalPatientId,
      onAccept: (removeModalCb, disableModalCb) => {
        if (disableModalCb) disableModalCb(true);

        let flashMessage = '';
        let flashType = FlashMessageType.Success;

        API.removeVisit(visit.patientId, visit.id)
          .then(async (res) => {
            flashMessage = res.data.msgUser;
            // TODO: Need to make some checks on case when request will aborted.
            const response = await API.getVisits();
            const { data } = response;
            setVisits(data);
          })
          .catch((err) => {
            flashMessage = err.response.data.msgUser;
            flashType = FlashMessageType.Error;
          })
          .finally(() => {
            FlashMessageCreate({ message: flashMessage, type: flashType, isSelfDestroyable: true });
            if (removeModalCb) removeModalCb();
          });
      },
    });
  };

  const resize = () => {
    const OFFSET = 15;
    const resizeHeaderElement = document.querySelector<HTMLElement>('header.header');
    const resizeHeadingElement = document.querySelector<HTMLHeadingElement>('div#react-table h2');
    const resizeFormElement = document.querySelector<HTMLFormElement>('div#react-table form');
    const resizeHeaderElementStyles = window.getComputedStyle(resizeHeaderElement as Element);
    const resizeHeadingElementStyles = window.getComputedStyle(resizeHeadingElement as Element);
    let otherHeights =
      parseInt(resizeHeaderElementStyles.marginBottom, 10) +
      (resizeHeaderElement?.offsetHeight || 0) +
      parseInt(resizeHeadingElementStyles.marginBottom, 10) +
      (resizeHeadingElement?.offsetHeight || 0) +
      (resizeFormElement?.offsetHeight || 0);

    setTableHeight(window.innerHeight - otherHeights - OFFSET);
  };

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hospitalId = document.querySelector<HTMLDivElement>('#h-id')?.innerText;
    if (localStorage.getItem(`${hospitalId}_openModal`) === 'registrationModal') {
      MainModalCreate(
        {
          modalName: 'registrationModal',
        },
        {
          minWidth: '50vw',
          minHeight: '30vh',
        },
      );
      localStorage.removeItem(`${hospitalId}_openModal`);
    }
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: `${tableHeight}px` }} id='patient-table-list'>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        {visits === undefined ? <caption style={{ textAlign: 'center', fontSize: '20px' }}>処理中...</caption> : null}
        {Array.isArray(visits) && visits.length === 0 ? (
          <caption style={{ textAlign: 'center', fontSize: '20px' }}>受診者登録がありません。</caption>
        ) : null}
        <TableHead>
          <TableRow ref={tableHeadRow} id='table-row-for-sticky'>
            {headCells.map((headCell) => (
              <TableCell align={headCell.align} key={headCell.label}>
                <Box
                  sx={{
                    transform: headCell.id && headCell.id !== 'patientKanjiName' ? 'translateX(0.5rem)' : 'none',
                  }}
                >
                  {headCell.id !== undefined ? (
                    <Tooltip title='並び替え' arrow>
                      <TableSortLabel
                        sx={{ flexDirection: 'row', '&.Mui-active .MuiTableSortLabel-icon': { color: 'primary.light' } }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleClickSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </Tooltip>
                  ) : (
                    headCell.label
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {visits?.sort(getComparator(order, orderBy)).map((visit) => (
            <PatientTableRow key={visit.id} visit={visit} handleClickDelete={handleClickDelete} order={order} orderBy={orderBy} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientTableList;
