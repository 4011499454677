import { createTheme, ThemeProvider } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import PatientTableDragDrop from './PatientTableDragDrop';
import PatientTableList from './PatientTableList';
import PatientTableSearchForm from './PatientTableSearchForm';

import API from '../API';

// Types
import { TVisit } from '../types';
import { formattingFields } from '../utils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#009EE5',
    },
  },
});

const PatientTable: FC = () => {
  const [visits, setVisits] = useState<Array<TVisit> | undefined>(undefined);
  const [tableHeight, setTableHeight] = useState(100);
  const setVisitsFormatted = (data: Array<TVisit>) => setVisits(formattingFields(data));

  // Small hack to communicate with other independent React parts.
  window.PatientTable.update = () => {
    API.getVisits().then(({ data }) => setVisitsFormatted(data));
  };

  useEffect(() => {
    API.getVisits().then(({ data }) => setVisitsFormatted(data));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <h2 style={{ marginTop: 0, fontSize: '20px' }}>受診者一覧</h2>
      <PatientTableSearchForm setVisits={setVisitsFormatted} />
      <PatientTableList setVisits={setVisitsFormatted} visits={visits} tableHeight={tableHeight} setTableHeight={setTableHeight} />
      <PatientTableDragDrop visits={visits} tableHeight={tableHeight} />
    </ThemeProvider>
  );
};

export default PatientTable;
