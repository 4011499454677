import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { FC, useState } from 'react';

import Logo from '../Logo';

const InputLabelNames = {
  password1: 'パスワード',
  password2: '再⼊⼒(確認)',
};

const Reset: FC = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <form className='auth-form' method='post'>
      <Logo />
      <h3>新しいパスワード</h3>
      <p>任意のパスワード※英大文字・数字をそれぞれ最低１文字以上含み、８文字以上である必要があります。</p>
      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='password1'>{InputLabelNames.password1}</InputLabel>
        <OutlinedInput
          id='password1'
          name='password1'
          type={showPassword ? 'text' : 'password'}
          label={InputLabelNames.password1}
          autoComplete='new-password'
          required
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='password2'>{InputLabelNames.password2}</InputLabel>
        <OutlinedInput
          id='password2'
          name='password2'
          type={showPassword ? 'text' : 'password'}
          label={InputLabelNames.password2}
          autoComplete='new-password'
          required
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <button className='btn btn--main btn--fix' type='submit'>
        送信
      </button>
    </form>
  );
};

export default Reset;
