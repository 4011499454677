import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Alert, AlertTitle, createTheme, IconButton, ThemeProvider } from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';

import { Colors, flashMessageTime, FlashMessageType } from '../const';

const theme = createTheme({
  palette: {
    error: {
      main: Colors.burntSienna,
    },
    warning: {
      main: Colors.orangePeel,
    },
    success: {
      main: Colors.fruitSalad,
    },
    info: {
      main: Colors.ceruleanDark,
    },
  },
});

type TFlashMessage = {
  title?: string;
  message?: string;
  acceptMessage?: string;
  rejectMessage?: string;
  type?: FlashMessageType;
  onAccept?: () => void;
  onReject?: () => void;
  onFinally?: () => void;
  isSelfDestroyable?: boolean;
  time?: number;
};

enum FlashMessageJpNames {
  warning = '警告',
  error = 'エラー',
  success = '成功',
  info = '情報',
}

/**
 * FlashMessage component that can be used in React.
 *
 * @typedef {Object} props
 * @param props.message Flash message text which will appear inside use '\n' to move text on new line.
 * @param props.acceptMessage Accept button text default 'Accept'
 * @param props.rejectMessage Reject button text default 'Reject'
 * @param props.type Type of flash message described in 'FlashMessageType'.
 * @param props.onAccept Callback function which will call when 'Accept' button will pressed.
 * @param props.onReject Callback function which will call when 'Reject' button will pressed.
 * @param props.onFinally Callback function which will call on pressing 'Accept'|'Reject' buttons or when 'isSelfDestroyable = true'.
 * @param props.isSelfDestroyable When its 'true' flash message will be self disappear by end of the time.
 * @param props.time Time after which 'flash message' will disappear. Default 'flashMessageTime'.
 *
 * @returns FunctionComponent
 */
export const FlashMessage: FC<TFlashMessage> = ({
  title,
  message,
  acceptMessage = 'OK',
  rejectMessage = 'やめる',
  type = FlashMessageType.Success,
  onAccept,
  onReject,
  onFinally,
  isSelfDestroyable = false,
  time = flashMessageTime,
}: TFlashMessage) => {
  const root = useRef(document.querySelector('#flash-message-react'));

  const onFlashMessageClick = (cb?: () => void) => {
    root.current?.remove();
    root.current = null;
    if (cb) cb();
    if (onFinally) onFinally();
  };

  useEffect(() => {
    if (!isSelfDestroyable) return;

    setTimeout(onFlashMessageClick, time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Alert className={`flash-message flash-message--${type}`} sx={{ boxShadow: 3 }} severity={type}>
      <ThemeProvider theme={theme}>
        <AlertTitle>{title || FlashMessageJpNames[type]}</AlertTitle>
        <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: message || '' }} />
        {onAccept || onReject ? (
          <div className='flash-message__btn-wrapper'>
            {onReject ? (
              <button
                className='flash-message__reject-btn btn btn--fix'
                onClick={() => onFlashMessageClick(onReject)}
                type='button'
                aria-label='reject-btn'
              >
                {rejectMessage}
              </button>
            ) : null}
            {onAccept ? (
              <button
                className='flash-message__accept-btn btn btn--fix'
                onClick={() => onFlashMessageClick(onAccept)}
                type='button'
                aria-label='accept-btn'
              >
                {acceptMessage}
              </button>
            ) : null}
          </div>
        ) : null}
        <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => onFlashMessageClick()} color={type}>
          <HighlightOffIcon />
        </IconButton>
      </ThemeProvider>
    </Alert>
  );
};

/**
 * Creates FlashMessage. Can be used outside of React.
 *
 * @typedef {Object} settings
 * @param settings.title Flash message title which will appear on top of the flash message.
 * @param settings.message Flash message text which will appear inside use '\n' to move text on new line.
 * @param settings.acceptMessage Accept button text default 'Accept'
 * @param settings.rejectMessage Reject button text default 'Reject'
 * @param settings.type Type of flash message described in 'FlashMessageType'.
 * @param settings.onAccept Callback function which will call when 'Accept' button will pressed.
 * @param settings.onReject Callback function which will call when 'Reject' button will pressed.
 * @param settings.onFinally Callback function which will call on pressing 'Accept'|'Reject' buttons or when 'isSelfDestroyable = true'.
 * @param settings.isSelfDestroyable When its 'true' flash message will be self disappear by end of the time.
 * @param settings.time Time after which 'flash message' will disappear. Default 'flashMessageTime'.
 */
const FlashMessageCreate = (settings: TFlashMessage) => {
  const flashMessage = <FlashMessage {...settings} />;
  let parent = document.querySelector<HTMLDivElement>('#flash-message-react');

  // Removes previous flash message to avoid glitches in case if they was called multiple times.
  if (parent) parent.remove();

  parent = document.createElement('div');
  parent.setAttribute('id', 'flash-message-react');
  document.body.appendChild(parent);

  ReactDom.render(flashMessage, parent);
};

export const FlashMessageDetect = () => {
  const flashMessage = document.querySelector<HTMLDivElement>('#flash-message-server');

  if (flashMessage) {
    FlashMessageCreate({
      message: flashMessage.innerHTML,
      type: flashMessage.dataset.type as FlashMessageType,
      isSelfDestroyable: true,
    });
  }
};

export default FlashMessageCreate;
