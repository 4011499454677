import SearchIcon from '@mui/icons-material/Search';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react';

import API from '../API';
import { MainModalCreate } from '../MainModal';
import { useLazyEffect } from '../hooks/useLazyEffect';

// Types
import { TFormValues, TVisit } from '../types';

const selectorLabelName = '検索条件';
const inputLabelName = '検索キーワード';

type TPatientTableSearchForm = {
  setVisits: (data: Array<TVisit>) => void;
};

const PatientTableSearchForm: FC<TPatientTableSearchForm> = ({ setVisits }) => {
  const checkFormValues = (formValues: TFormValues) =>
    !formValues.checkInDate.length && !formValues.searchQuery.length && formValues.finishedFilter === 'null';

  const flatpickrElement = useRef<HTMLSpanElement>(document.querySelector('#search-flatpickr'));
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [formValues, setFormValues] = useState<TFormValues>({
    finishedFilter: 'null',
    searchQuery: '',
    checkInDate: '',
    startDate: '',
    endDate: '',
  });
  const handleChange = (evt: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleReset = () => {
    setIsDisabled(true);
    API.getVisits()
      .then(({ data }) => {
        setVisits(data);
        setFormValues({
          finishedFilter: 'null',
          searchQuery: '',
          checkInDate: '',
          startDate: '',
          endDate: '',
        });
      })
      .finally(() => setIsDisabled(false));
  };

  useEffect(() => {
    // Flatpickr part
    flatpickr(flatpickrElement.current as HTMLSpanElement, {
      mode: 'range',
      locale: Japanese,
      dateFormat: 'Y-m-d',
      onChange: (_, dateStr) => {
        const [startDate, , endDate] = dateStr.split(' ');
        setFormValues({
          ...formValues,
          checkInDate: dateStr,
          startDate: startDate || '',
          endDate: endDate || startDate,
        });
      },
      onOpen: (date, _, instance) => {
        instance.setDate(date);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLazyEffect(() => {
    if (checkFormValues(formValues)) {
      API.getVisits().then(({ data }) => {
        setVisits(data);
      });
      return;
    }
    setIsFocused(false); // Fix glitch with input event onBlur when we submitting form by pressing Enter.
    API.getFilteredVisits(formValues)
      .then(({ data }) => {
        setVisits(data);
      })
      .finally(() => setIsDisabled(false));
  }, [formValues]);

  return (
    <form
      style={{ display: 'flex', gap: '8px' }}
      onSubmit={(evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
      }}
    >
      <FormControl size='small' disabled={isDisabled}>
        <InputLabel id='finished-filter'>{selectorLabelName}</InputLabel>
        <Select
          labelId='finished-filter'
          id='finished-filter'
          name='finishedFilter'
          value={formValues.finishedFilter}
          label={selectorLabelName}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <MenuItem value='null'>すべて</MenuItem>
          <MenuItem value='mri'>要対応分</MenuItem>
          <MenuItem value='report-a'>海馬測定レポート終了分</MenuItem>
          <MenuItem value='report-a-b'>海馬測定レポート, オンラインテストレポート終了分</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant='outlined' size='small' disabled={isDisabled}>
        <InputLabel htmlFor='search-query'>{inputLabelName}</InputLabel>
        <OutlinedInput
          id='search-query'
          name='searchQuery'
          value={formValues.searchQuery}
          label={inputLabelName}
          onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton type='submit' edge='end' color={isFocused ? 'primary' : 'default'} disabled={isDisabled}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl size='small' disabled={isDisabled}>
        <span
          style={{ height: '100%', flexWrap: 'wrap' }}
          className={`btn btn--additional ${isDisabled ? 'btn--disabled' : ''}`}
          id='search-flatpickr'
          ref={flatpickrElement}
        >
          登録日検索
          {formValues.checkInDate ? <span style={{ display: 'flex', marginLeft: '5px' }}>{formValues.checkInDate}</span> : null}
        </span>
      </FormControl>
      {checkFormValues(formValues) ? null : (
        <FormControl size='small' disabled={isDisabled}>
          <button style={{ height: '100%' }} className='btn btn--main' type='button' onClick={handleReset} disabled={isDisabled}>
            絞り込みリセット
          </button>
        </FormControl>
      )}
      <div style={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
        <FormControl size='small' disabled={isDisabled}>
          <button
            type='button'
            style={{ height: '100%' }}
            className='patient-table__registration-link btn btn--main'
            onClick={() => {
              MainModalCreate(
                {
                  modalName: 'registrationModal',
                },
                {
                  minWidth: '50vw',
                  minHeight: '30vh',
                },
              );
            }}
          >
            <span />
            受診者登録
          </button>
        </FormControl>
      </div>
    </form>
  );
};

export default PatientTableSearchForm;
