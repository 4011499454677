import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, Link, OutlinedInput } from '@mui/material';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

import FlashMessageCreate from '../../components/FlashMessage';
import { FlashMessageType } from '../../const';
import Logo from '../Logo';

type TMfa = {
  dataset: {
    email: string;
    actionLink: string;
  };
};

const RESEND_TIMER = 60;
const InputLabelNames = {
  mfaCode: 'セキュリティコード',
};

const Mfa: FC<TMfa> = ({ dataset }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [timer, setTimer] = useState(RESEND_TIMER);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickResetLink = () => {
    let flashMessage = '';
    let flashType = FlashMessageType.Success;

    axios
      .get(dataset.actionLink, { params: { email: dataset.email } })
      .then((res) => {
        flashMessage = res.data.msgUser;
        flashType = res.data.msgType;
      })
      .catch((err) => {
        flashMessage = err.response.data.msgUser;
        flashType = err.response.data.msgType;
      })
      .finally(() => {
        setTimer(RESEND_TIMER);
        FlashMessageCreate({ message: flashMessage, type: flashType, isSelfDestroyable: true });
      });
  };

  useEffect(() => {
    if (timer === 0) {
      return;
    }
    const intervalTimer = setInterval(() => setTimer((currentTime) => currentTime - 1), 1000);

    return () => {
      clearInterval(intervalTimer);
    };
  });

  return (
    <form className='auth-form' method='POST' action={dataset.actionLink}>
      <input type='hidden' name='email' value={dataset.email} hidden />
      <Logo />
      <p>
        MFA（二段階認証）用のセキュリティコードが、<a href={`mailto:${dataset.email}`}>{dataset.email}</a>に送信されました。
        <br />
        コードを入力し、「ログイン」ボタンをクリックしてログインしてください。
      </p>
      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='mfa-code'>{InputLabelNames.mfaCode}</InputLabel>
        <OutlinedInput
          id='mfa-code'
          name='mfa-code'
          type={showPassword ? 'text' : 'password'}
          label={InputLabelNames.mfaCode}
          autoComplete='new-password'
          required
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {timer === 0 ? (
        <Link style={{ cursor: 'pointer' }} onClick={handleClickResetLink} underline='hover'>
          {' '}
          コード再送信
        </Link>
      ) : (
        <p className='auth-form__reset-link'>{timer}秒後に新しいセキュリティコードの発行ができます。</p>
      )}
      <button className='btn btn--main btn--fix' type='submit'>
        ログイン
      </button>
    </form>
  );
};

export default Mfa;
