import React, { FC } from 'react';

import { TDataSet } from '../../types';

type TPatientRegisterConfirmation = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

export const getGender = (gender: 'male' | 'female' | 'other') => {
  switch (gender) {
    case 'male':
      return '男性';

    case 'female':
      return '女性';

    case 'other':
      return 'その他';
  }
};

const entries = [
  {
    id: 'hospitalPatientId',
    name: '院内ID:',
  },
  {
    id: 'hospitalName',
    name: '受検病院:',
  },
  {
    id: 'name',
    name: '名前:',
  },
  {
    id: 'gender',
    name: '性別:',
  },
  {
    id: 'birthDate',
    name: '生年月日:',
  },
  {
    id: 'email',
    name: 'メールアドレス:',
  },
];

const PatientRegisterConfirmation: FC<TPatientRegisterConfirmation> = ({ dataset, isDisabled, setIsDisabled, removeModal }) => {
  return (
    <div className='register-form__confirmation'>
      <h3>登録情報をご確認ください</h3>
      <ul>
        {entries.map((entry) => {
          return dataset[entry.id as keyof typeof dataset] ? (
            <li key={entry.id}>
              <span>{entry.name}</span>
              <b>
                {entry.id === 'gender'
                  ? getGender(dataset[entry.id as keyof typeof dataset] as 'male' | 'female' | 'other')
                  : dataset[entry.id as keyof typeof dataset]}
              </b>
            </li>
          ) : null;
        })}
      </ul>
      <div className='register-form__confirmation--wrapper'>
        <button className='btn btn--additional' type='button' disabled={isDisabled} onClick={removeModal}>
          キャンセル
        </button>
        <label
          className={`btn btn--main ${isDisabled ? 'btn--loading btn--disabled' : ''}`}
          htmlFor='accept-terms-button'
          onClick={() => {
            if (dataset.onAccept) dataset.onAccept(undefined, setIsDisabled);
          }}
        >
          登録
        </label>
      </div>
    </div>
  );
};

export default PatientRegisterConfirmation;
