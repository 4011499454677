import moment from 'moment';
import React, { FC } from 'react';

import { TDataSet } from '../types';

type TRemoveVisit = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const RemoveVisit: FC<TRemoveVisit> = ({ dataset, isDisabled, setIsDisabled, removeModal }: TRemoveVisit) => {
  const convertDate = (date: moment.MomentInput) => moment(date).format('LL'); // TODO: later move to utils.ts

  return (
    <div className='remove-visit'>
      <h2 className='remove-visit__title'>本当に削除しますか？</h2>
      <ul className='remove-visit__list'>
        <li className='remove-visit__item'>
          <span>受診者名:</span>
          <span>{dataset.name?.replace('()', '')}</span>
        </li>
        <li className='remove-visit__item'>
          <span>生年月日:</span>
          <span>{convertDate(dataset.birthDate)}</span>
        </li>
        <li className='remove-visit__item'>
          <span>登録日:</span>
          <span>{convertDate(dataset.registrationDate)}</span>
        </li>
        <li className='remove-visit__item'>
          <span>院内ID:</span>
          <span>{dataset.hospitalPatientId}</span>
        </li>
      </ul>
      <h3 className='remove-visit__warning'>※一度削除すると復元できません。</h3>
      <div className='remove-visit__btn-wrapper'>
        <button className='remove-visit__btn btn btn--additional' type='button' onClick={removeModal} disabled={isDisabled}>
          いいえ
        </button>
        <button
          className={`remove-visit__btn btn btn--main ${isDisabled ? 'btn--loading' : ''}`}
          type='submit'
          onClick={() => {
            if (dataset.onAccept) dataset.onAccept(removeModal, setIsDisabled);
          }}
          disabled={isDisabled}
        >
          はい
        </button>
      </div>
    </div>
  );
};

export default RemoveVisit;
