import React, { FC, memo } from 'react';

type TRegistrationModalMriUploadLoadingState = {
  prePatientInfo: string | undefined;
  isLoading: boolean;
  progress: number;
};

const RegistrationModalMriUploadLoadingState: FC<TRegistrationModalMriUploadLoadingState> = ({ prePatientInfo, isLoading, progress }) => {
  return (
    <div className='registration-modal__loading' style={{ display: isLoading ? 'flex' : 'none' }}>
      {prePatientInfo ? <div className='registration-modal__loading-pre-info' dangerouslySetInnerHTML={{ __html: prePatientInfo }} /> : null}
      <div className='registration-modal__loading-progress-bar'>
        <div style={{ width: `${progress}%` }} />
        <span>{progress}%</span>
      </div>
    </div>
  );
};

export default memo(RegistrationModalMriUploadLoadingState);
