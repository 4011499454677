import { Checkbox, FormControl, InputLabel, Link, OutlinedInput } from '@mui/material';
import React, { FC, FormEvent, useRef, useState } from 'react';

import { MainModalCreate } from '../../MainModal';

type TPatientRegisterVisit = {
  dataset: {
    patientName: string;
    patientGender: string;
    patientBirthdate: string;
    patientEmail: string;
    patientId: number;
    hospitalName: string;
    hospitalCustomUrl: string;
    termsPrivacyLink: string;
    termsEulaLink: string;
    formLink: string;
  };
};
const getGender = (gender: 'male' | 'female' | 'other') => {
  switch (gender) {
    case 'male':
      return '男性';

    case 'female':
      return '女性';

    case 'other':
      return 'その他';
  }
};
const InputLabelNames = {
  hospitalName: '受検病院',
  patientName: '氏名',
  patientGender: '性別',
  patientBirthdate: '生年月日',
  patientEmail: 'メールアドレス',
};

const PatientRegisterVisit: FC<TPatientRegisterVisit> = ({ dataset }) => {
  const trigger = useRef(true);
  const [checked, setChecked] = useState(false);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    if (trigger.current) {
      evt.preventDefault();
      trigger.current = false;
      MainModalCreate(
        {
          modalName: 'patientRegisterConfirmation',
          hospitalName: dataset.hospitalName,
          name: dataset.patientName,
          gender: dataset.patientGender,
          birthDate: dataset.patientBirthdate,
          email: dataset.patientEmail,
          onAccept: (_, disableModal) => {
            if (disableModal) disableModal(true);
          },
          onReject: () => {
            trigger.current = true;
          },
        },
        { padding: '1rem' },
      );
      return;
    }
  };

  return (
    <form className='register-form' action={dataset.formLink} method='POST' onSubmit={handleSubmit}>
      <h2>BrainSuite 2回目以降の受検者登録情報の確認</h2>
      <h3>{dataset.patientName}様</h3>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='hospital-name'>{InputLabelNames.hospitalName}</InputLabel>
        <OutlinedInput
          value={dataset.hospitalName}
          id='hospital-name'
          name='hospital-name'
          type='text'
          label={InputLabelNames.hospitalName}
          required
          readOnly
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='patient-name'>{InputLabelNames.patientName}</InputLabel>
        <OutlinedInput
          value={dataset.patientName}
          id='patient-name'
          name='patient-name'
          type='text'
          label={InputLabelNames.patientName}
          required
          readOnly
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='patient-gender'>{InputLabelNames.patientGender}</InputLabel>
        <OutlinedInput
          value={getGender(dataset.patientGender as 'male' | 'female' | 'other')}
          id='patient-gender'
          name='patient-gender'
          type='text'
          label={InputLabelNames.patientGender}
          required
          readOnly
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='patient-birthdate'>{InputLabelNames.patientBirthdate}</InputLabel>
        <OutlinedInput
          value={dataset.patientBirthdate}
          id='patient-birthdate'
          name='patient-birthdate'
          type='text'
          label={InputLabelNames.patientBirthdate}
          required
          readOnly
        />
      </FormControl>

      {dataset.patientEmail ? (
        <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
          <InputLabel htmlFor='patient-email'>{InputLabelNames.patientEmail}</InputLabel>
          <OutlinedInput
            value={dataset.patientEmail}
            id='patient-email'
            name='patient-email'
            type='text'
            label={InputLabelNames.patientEmail}
            required
            readOnly
          />
        </FormControl>
      ) : null}

      <input type='hidden' name='patient_id' value={dataset.patientId} />
      <input type='hidden' name='custom_url' value={dataset.hospitalCustomUrl} />
      <div className='register-form__terms-wrapper'>
        <Checkbox sx={{ pt: '10px' }} checked={checked} onChange={(evt) => setChecked(evt.target.checked)} required />
        <span>
          <Link href={dataset.termsPrivacyLink} underline='hover' target='_blank'>
            個人情報の取扱
          </Link>
          と
          <Link href={dataset.termsEulaLink} underline='hover' target='_blank'>
            利用規約
          </Link>
          に同意します。
        </span>
      </div>

      <input className='btn btn--main' id='accept-terms-button' type='submit' value='登録' disabled={!checked} />
    </form>
  );
};

export default PatientRegisterVisit;
