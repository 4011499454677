const EmailValidationInit = () => {
  const emailConfirm = document.querySelector('#email_confirm');

  if (emailConfirm) {
    const email = document.querySelector('#email');

    const validate = (evt, elem) => {
      email.setCustomValidity('');
      emailConfirm.setCustomValidity('');

      if (evt.target.value !== elem.value && elem.value && evt.target.value) {
        evt.target.setCustomValidity('メールアドレスが一致しません');
      }
    };

    email.addEventListener('input', (evt) => validate(evt, emailConfirm));
    emailConfirm.addEventListener('input', (evt) => validate(evt, email));
  }
};

export default EmailValidationInit;
