import AddIcon from '@mui/icons-material/Add';
import { CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { FC, useState } from 'react';

import { IPatientCreateVisitButtonResponse, TPatientCreateVisitButton } from './PatientCreateVisitButton.types';

import API from '../../API';
import { FlashMessageType } from '../../const';
import { getPatientIdFormUrl, getVisitIdFormUrl } from '../../utils';
import FlashMessageCreate from '../FlashMessage';

const PatientCreateVisitButton: FC<TPatientCreateVisitButton> = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onClickHandler = () => {
    setIsDisabled(true);
    let message = '';
    let type = FlashMessageType.Success;

    API.setNewVisit<IPatientCreateVisitButtonResponse>(getPatientIdFormUrl(), getVisitIdFormUrl())
      .then(({ data }) => {
        message = data.msgUser;
        type = data.msgType;
        window.location.href = data.data.redirectUrl;
      })
      .catch((err: unknown) => {
        if (axios.isAxiosError(err)) {
          const axiosError: AxiosError<IPatientCreateVisitButtonResponse, IPatientCreateVisitButtonResponse> = err;
          const { msgUser, msgType } = axiosError.response?.data as IPatientCreateVisitButtonResponse;
          message = msgUser;
          type = msgType;
        } else {
          console.error(err);
          throw Error('Something bad happened');
        }
      })
      .finally(() => {
        setIsDisabled(false);
        FlashMessageCreate({ message, type, isSelfDestroyable: true, time: 3000 });
      });
  };

  return (
    <button
      disabled={isDisabled}
      className='btn btn--additional'
      style={{ height: '40px', alignSelf: 'center', display: 'flex', gap: '0.5rem' }}
      type='button'
      onClick={onClickHandler}
    >
      {isDisabled ? <CircularProgress color='primary' size={22} /> : <AddIcon />}
      <span>新しい受診を作成</span>
    </button>
  );
};

export default PatientCreateVisitButton;
