import DriveFolderUploadSharpIcon from '@mui/icons-material/DriveFolderUploadSharp';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import React, { ChangeEvent, FC, memo, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import RegistrationModalMriUploadLoadingState from './RegistrationModalMriUploadLoadingState';

import { FlashMessageType } from '../const';

type TRegistrationModalMriUpload = {
  prePatientInfo: string | undefined;
  isLoading: boolean;
  progress: number;
  isDisabled: boolean;
  setFiles: (files: any) => void;
};

const getFile = async (fileEntry: FileSystemFileEntry): Promise<File | undefined> => {
  try {
    return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  } catch (err) {
    console.error(`Problem with getting file.\n${err}`);
  }
};

/**
 * Retrieve all files from a DataTransferItemList with the full directory structure preserved in the filename.
 *
 * Problem:
 * Different browsers handle dragged and dropped folders differently. Firefox maintains the full directory
 * structure in the file name, whereas Chrome does not. This inconsistency can be problematic for applications
 * that rely on the full path of a file.
 *
 * Solution:
 * This function ensures that the full directory structure is preserved in the filename, regardless of the browser being used.
 * It traverses the entire directory/file structure using a BFS approach. As it goes through each file and directory,
 * it maintains the path from the root directory. When it finally reads the file, it prefixes the filename with its
 * entire path, ensuring that the filename is consistent with the behavior observed in Firefox.
 *
 * @param {DataTransferItemList} dataTransferItemList - The list of items to be processed.
 * @returns {Promise<FileList>} - A FileList with files having full paths in their filenames.
 */
const getAllFileEntries = async (dataTransferItemList: DataTransferItemList): Promise<FileList> => {
  const fileEntries: Array<{ entry: FileSystemFileEntry; path: string }> = [];
  // Use BFS to traverse entire directory/file structure
  const queue: Array<{ entry: FileSystemEntry | null; path: string }> = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    const entry = dataTransferItemList[i].webkitGetAsEntry();
    queue.push({ entry, path: entry!.name });
  }

  while (queue.length > 0) {
    const { entry, path } = queue.shift()!;

    if (entry!.isFile) {
      fileEntries.push({ entry: entry as FileSystemFileEntry, path });
    } else if (entry!.isDirectory) {
      const directory = entry as FileSystemDirectoryEntry;
      const reader = directory.createReader();
      const entries = await readAllDirectoryEntries(reader);
      for (const childEntry of entries) {
        queue.push({ entry: childEntry, path: path + '/' + childEntry.name });
      }
    }
  }

  // Convert back to FileList object.
  const dt = new DataTransfer();

  for (let item of fileEntries) {
    const file = await getFile(item.entry);
    if (file) {
      const blobData = [await file.arrayBuffer()];
      const newFile = new File(blobData, item.path, { type: file.type });
      dt.items.add(newFile);
    }
  }

  return dt.files;
};

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
const readAllDirectoryEntries = async (directoryReader: FileSystemDirectoryReader) => {
  const entries: Array<FileSystemFileEntry | FileSystemDirectoryEntry> = [];
  let readEntries = await readEntriesPromise(directoryReader);

  while (readEntries && readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }

  return entries;
};

// Wrap readEntries in a promise to make working with readEntries easier
const readEntriesPromise = async (
  directoryReader: FileSystemDirectoryReader,
): Promise<Array<FileSystemFileEntry | FileSystemDirectoryEntry> | undefined> => {
  try {
    // TODO: FIX THIS ANY TYPE!!!!!!!!!!!!!!!!
    return await new Promise((resolve: any, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.error(`Problem with reading entries.\n${err}`);
  }
};

const RegistrationModalMriUpload: FC<TRegistrationModalMriUpload> = ({ prePatientInfo, isLoading, progress, isDisabled, setFiles }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const handleDrag = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isDisabled) return;
    if (evt.type === 'dragenter' || evt.type === 'dragover') {
      setIsDragActive(true);
    } else if (evt.type === 'dragleave') {
      setIsDragActive(false);
    }
  };
  const handleDrop = async (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isDisabled) return;
    setIsDragActive(false);
    getAllFileEntries(evt.dataTransfer.items)
      .then((files: FileList) => setFiles(files))
      .catch(() => {
        FlashMessageCreate({
          message:
            'このブラウザではドラッグ＆ドロップがサポートされていないか、<br>あるいはファイル以外の形式がドラッグ＆ドロップされた可能性があります。',
          type: FlashMessageType.Error,
          isSelfDestroyable: true,
        });
        throw new Error("Drag&Drop functionality doesn't work in your browser.\nOr\nContent that you drag was not a file.");
      });
  };
  window.PatientTable.handleDrop = handleDrop;
  const filesHandle = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFiles(target.files);
    target.type = '';
    target.type = 'file';
  };

  return (
    <div
      className={`registration-modal__drag-drop ${isDragActive ? 'registration-modal__drag-drop--active' : ''}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <RegistrationModalMriUploadLoadingState prePatientInfo={prePatientInfo} isLoading={isLoading} progress={progress} />
      <p>このウィンドウにドラッグ＆ドロップ</p>
      <p>
        解析対象のMRI画像データ（3次元T1強調像）の
        <br />
        ファイルすべて、または、ファイルの入ったフォルダを重ねる
      </p>
      <p>フォルダ又はファイルを選択して手動でアップロード</p>
      <label className='btn btn--main btn--fix' tabIndex={0}>
        <input type='file' onChange={filesHandle} hidden multiple directory='' webkitdirectory='' allowdirs='' />
        <DriveFolderUploadSharpIcon />
        フォルダを選択
      </label>
      <label className='btn btn--main btn--fix' tabIndex={0}>
        <input type='file' onChange={filesHandle} hidden multiple />
        <UploadFileSharpIcon />
        ファイルを選択
      </label>
    </div>
  );
};

export default memo(RegistrationModalMriUpload);
