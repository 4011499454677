import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  Link,
  OutlinedInput,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { ChangeEvent, FC, FocusEvent, FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';

import { MainModalCreate } from '../../MainModal';

type TPatientRegister = {
  dataset: {
    patientHospitalPatientId: string;
    patientName: string;
    patientGender: string;
    patientBirthdate: string;
    patientEmail: string;
    patientEmailConfirm: string;
    hospitalName: string;
    confirmationPage: boolean;
    termsPrivacyLink: string;
    termsEulaLink: string;
  };
};

const InputLabelNames = {
  hospitalPatientId: '院内ID',
  kanjiName: '*氏名',
  gender: '*',
  birthdate: '*生年月日',
  email: 'メールアドレス',
  emailConfirm: 'メールアドレス（確認用再入力）',
};
const InputPlaceholderNames = {
  hospitalPatientId: '不明の場合は空欄',
  kanjiName: '鈴木',
  birthdate: 'YYYY-MM-DD',
  email: 'または不明の場合は空欄',
  emailConfirm: 'または不明の場合は空欄',
  captcha: '数字を入力してください。',
};
const genderTable = {
  male: '男性',
  female: '女性',
  other: 'その他',
};

const PatientRegister: FC<TPatientRegister> = ({ dataset }) => {
  dataset.confirmationPage = !!dataset.confirmationPage;
  const trigger = useRef(true);
  const emailConfirmElement = useRef<HTMLInputElement>(document.querySelector('#email-confirm'));
  const [checked, setChecked] = useState(false);
  const [captchaImg, setCaptchaImg] = useState('');
  const [formValues, setFormValues] = useState({
    'hospital-patient-id': dataset.patientHospitalPatientId || '',
    'kanji-name': dataset.patientName || '',
    gender: dataset.patientGender || '',
    'birth-date': dataset.patientBirthdate || '',
    email: dataset.patientEmail || '',
    'email-confirm': dataset.patientEmailConfirm || '',
    captcha: '',
  });
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = evt.target;
    emailConfirmElement.current?.setCustomValidity('');
    if (id === 'email' || id === 'email-confirm') {
      if (formValues.email !== evt.target.value) {
        emailConfirmElement.current?.setCustomValidity('メールアドレスが一致しません');
      }
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    evt.target.setCustomValidity('');
    const formattedDate = moment(value).format('YYYY-MM-DD');
    if (formattedDate === 'Invalid date') {
      evt.target.setCustomValidity('日付が正しくありません。');
      return;
    }
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };
  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    if (trigger.current) {
      evt.preventDefault();
      trigger.current = false;
      MainModalCreate(
        {
          modalName: 'patientRegisterConfirmation',
          hospitalPatientId: formValues['hospital-patient-id'],
          name: formValues['kanji-name'],
          gender: formValues.gender,
          birthDate: formValues['birth-date'],
          email: formValues.email,
          onAccept: (_, disableModal) => {
            if (disableModal) disableModal(true);
          },
          onReject: () => {
            trigger.current = true;
          },
        },
        { padding: '1rem' },
      );
      return;
    }
  };
  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    axios
      .get('/patient/self-register/captcha')
      .then(({ data }) => {
        setCaptchaImg(data.img);
      })
      .catch();
  };
  useEffect(() => {
    axios
      .get('/patient/self-register/captcha')
      .then(({ data }) => {
        setCaptchaImg(data.img);
      })
      .catch();
  }, []);

  return (
    <form className='register-form' method='POST' onSubmit={handleSubmit}>
      <h2>BrainSuite{dataset.confirmationPage ? ' 受検者登録情報の確認' : ' のお申し込み'}</h2>
      <h3>{dataset.hospitalName}</h3>
      {dataset.confirmationPage ? <p>登録情報をご確認ください。</p> : null}

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='hospital-patient-id'>{InputLabelNames.hospitalPatientId}</InputLabel>
        <OutlinedInput
          value={formValues['hospital-patient-id']}
          placeholder={InputPlaceholderNames.hospitalPatientId}
          id='hospital-patient-id'
          name='hospital-patient-id'
          type='text'
          label={InputLabelNames.hospitalPatientId}
          onChange={handleChange}
          required={dataset.confirmationPage}
          readOnly={dataset.confirmationPage}
          inputProps={{ pattern: '^[^s]+(s.*)?$' }}
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='kanji-name'>{InputLabelNames.kanjiName}</InputLabel>
        <OutlinedInput
          value={formValues['kanji-name']}
          placeholder={InputPlaceholderNames.kanjiName}
          id='kanji-name'
          name='kanji-name'
          type='text'
          label={InputLabelNames.kanjiName}
          onChange={handleChange}
          required
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <FormLabel sx={{ position: 'absolute', left: '14px', top: 0 }}>{InputLabelNames.gender}</FormLabel>
        <RadioGroup sx={{ justifyContent: 'center' }} row name='gender' value={formValues.gender} onChange={handleChange}>
          <FormControlLabel value='male' control={<Radio required />} label={genderTable.male} />
          <FormControlLabel value='female' control={<Radio required />} label={genderTable.female} />
          <FormControlLabel value='other' control={<Radio required />} label={genderTable.other} />
        </RadioGroup>
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='birth-date'>{InputLabelNames.birthdate}</InputLabel>
        <OutlinedInput
          value={formValues['birth-date']}
          placeholder={InputPlaceholderNames.birthdate}
          id='birth-date'
          name='birth-date'
          type='text'
          label={InputLabelNames.birthdate}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='email'>{InputLabelNames.email}</InputLabel>
        <OutlinedInput
          value={formValues.email}
          placeholder={InputPlaceholderNames.email}
          id='email'
          name='email'
          type='email'
          label={InputLabelNames.email}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='email-confirm'>{InputLabelNames.emailConfirm}</InputLabel>
        <OutlinedInput
          value={formValues['email-confirm']}
          placeholder={InputPlaceholderNames.emailConfirm}
          id='email-confirm'
          name='email-confirm'
          type='email'
          label={InputLabelNames.emailConfirm}
          onChange={handleChange}
          inputRef={emailConfirmElement}
        />
      </FormControl>

      {dataset.confirmationPage ? null : (
        <Box className='register-form__captcha'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {captchaImg.length ? (
              <img src={`data:image/png;base64,${captchaImg}`} alt='captcha' />
            ) : (
              <Skeleton variant='rectangular' width='100%' height='auto' />
            )}
            <Tooltip title='Refresh image' arrow placement='right'>
              <IconButton color='primary' component='button' onClick={handleClick}>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            sx={{ mb: '-1px', mr: '-1px', ml: '-1px', minWidth: 280 }}
            value={formValues.captcha}
            placeholder={InputPlaceholderNames.captcha}
            name='captcha'
            type='number'
            onChange={handleChange}
            required
            inputProps={{ style: { padding: '8.5px 14px' } }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          />
        </Box>
      )}

      <div className='register-form__terms-wrapper'>
        <Checkbox sx={{ pt: '10px' }} checked={checked} onChange={(evt) => setChecked(evt.target.checked)} required />
        <span>
          <Link href={dataset.termsPrivacyLink} underline='hover' target='_blank'>
            個人情報の取扱
          </Link>
          と
          <Link href={dataset.termsEulaLink} underline='hover' target='_blank'>
            利用規約
          </Link>
          に同意します。
        </span>
      </div>

      <input
        className='btn btn--main'
        id='accept-terms-button'
        type='submit'
        value={dataset.confirmationPage ? '登録してオンラインテストへ進む' : '登録'}
        disabled={!checked}
      />
    </form>
  );
};

export default PatientRegister;
