import React, { CSSProperties, FC, useEffect, useState } from 'react';

import { MainModalCreate } from '../MainModal';
import { Colors } from '../const';

import { TVisit } from '../types';

type TPatientTableDragDrop = {
  visits: Array<TVisit> | undefined;
  tableHeight: number;
};

const PatientTableDragDrop: FC<TPatientTableDragDrop> = ({ visits, tableHeight }) => {
  const [modal, setModal] = useState<HTMLDivElement | null>(null);
  window.PatientTable.setModal = setModal;
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [tableRect, setTableRect] = useState<DOMRect>();
  const style: CSSProperties = {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: isDraggable ? 999 : -1,
    opacity: isDraggable ? 1 : 0,
    position: 'absolute',
    top: tableRect?.top || 0,
    left: tableRect?.left || 0,
    width: tableRect?.width || '100%',
    height: tableRect?.height || '100%',
    borderRadius: '4px',
    border: `2px dashed ${isDragActive ? Colors.cerulean : 'rgba(0, 0, 0, 0.23)'}`,
    backdropFilter: isDraggable ? 'blur(4px)' : undefined,
    transition: 'opacity 0.5s linear',
    boxShadow: isDragActive ? '0 0 0 color.scale($cerulean, $alpha: -60%)' : undefined,
    animation: isDragActive ? 'pulse 2s infinite' : undefined,
  };
  const textStyle: CSSProperties = {
    margin: 0,
    color: '#aaaaaa',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 15px 10px #ffffff',
  };
  const showDragDropArea = (evt: DragEvent) => {
    evt.preventDefault();
    setIsDraggable(true);
  };
  const hideDragDropArea = (evt: DragEvent) => {
    evt.preventDefault();
    if (evt.type === 'drop' || (evt.clientX === 0 && evt.clientY === 0)) {
      setIsDraggable(false);
    }
  };
  const handleDrag = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.type === 'dragenter' || evt.type === 'dragover') {
      setIsDragActive(true);
    } else if (evt.type === 'dragleave') {
      setIsDragActive(false);
    }
  };
  const handleDrop = (evt: React.DragEvent<HTMLDivElement>) => {
    setIsDraggable(false);
    setIsDragActive(false);
    MainModalCreate(
      {
        modalName: 'registrationModal',
        loading: true,
      },
      {
        minWidth: '50vw',
        minHeight: '30vh',
      },
    );
    window.PatientTable.handleDrop(evt);
    setModal(document.querySelector<HTMLDivElement>('#registrationModal'));
  };

  useEffect(() => {
    const table = document.querySelector<HTMLDivElement>('#patient-table-list');
    if (table) {
      setTableRect(table.getBoundingClientRect());
    }
  }, [visits, tableHeight]);
  useEffect(() => {
    if (!modal) document.addEventListener('dragenter', showDragDropArea);
    if (!modal) document.addEventListener('dragover', showDragDropArea);
    if (!modal) document.addEventListener('dragleave', hideDragDropArea);
    if (!modal) document.addEventListener('drop', hideDragDropArea);

    return () => {
      if (!modal) document.removeEventListener('dragenter', showDragDropArea);
      if (!modal) document.removeEventListener('dragover', showDragDropArea);
      if (!modal) document.removeEventListener('dragleave', hideDragDropArea);
      if (!modal) document.removeEventListener('drop', hideDragDropArea);
    };
  }, [modal]);

  return (
    <div
      style={{
        ...style,
      }}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <p style={{ ...textStyle, fontWeight: 700 }}>このウィンドウにドラッグ＆ドロップ</p>
      <p style={textStyle}>
        解析対象のMRI画像データ（3次元T1強調像）の
        <br />
        ファイルすべて、または、ファイルの入ったフォルダを重ねる
      </p>
    </div>
  );
};

export default PatientTableDragDrop;
