import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';

import API from '../API';
import { Colors } from '../const';

import { MriViewType } from '../types';

const TIMEOUT = 3000;

type TRegistrationModalMriResultImg = {
  type: MriViewType;
  uuid: string;
};

const RegistrationModalMriResultImg: FC<TRegistrationModalMriResultImg> = ({ type, uuid }) => {
  const [imgUrl, setImgUrl] = useState('');
  const style: CSSProperties = {
    position: 'relative',
    width: 'clamp(15vw, 10vw, 5vw)',
    paddingBottom: 'clamp(15vw, 10vw, 5vw)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${imgUrl}')`,
    backgroundColor: Colors.black,
    backgroundSize: 'contain',
    mixBlendMode: imgUrl.length ? 'unset' : 'luminosity',
  };

  useEffect(() => {
    const getImage = () => {
      fetch(API.getImage(type, uuid)).then(({ status }) => {
        if (status !== 200) {
          setTimeout(() => getImage(), TIMEOUT);
        } else {
          setImgUrl(API.getImage(type, uuid));
        }
      });
    };

    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={style}>
      {imgUrl.length ? null : (
        <div className='lds-spinner' style={{ position: 'absolute' }}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
};

export default memo(RegistrationModalMriResultImg);
