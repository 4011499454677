import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, Link, OutlinedInput } from '@mui/material';
import React, { FC, useState } from 'react';

import Logo from '../Logo';

type TLogin = {
  dataset: {
    resetLink: string;
  };
};

const InputLabelNames = {
  email: 'メールアドレス',
  password: 'パスワード',
};

const Login: FC<TLogin> = ({ dataset }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <form className='auth-form' method='POST'>
      <Logo />

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='email'>{InputLabelNames.email}</InputLabel>
        <OutlinedInput id='email' name='email' type='email' label={InputLabelNames.email} required />
      </FormControl>

      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='password'>{InputLabelNames.password}</InputLabel>
        <OutlinedInput
          id='password'
          name='password'
          type={showPassword ? 'text' : 'password'}
          label={InputLabelNames.password}
          required
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Link href={dataset.resetLink} underline='hover'>
        パスワードをお忘れの時はこちら
      </Link>

      <button className='btn btn--main btn--fix' type='submit'>
        ログイン
      </button>
    </form>
  );
};

export default Login;
