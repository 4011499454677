import CheckIcon from '@mui/icons-material/Check';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HelpIcon from '@mui/icons-material/Help';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FormControl, FormControlLabel, InputLabel, OutlinedInput, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import React, { ChangeEvent, FC, FocusEvent, SyntheticEvent, memo, useEffect, useState } from 'react';

import FlashMessageCreate from './FlashMessage';
import { TMriData } from './RegistrationModalMri';
import RegistrationModalMriResultImg from './RegistrationModalMriResultImg';
import RegistrationModalMriResultTable from './RegistrationModalMriResultTable';

import API from '../API';
import { MainModalCreate } from '../MainModal';
import { Colors, FlashMessageType } from '../const';
import { MriViewType } from '../types';

export type TMatchPatient = {
  birthdate: string;
  gender: 'male' | 'female' | 'other';
  hospitalPatientId: string;
  kanjiName: string;
  patientName: string;
  patientId: number;
  prepaidCode: string;
  brainID: number;
};

type TRegistrationModalMriResult = {
  mriData: TMriData;
  setIsBehind: (value: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  setBeforeClose: (value: { flag: boolean; message: string }) => void;
  removeModal: (value?: boolean) => void;
  anonymizeDataToggle?: boolean;
};

export const genderTable = {
  male: '男性',
  female: '女性',
  other: '他/不明',
};

const EmbeddedReportStatus = {
  accepted: <CheckIcon color='success' />,
  rejected: <DangerousIcon color='error' />,
  'not-provided': <WarningAmberIcon color='warning' />,
};

const InputLabelNames = {
  HospitalPatientId: '院内ID *',
  Name: '名前 *',
  Birthdate: '生年月日 *',
  Gender: '性別 *',
  PrepaidCode: 'チケット番号',
  BrainID: 'Brain-ID',
};
const warningMessage = 'このページを閉じると、現在のアップロードが無効になります。<br/>よろしいですか？';

const RegistrationModalMriResult: FC<TRegistrationModalMriResult> = ({
  setIsBehind,
  mriData,
  isDisabled,
  setIsDisabled,
  setBeforeClose,
  removeModal,
  anonymizeDataToggle,
}) => {
  const [selected, setSelected] = useState<number | undefined>();
  const [matchPatients, setMatchPatients] = useState<Array<TMatchPatient>>([]);
  const [formValues, setFormValues] = useState({
    hospitalPatientId: mriData.hospitalPatientId || '',
    name: mriData.name || '',
    birthDate: mriData.birthDate || '',
    gender: mriData.gender || '',
    doctorComment: '',
    prepaidCode: '',
    brainID: '',
  });

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    evt.target.setCustomValidity('');
    const formattedDate = moment(value).format('YYYY-MM-DD');
    if (formattedDate === 'Invalid date') {
      evt.target.setCustomValidity('日付が正しくありません。');
      return;
    }
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };
  const handleRegistrationClick = () => {
    const data = new FormData();

    for (const [key, value] of Object.entries(mriData)) {
      data.append(key, value as string);
    }

    for (const [key, value] of Object.entries(formValues)) {
      if (data.has(key)) {
        data.set(key, value as string);
      } else {
        data.append(key, value as string);
      }
    }

    if (selected) data.set('bsId', `${selected}`);
    const match = matchPatients?.find(({ patientId }) => patientId === selected);

    setIsBehind(true);
    setIsDisabled(true);

    MainModalCreate({
      modalName: 'registrationModalMriResultConfirmation',
      patientName: match ? (match.patientName.length ? `${match.patientName}` : match.kanjiName) : formValues.name,
      birthDate: match ? match.birthdate : formValues.birthDate,
      hospitalPatientId: match ? (match.hospitalPatientId ? match.hospitalPatientId : formValues.hospitalPatientId) : formValues.hospitalPatientId,
      patientId: match ? match.patientId : selected,
      gender: match ? match.gender : formValues.gender,
      prepaidCode: formValues.prepaidCode,
      onAccept: (removeModalCb, disableModalCb) => {
        if (disableModalCb) disableModalCb(true);

        let flashMessage = '';
        let flashType = FlashMessageType.Success;
        let timer: NodeJS.Timeout;

        API[selected ? 'setVisit' : 'setPatient'](data)
          .then(({ data }) => {
            setBeforeClose({ flag: false, message: warningMessage });
            flashMessage = data.msgUser;
            removeModal(true);
            window.PatientTable.update();
            timer = setTimeout(() => {
              window.location.href = `${window.location.href}/${data.patientId}/${data.visitId}`;
            }, data.seconds);
          })
          .catch((err) => {
            flashMessage = err.response.data.msgUser;
            flashType = FlashMessageType.Error;
          })
          .finally(() => {
            FlashMessageCreate({
              message: flashMessage,
              type: flashType,
              onReject: () => clearTimeout(timer),
              rejectMessage: 'やめる',
              isSelfDestroyable: true,
            });
            if (removeModalCb) removeModalCb();
          });
      },
      onReject: () => {
        setIsBehind(false);
        setIsDisabled(false);
      },
    });
  };

  const handleFormSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();

    handleRegistrationClick();
  };

  const handleFlashMessage = (hintMsg: Array<string>) => {
    FlashMessageCreate({
      message: `${hintMsg.map((msg) => ` - <b>${msg}</b><br>`).join('')}`,
      type: FlashMessageType.Warning,
      isSelfDestroyable: false,
      onAccept: () => {},
    });
  };

  useEffect(() => {
    if (mriData.hintMsg?.length) {
      handleFlashMessage(mriData.hintMsg);
    }
  }, [mriData.hintMsg]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setBeforeClose({ flag: true, message: warningMessage }), []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      API.getMatchPatients<any, Array<TMatchPatient>>(formValues).then(({ data }) => {
        setSelected(undefined);
        setMatchPatients(data);

        const match = data.find(
          ({ hospitalPatientId, patientId }) => formValues.hospitalPatientId === hospitalPatientId || parseInt(formValues.brainID, 10) === patientId,
        );

        if (match) setSelected(match.patientId);
      });
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.birthDate, formValues.gender, formValues.hospitalPatientId, formValues.name, formValues.brainID]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          borderRadius: '4px',
          overflow: 'hidden',
          backgroundColor: Colors.black,
          flexShrink: 0,
        }}
      >
        <RegistrationModalMriResultImg type={MriViewType.RAW_AX} uuid={mriData.uuidNii || ''} />
        <RegistrationModalMriResultImg type={MriViewType.RAW_COR} uuid={mriData.uuidNii || ''} />
        <RegistrationModalMriResultImg type={MriViewType.RAW_SAG} uuid={mriData.uuidNii || ''} />
        <RegistrationModalMriResultImg type={MriViewType.RAW_ANIM} uuid={mriData.uuidNii || ''} />
      </div>
      <form
        style={{
          display: 'grid',
          gridTemplateAreas: `'primary secondary'
          'lPixelReport lPixelReport'
          `,
          gridGap: '1rem',
          margin: '1rem 0',
          fontSize: '0.875rem',
        }}
        onSubmit={handleFormSubmit}
      >
        <div style={{ gridArea: 'primary', border: '1px solid var(--color-cerulean)' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              background: '#E3F6FF',
              padding: '0 0.5rem',
              color: 'var(--color-cerulean)',
              fontWeight: 700,
            }}
          >
            受診者情報（DICOMタグより取得）
            <span style={{ fontWeight: 'initial', padding: '0.15rem 0.4rem 0.25rem', color: Colors.white, backgroundColor: Colors.red }}>必須</span>
            {mriData.hintMsg?.length ? (
              <Tooltip title='Warnings' arrow>
                <IconButton color='warning' onClick={() => handleFlashMessage(mriData.hintMsg as Array<string>)}>
                  <WarningAmberIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
          <div
            style={{
              display: 'grid',
              gap: '1rem',
              justifyContent: 'start',
              padding: '1rem',
              gridTemplateAreas: `
              "hospitalPatientId name"
              "birthDate gender"
            `,
            }}
          >
            <FormControl sx={{ width: 'clamp(15vw, 20vw, 20vw)' }} variant='outlined' size='small' disabled={isDisabled}>
              <InputLabel htmlFor='hospitalPatientId'>{InputLabelNames.HospitalPatientId}</InputLabel>
              <OutlinedInput
                id='hospitalPatientId'
                name='hospitalPatientId'
                value={formValues.hospitalPatientId}
                label={InputLabelNames.HospitalPatientId}
                onInput={handleChange}
                required
              />
            </FormControl>
            <FormControl
              sx={{ width: 'clamp(15vw, 20vw, 20vw)', gridArea: 'hospitalPatientId' }}
              variant='outlined'
              size='small'
              disabled={isDisabled}
            >
              <InputLabel htmlFor='name'>{InputLabelNames.Name}</InputLabel>
              <OutlinedInput
                id='name'
                name='name'
                value={formValues.name}
                label={InputLabelNames.Name}
                onInput={handleChange}
                required={!anonymizeDataToggle}
              />
            </FormControl>
            <FormControl sx={{ width: 'clamp(15vw, 20vw, 20vw)', gridArea: 'birthDate' }} variant='outlined' size='small' disabled={isDisabled}>
              <InputLabel htmlFor='birthDate'>{InputLabelNames.Birthdate}</InputLabel>
              <OutlinedInput
                id='birthDate'
                name='birthDate'
                value={formValues.birthDate}
                label={InputLabelNames.Birthdate}
                onInput={handleChange}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl sx={{ gridArea: 'gender' }} variant='outlined' size='small' disabled={isDisabled}>
              <span style={{ color: 'var(--color-charcoal', fontSize: '0.75rem' }}>{InputLabelNames.Gender}</span>
              <RadioGroup sx={{ marginLeft: '0.8rem' }} row name='gender' value={formValues.gender} onChange={handleChange}>
                <FormControlLabel value='male' control={<Radio required />} label={genderTable.male} />
                <FormControlLabel value='female' control={<Radio required />} label={genderTable.female} />
                <FormControlLabel value='other' control={<Radio required />} label={genderTable.other} />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div style={{ gridArea: 'secondary', border: '1px solid var(--color-mercury)' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              minHeight: '40px',
              background: 'var(--color-mercury)',
              padding: '0 0.5rem',
              boxSizing: 'border-box',
              fontWeight: 700,
            }}
          >
            BrainSuite情報
            <span style={{ fontWeight: 'initial', padding: '0.15rem 0.4rem 0.25rem', color: Colors.white, backgroundColor: Colors.gray }}>任意</span>
          </div>
          <div
            style={{
              display: 'grid',
              gap: '1rem',
              justifyContent: 'start',
              padding: '1rem',
              gridTemplateAreas: `
              "brainID"
              "prepaidCode"
            `,
            }}
          >
            <FormControl sx={{ width: 'clamp(15vw, 20vw, 20vw)', gridArea: 'brainID' }} variant='outlined' size='small' disabled={isDisabled}>
              <InputLabel htmlFor='brainID'>{InputLabelNames.BrainID}</InputLabel>
              <OutlinedInput
                id='brainID'
                name='brainID'
                value={formValues.brainID}
                label={InputLabelNames.BrainID}
                onInput={handleChange}
                endAdornment={
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '0.875rem' }}>
                        Brain-IDをお持ちの方は、Brain-IDを入力してください。Brain-IDは、BrainSuiteを始めとした各種サービスの共通アカウントです。
                        <br />
                        Brain-IDを入力することで、過去の解析結果を同じアカウントでまとめて表示することができます。
                      </Typography>
                    }
                    arrow
                  >
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
            </FormControl>
            <FormControl sx={{ width: 'clamp(15vw, 20vw, 20vw)', gridArea: 'prepaidCode' }} variant='outlined' size='small' disabled={isDisabled}>
              <InputLabel htmlFor='prepaidCode'>{InputLabelNames.PrepaidCode}</InputLabel>
              <OutlinedInput
                id='prepaidCode'
                name='prepaidCode'
                value={formValues.prepaidCode}
                label={InputLabelNames.PrepaidCode}
                onInput={handleChange}
                endAdornment={
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '0.875rem' }}>
                        BrainSuiteチケットをお持ちの方は、チケット番号を入力してください。
                        事前にBrainSuiteをご購入およびお支払いしている方には、BrainSuiteチケットをお配りしております。
                        チケット番号を入力することで、ご利用者様の窓口負担金を調整いたします。
                      </Typography>
                    }
                    arrow
                  >
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
            </FormControl>
          </div>
        </div>

        {mriData.embeddedLpixelReport === 'not-provided' ? null : (
          <FormControl sx={{ gridArea: 'lPixelReport' }} variant='outlined' size='small' disabled={isDisabled}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              EIRLレポートが含まれています。
              {EmbeddedReportStatus[mriData.embeddedLpixelReport]}
            </div>
          </FormControl>
        )}

        <input className='visually-hidden' type='submit' id='result-table-submit' formNoValidate={!!selected} />
      </form>
      <>
        <RegistrationModalMriResultTable matchPatients={matchPatients} setSelected={setSelected} selected={selected} isDisabled={isDisabled} />
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            padding: '1rem',
            backgroundColor: 'white',
            zIndex: 1000,
            border: '1px solid var(--color-cerulean)',
            borderTop: 'none',
          }}
        >
          <label
            htmlFor='result-table-submit'
            style={{ width: 'max-content' }}
            className={`btn btn--additional ${isDisabled || !selected ? 'btn--disabled' : null}`}
          >
            受診履歴を追加
          </label>
          <label
            htmlFor='result-table-submit'
            style={{ width: 'max-content' }}
            className={`btn btn--main ${isDisabled || !!selected ? 'btn--disabled' : null}`}
          >
            新規受診を登録
          </label>
        </div>
      </>
    </>
  );
};

export default memo(RegistrationModalMriResult);
