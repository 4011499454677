export const delayForLoadingScreen = 300;
export const flashMessageTime = 5000;
export enum FlashMessageType {
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  Info = 'info',
}

export const Colors = {
  cerulean: '#009EE5',
  black: '#000000',
  burntSienna: '#EF5350',
  orangePeel: '#FF9800',
  fruitSalad: '#4CAF50',
  ceruleanDark: '#03A9F4',
  mobster: '#79747E',
  gray: '#79747E',
  white: '#FFFFFF',
  red: '#DB2B2B',
};

export const SIZE_LIMIT_BYTES = 99_614_720; // 95 MB limit for one part of MRI's
