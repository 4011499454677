import { Box, Tab, Tabs } from '@mui/material';
import React, { FC, SyntheticEvent, useRef, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import RegistrationModalLink from './RegistrationModalLink';
import RegistrationModalManual from './RegistrationModalManual';
import RegistrationModalMri from './RegistrationModalMri';

import { FlashMessageType } from '../const';
import { TDataSet } from '../types';
import { isDevMode } from '../utils';

const hospitalCustomUrl = document.querySelector<HTMLDivElement>('#h-custom_url');
// const HOSPITALS = ['irimoto', 'shiodome-cl', 'ueno-cl', 'ichibanchou-cl'];
const HOSPITALS: Array<string> = [];

type TRegistrationModal = {
  dataset: TDataSet;
  setIsBehind: (value: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  beforeClose: { flag: boolean; message: string };
  setBeforeClose: (value: { flag: boolean; message: string }) => void;
  removeModal: () => void;
};

enum TabType {
  Mri = 'mri',
  Manual = 'manual',
  Link = 'link',
  Batch = 'batch',
}

const TabsList = [
  {
    name: 'MRI画像アップロード登録',
    type: TabType.Mri,
  },
  {
    name: 'フォーム入力登録',
    type: TabType.Manual,
  },
  {
    name: 'フォーム送信',
    type: TabType.Link,
  },
  {
    name: '大容量ファイルアップローダー',
    type: TabType.Batch,
  },
];

const CurrentTabs = {
  mri: RegistrationModalMri,
  manual: RegistrationModalManual,
  link: RegistrationModalLink,
  batch: RegistrationModalMri,
};

const RegistrationModal: FC<TRegistrationModal> = ({ dataset, setIsBehind, isDisabled, setIsDisabled, beforeClose, setBeforeClose, removeModal }) => {
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.Mri);
  const anonymizeDataToggle = useRef<HTMLInputElement>(document.querySelector('#anonymize-data'));

  const onChangeHandler = (_: SyntheticEvent, value: TabType) => {
    if (beforeClose.flag) {
      setIsBehind(true);

      FlashMessageCreate({
        type: FlashMessageType.Warning,
        message: beforeClose.message,
        onAccept: () => {
          setBeforeClose({ flag: false, message: '' });
          setCurrentTab(value);
        },
        onReject: () => {},
        onFinally: () => {
          setIsBehind(false);
        },
      });
      return;
    }
    setCurrentTab(value);
  };

  const CurrentTab = CurrentTabs[currentTab as keyof typeof CurrentTabs];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', mb: '2rem', mt: '-50px' }}>
        <Tabs value={currentTab} onChange={onChangeHandler} centered>
          {TabsList.map(({ name, type }) => {
            if (type === TabType.Link && anonymizeDataToggle.current?.checked) {
              return null;
            }
            if (type === TabType.Batch) {
              if (HOSPITALS.includes(hospitalCustomUrl?.innerText || '') || isDevMode()) {
                return (
                  <Tab
                    label={name}
                    value={type}
                    key={name}
                    disabled={isDisabled}
                    onClick={() => {
                      setCurrentTab(currentTab);
                      window.open(window.location.origin + '/batch');
                    }}
                  />
                );
              } else {
                return null;
              }
            }
            return <Tab label={name} value={type} key={name} disabled={isDisabled} />;
          })}
        </Tabs>
      </Box>
      <CurrentTab
        dataset={dataset}
        setIsBehind={setIsBehind}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        setBeforeClose={setBeforeClose}
        removeModal={removeModal}
        anonymizeDataToggle={anonymizeDataToggle.current?.checked}
      />
    </>
  );
};

export default RegistrationModal;
