import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { createTheme, IconButton, ThemeProvider } from '@mui/material';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Here you need import all modal components that you plan to use.
import ConfirmationPayForReportModal from './components/ConfirmationPayForReportModal';
import ConfirmationReport from './components/ConfirmationReport';
import ConfirmationReportA from './components/ConfirmationReportA';
import DoctorCommentChange from './components/DoctorCommentChange';
import EmployeeManagement from './components/EmployeeManagement';
import FlashMessageCreate from './components/FlashMessage';
import PatientPersonalInfoWarningModal from './components/PatientPersonalInfoWarningModal/PatientPersonalInfoWarningModal';
import PrepaidDeleteCodeModal from './components/PrepaidDeleteCodeModal';
import PrepaidLatestVisitModal from './components/PrepaidLatestVisitModal';
import PreviewReportModal from './components/PreviewReportModal';
import QRCodeModal from './components/QRCodeModal';
import RegistrationModal from './components/RegistrationModal';
import RegistrationModalMriResultConfirmation from './components/RegistrationModalMriResultConfirmation';
import RemoveVisit from './components/RemoveVisit';
import ReportLayout from './components/ReportLayout';
import PatientRegisterConfirmation from './components_tmp/patient-register/PatientRegisterConfirmation';
import { Colors, FlashMessageType } from './const';
import { TDataSet } from './types';
import { overlayObserver } from './utils';

// Here you need associate all modal components that you plan to use.
const Modals = {
  employeeManagement: EmployeeManagement,
  confirmationReportA: ConfirmationReportA,
  confirmationReport: ConfirmationReport,
  removeVisit: RemoveVisit,
  reportLayout: ReportLayout,
  registrationModal: RegistrationModal,
  registrationModalMriResultConfirmation: RegistrationModalMriResultConfirmation,
  patientRegisterConfirmation: PatientRegisterConfirmation,
  doctorCommentChange: DoctorCommentChange,
  prepaidDeleteCodeModal: PrepaidDeleteCodeModal,
  prepaidLatestVisitModal: PrepaidLatestVisitModal,
  previewReportModal: PreviewReportModal,
  confirmationPayForReportModal: ConfirmationPayForReportModal,
  qrCodeModal: QRCodeModal,
  patientPersonalInfoWarningModal: PatientPersonalInfoWarningModal,
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.cerulean,
    },
    error: {
      main: Colors.burntSienna,
    },
    warning: {
      main: Colors.orangePeel,
    },
    success: {
      main: Colors.fruitSalad,
    },
    info: {
      main: Colors.ceruleanDark,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // Font size for all InputLabels
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem', // Font size for all OutlinedInputs
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem', // Font size for all FormControlLabel
        },
      },
    },
  },
});

type TMainModal = {
  dataset: TDataSet;
  style?: CSSProperties;
};

/**
 *  This is a MainModal window which contain implementation of closing by pressing 'Escape' and clicking outside the MainModal window.
 *
 * @param component FunctionComponent which will be displayed inside of MainModal.
 * @returns FunctionComponent
 */
const MainModal: FC<TMainModal> = ({ dataset, style }: TMainModal) => {
  const overlay = document.querySelector<HTMLDivElement>('#overlay');
  const mainNav = document.querySelector<HTMLInputElement>('#main-nav');

  const [isBehind, setIsBehind] = useState(false);
  const [isDisabled, setIsDisabled] = useState(dataset.isDisabled || false);
  const [beforeClose, setBeforeClose] = useState({ flag: false, message: '' });

  const removeParent = () => {
    const parent = document.querySelector(`#${dataset.modalName}`);

    if (parent) {
      ReactDOM.unmountComponentAtNode(parent);
      parent.remove();
    }
  };

  const removeModal = (force: boolean = false) => {
    // Enabling previous Drag&Drop area.
    if (dataset.modalName === 'registrationModal') window.PatientTable.setModal(null);
    if (dataset.onReject) dataset.onReject();
    if (force) {
      removeParent();
      return;
    }
    if (beforeClose.flag) {
      setIsBehind(true);

      FlashMessageCreate({
        type: FlashMessageType.Warning,
        message: beforeClose.message,
        onAccept: () => {
          removeParent();
        },
        onReject: () => {},
        onFinally: () => {
          setIsBehind(false);
        },
      });
      return;
    }
    removeParent();
  };

  useEffect(() => {
    // Removes previous flash Message to prevent overlapping.
    document.querySelector<HTMLDivElement>('#flash-message-react')?.remove();

    // Run every time on init MainModal
    if (mainNav) mainNav.checked = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onWindowLeave = (evt: BeforeUnloadEvent) => {
      evt.preventDefault();
      evt.returnValue = '';
    };
    const onDocumentKeydown = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        removeModal();
      }
    };
    const onDocumentClick = ({ target }: MouseEvent) => {
      if (target === overlay) {
        removeModal();
      }
    };

    if (!isDisabled) document.addEventListener('keydown', onDocumentKeydown);
    if (!isDisabled) document.addEventListener('click', onDocumentClick);
    if (beforeClose.flag) window.addEventListener('beforeunload', onWindowLeave);

    return () => {
      if (!isDisabled) document.removeEventListener('keydown', onDocumentKeydown);
      if (!isDisabled) document.removeEventListener('click', onDocumentClick);
      if (beforeClose.flag) window.removeEventListener('beforeunload', onWindowLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, beforeClose]);

  useEffect(() => {
    const parent = document.querySelector(`#${dataset.modalName}`) as HTMLDivElement;
    parent.style.zIndex = isBehind ? '98' : '100';
  }, [isBehind, dataset.modalName]);

  // Getting modal from list of all modals.
  const Modal = Modals[dataset.modalName as keyof typeof Modals];

  return (
    <div className='modal-main' style={{ ...style, zIndex: isBehind ? 98 : 100 }}>
      <ThemeProvider theme={theme}>
        <Modal
          setIsBehind={setIsBehind}
          dataset={dataset}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          beforeClose={beforeClose}
          setBeforeClose={setBeforeClose}
          removeModal={removeModal}
          removeModalLight={removeParent}
        />
        <IconButton
          className='modal-main__close-btn'
          onClick={() => removeModal()}
          color='primary'
          disabled={dataset.modalName === 'registrationModal' ? false : isDisabled}
        >
          <HighlightOffIcon />
        </IconButton>
      </ThemeProvider>
    </div>
  );
};

export const MainModalCreate = (dataset: TDataSet, style?: CSSProperties) => {
  const root = document.createElement('div');
  root.id = dataset.modalName;
  root.dataset.reactModal = '';
  document.body.appendChild(root);
  overlayObserver.observe(root);
  // Disabling previous Drag&Drop area.
  if (dataset.modalName === 'registrationModal') window.PatientTable.setModal(root);
  ReactDOM.render(<MainModal dataset={dataset} style={style} />, root);
};

export default MainModal;
