import React, { FC, useState } from 'react';

import { MainModalCreate } from '../MainModal';
import { TDataSet } from '../types';
import { generateReportLink } from '../utils';

type TPreviewReportModal = {
  dataset: TDataSet;
  isDisabled?: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
  setIsBehind: (value: boolean) => void;
};

const PreviewReportModal: FC<TPreviewReportModal> = ({ dataset, isDisabled, setIsDisabled, removeModal, setIsBehind }) => {
  const { link, dataLink, reportStatus } = dataset;
  const [reportStatusBool, setReportStatusBool] = useState(String(reportStatus) !== 'false');

  const handleConfirmationModal = () =>
    new Promise<void>((resolve, reject) => {
      MainModalCreate({
        modalName: 'confirmationPayForReportModal',
        onAccept: () => {
          setReportStatusBool(true);
          resolve();
        },
        onReject: () => reject(),
      });
    });

  const startPDFGeneration = async () => {
    // This function is used to open a new window to prevent a popup warning and start the PDF generation process. When the process is done, the window will be redirected to the generated report.
    let tempWindow = window.open('', '_blank');
    if (tempWindow && tempWindow.document) {
      tempWindow.document.title = '処理中･･･';

      const style = `
        <style>
          html, body {
            background-color: white;
            height: 100%;
          }
        </style>
      `;

      const message = `
      <div style="text-align:center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        処理中･･･
      </div>
    `;

      tempWindow.document.head.innerHTML += style;
      tempWindow.document.body.innerHTML = message;
    }

    try {
      const [content] = await generateReportLink(dataLink as string, 'post');
      if (content) {
        if (tempWindow) tempWindow.location.href = content;
      }
    } catch (err) {
      if (tempWindow) {
        tempWindow.document.body.innerHTML =
          '<p style="text-align:center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">レポートの生成は失敗しました。もう一度お試しください。</p>';
      }
      console.error(err);
    }
  };

  const handleOpenPrint = async () => {
    setIsDisabled(true);

    try {
      if (!reportStatusBool) {
        setIsBehind(true);
        await handleConfirmationModal();
      }
      await startPDFGeneration();
    } catch (err) {
      if (err) console.log(err);
    } finally {
      setIsDisabled(false);
      setIsBehind(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
          backgroundColor: 'var(--color-tundora)',
          padding: '1rem',
          margin: 'calc(1% - 1px - 1vw) calc(50% - 50vw)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          zIndex: 1000,
          boxSizing: 'border-box',
        }}
      >
        <button className='btn btn--additional btn--fix' type='button' onClick={removeModal} disabled={isDisabled}>
          閉じる
        </button>
        <button
          style={{ marginRight: '3rem' }}
          className={`btn btn--main btn--fix ${isDisabled ? 'btn--loading' : ''}`}
          type='button'
          onClick={handleOpenPrint}
          disabled={isDisabled}
        >
          {isDisabled ? '処理中...' : reportStatusBool ? '再ダウンロード' : '確定'}
        </button>
      </div>
      {link && <img src={link} alt='Preview Report' />}
    </>
  );
};

export default PreviewReportModal;
