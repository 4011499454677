const MainNavInit = () => {
  const toggle = document.querySelector('#main-nav');

  if (toggle) {
    const toggleLabel = document.querySelector('[for="main-nav"]');
    const mainNav = document.querySelector('ul.header__nav-list');

    document.addEventListener('click', (evt) => {
      if (evt.target.id !== 'main-nav') toggle.checked = false;
    });
    mainNav.addEventListener('click', (evt) => evt.stopPropagation());
    toggleLabel.addEventListener('click', (evt) => evt.stopPropagation());
  }
};

export default MainNavInit;
