import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import API from '../API';
import { FlashMessageType } from '../const';

type TRegistrationModalLink = {
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  setBeforeClose: (value: { flag: boolean; message: string }) => void;
  removeModal: (value: boolean) => void;
};

const InputLabelNames = {
  ManualEmail: 'メールアドレス',
};

const RegistrationModalLink: FC<TRegistrationModalLink> = ({ isDisabled, setIsDisabled, setBeforeClose, removeModal }) => {
  const imgWrapper = useRef<HTMLDivElement>(document.querySelector('.registration-modal__img'));
  const [imgHeight, setImgHeight] = useState('5px');
  const [manualEmail, setManualEmail] = useState('');

  const onFormSubmitHandle = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setIsDisabled(true);

    const formData = new FormData();
    let message = '';
    let type = FlashMessageType.Success;

    formData.set('email', manualEmail);

    API.sendEmail(formData)
      .then(({ data }) => {
        message = data.msgUser;
        type = data.msgType;
        setManualEmail('');
        removeModal(true); // Optional
      })
      .catch((err) => {
        message = err.response.data.msgUser;
        type = err.response.data.msgType;
      })
      .finally(() => {
        FlashMessageCreate({ message, type, isSelfDestroyable: true });
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    if (manualEmail.length) {
      setBeforeClose({ flag: true, message: 'メールはまだ送信されていません。やめますか？' });
    }

    return () => {
      setBeforeClose({ flag: false, message: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualEmail]);
  useEffect(() => {
    setImgHeight(`${imgWrapper.current?.clientHeight}px`);
  }, [imgWrapper]);

  return (
    <div className='registration-modal__link'>
      <form className='registration-modal__link-form' onSubmit={onFormSubmitHandle}>
        <h5>URLをメールで送信する</h5>
        <FormControl variant='outlined' size='small' disabled={isDisabled}>
          <InputLabel htmlFor='email'>{InputLabelNames.ManualEmail}</InputLabel>
          <OutlinedInput
            id='email'
            name='email'
            type='email'
            value={manualEmail}
            label={InputLabelNames.ManualEmail}
            onInput={(evt: ChangeEvent<HTMLInputElement>) => setManualEmail(evt.target.value)}
            required
          />
        </FormControl>
        <button className={`btn btn--main ${isDisabled ? 'btn--loading' : ''}`} type='submit' disabled={isDisabled}>
          登録フォームの送信
        </button>
      </form>
      <hr />
      <div className='registration-modal__img' ref={imgWrapper}>
        <h5>登録用URLをQRコードで読み取る</h5>
        <a href={API.getQrcode()} target='_blank' rel='noreferrer'>
          <img width={imgHeight} src={API.getQrcode(0)} alt='' />
        </a>
      </div>
    </div>
  );
};

export default RegistrationModalLink;
