import moment from 'moment';
import React, { FC } from 'react';

import { genderTable } from './RegistrationModalMriResult';

import { TDataSet } from '../types';

type TRegistrationModalMriResultConfirmation = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const RegistrationModalMriResultConfirmation: FC<TRegistrationModalMriResultConfirmation> = ({ dataset, isDisabled, setIsDisabled, removeModal }) => {
  const convertDate = (date: moment.MomentInput) => moment(date).format('LL'); // TODO: later move to utils.ts

  return (
    <div className='rmmrc'>
      <h2 className='rmmrc__title'>以下の受診者が登録されます。</h2>
      <h3 className='rmmrc__info'>{dataset.patientId ? '過去受験あり' : '新規登録'}</h3>
      <ul className='rmmrc__list'>
        {dataset.patientId ? (
          <li className='rmmrc__item'>
            <span>Brain-ID:</span>
            <span>{dataset.patientId}</span>
          </li>
        ) : null}
        {dataset.hospitalPatientId ? (
          <li className='rmmrc__item'>
            <span>院内ID:</span>
            <span>{dataset.hospitalPatientId}</span>
          </li>
        ) : null}
        <li className='rmmrc__item'>
          <span>名前:</span>
          <span>{dataset.patientName}</span>
        </li>
        {dataset.birthDate ? (
          <li className='rmmrc__item'>
            <span>生年月日:</span>
            <span>{convertDate(dataset.birthDate)}</span>
          </li>
        ) : null}
        <li className='rmmrc__item'>
          <span>性別:</span>
          <span>{genderTable[dataset.gender as keyof typeof genderTable]}</span>
        </li>
        {dataset.prepaidCode ? (
          <li className='rmmrc__item'>
            <span>チケット番号:</span>
            <span>{dataset.prepaidCode}</span>
          </li>
        ) : null}
      </ul>
      <div className='rmmrc__btn-wrapper'>
        <button
          className='rmmrc__btn btn btn--additional'
          type='button'
          onClick={() => {
            if (dataset.onReject) dataset.onReject();
            removeModal();
          }}
          disabled={isDisabled}
        >
          編集に戻る
        </button>
        <button
          className={`rmmrc__btn btn btn--main ${isDisabled ? 'btn--loading' : ''}`}
          type='submit'
          onClick={() => {
            if (dataset.onAccept) dataset.onAccept(removeModal, setIsDisabled);
          }}
          disabled={isDisabled}
        >
          登録
        </button>
      </div>
    </div>
  );
};

export default RegistrationModalMriResultConfirmation;
