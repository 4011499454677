import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import React, { FC } from 'react';

import Logo from '../Logo';

const InputLabelNames = {
  email: 'メールアドレス',
};

const RequestReset: FC = () => {
  return (
    <form className='auth-form' method='post'>
      <Logo />
      <h3>パスワード初期化 URL送信</h3>
      <p>ユーザー登録時のEmailアドレスを入力してください</p>
      <FormControl variant='outlined' size='small' sx={{ width: 300 }}>
        <InputLabel htmlFor='email'>{InputLabelNames.email}</InputLabel>
        <OutlinedInput id='email' name='email' type='email' label={InputLabelNames.email} required />
      </FormControl>

      <button className='btn btn--main btn--fix' type='submit'>
        送信
      </button>
    </form>
  );
};

export default RequestReset;
