import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React, { FC, memo, useEffect, useRef, useState } from 'react';

import { genderTable, TMatchPatient } from './RegistrationModalMriResult';

type TRegistrationModalMriResultTable = {
  matchPatients: Array<TMatchPatient>;
  setSelected: (value: number | undefined | ((value: number | undefined) => number | undefined)) => void;
  selected: number | undefined;
  isDisabled: boolean;
};

const RegistrationModalMriResultTable: FC<TRegistrationModalMriResultTable> = ({ matchPatients = [], setSelected, selected, isDisabled }) => {
  const convertDate = (date: moment.MomentInput) => moment(date).format('LL'); // TODO: later move to utils.ts
  const headRow = useRef<HTMLTableRowElement>(document.querySelector('#head-row-for-calc'));
  const bodyRow = useRef<HTMLTableRowElement>(document.querySelector('#body-row-for-calc'));
  const [minTableHeight, setMinTableHeight] = useState(0);

  useEffect(() => setMinTableHeight((headRow.current?.clientHeight || 106) + (bodyRow.current?.clientHeight || 106)), [headRow, bodyRow]);

  return (
    <>
      <h3 style={{ margin: '1rem 0 1rem 0', alignSelf: 'start', fontSize: '1.125rem' }}>類似の受診者情報</h3>
      <TableContainer component={Paper} sx={{ mb: '1rem', maxHeight: '80vh', minHeight: `${minTableHeight}px` }}>
        <Table stickyHeader>
          <TableHead sx={{ fontWeight: 900 }}>
            <TableRow id='head-row-for-calc' ref={headRow}>
              <TableCell align='left'>Brain-ID</TableCell>
              <TableCell align='left'>院内ID</TableCell>
              <TableCell align='left'>名前</TableCell>
              <TableCell align='center'>生年月日</TableCell>
              <TableCell align='center'>性別</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchPatients.map((matchPatient) => (
              <TableRow
                id='body-row-for-calc'
                ref={bodyRow}
                hover={!isDisabled}
                selected={selected === matchPatient.patientId}
                onClick={() => {
                  if (!isDisabled)
                    setSelected((prevState) => {
                      return prevState === matchPatient.patientId ? undefined : matchPatient.patientId;
                    });
                }}
                key={matchPatient.patientId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='left'>{matchPatient.patientId}</TableCell>
                <TableCell align='left'>{matchPatient.hospitalPatientId}</TableCell>
                <TableCell align='left'>{matchPatient.patientName.length ? `${matchPatient.patientName}` : matchPatient.kanjiName}</TableCell>
                <TableCell align='center'>{matchPatient.birthdate ? convertDate(matchPatient.birthdate) : ''}</TableCell>
                <TableCell align='center'>{genderTable[matchPatient.gender]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(RegistrationModalMriResultTable);
