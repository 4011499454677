import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from 'react';

import { debounce } from '../utils';

/**
 * A custom React hook that lazily invokes a side effect after a certain delay.
 *
 * This hook is especially useful when you want to delay the invocation of a side effect
 * in response to rapid changes in some state or props, such as user input or window resizing.
 *
 * @function
 * @param {EffectCallback} effect - The side effect function to invoke after the specified delay.
 * @param {DependencyList} [deps=[]] - An array of dependencies which determines when the side effect should run.
 *                                     The side effect will run whenever one of the dependencies has changed.
 * @param {number} [wait=300] - The delay (in milliseconds) after which the side effect will be invoked.
 * @returns {void}
 *
 * @example
 * useLazyEffect(() => {
 *   // Your side effect logic here...
 * }, [someDependency], 500);
 */
export const useLazyEffect = (effect: EffectCallback, deps: DependencyList = [], wait = 300) => {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  effectRef.current = useCallback(effect, deps);
  const lazyEffect = useCallback(
    debounce(() => (cleanUp.current = effectRef.current?.()), wait),
    [],
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => {
      return cleanUp.current instanceof Function ? cleanUp.current() : undefined;
    };
  }, []);
};
