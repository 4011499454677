import axios from 'axios';

import API from '../API';
import { MainModalCreate } from '../MainModal';
import FlashMessageCreate from '../components/FlashMessage';
import { FlashMessageType } from '../const';
import { getPatientIdFormUrl, getVisitIdFormUrl } from '../utils';

const PrepaidRegistration = () => {
  const formElement = document.querySelector<HTMLFormElement>('#prepaid-form');
  const deletePrepaidBtn = document.querySelector('#delete-prepaid-btn') as HTMLButtonElement;
  const removeVisitBtn = document.querySelector('#removeVisitBtn') as HTMLButtonElement;

  if (formElement) {
    const formInputElement = formElement.querySelector('input') as HTMLInputElement;
    const formButtonElement = formElement.querySelector('button') as HTMLButtonElement;

    const isLatestVisit = formElement.getAttribute('data-latest-visit')?.toLowerCase() === 'true';

    formElement.addEventListener('submit', (evt: Event) => {
      evt.preventDefault();

      const formData = new FormData(formElement);

      let message = '';
      let type = FlashMessageType.Success;

      const submitForm = () => {
        // Disable input and button while processing
        formInputElement.readOnly = true;
        formButtonElement.disabled = true;
        deletePrepaidBtn.disabled = true;
        removeVisitBtn.classList.toggle('disabled', true);

        API.setPrepaidRegister(getPatientIdFormUrl(), getVisitIdFormUrl(), formData)
          .then(({ data }) => {
            message = data.msgUser;
            type = data.msgType;
            // On success enable delete prepaid code button
            deletePrepaidBtn.disabled = false;
          })
          .catch((err: unknown) => {
            // If failed, enable input and buttons again
            formInputElement.readOnly = false;
            formButtonElement.disabled = false;
            removeVisitBtn.classList.toggle('disabled', false);

            if (axios.isAxiosError(err)) {
              message = err.response?.data.msgUser;
              type = err.response?.data.msgType;
            } else {
              console.error(err);
              throw Error('Something bad happened');
            }
          })
          .finally(() => {
            FlashMessageCreate({ message, type, isSelfDestroyable: true });
          });
      };

      if (!isLatestVisit) {
        MainModalCreate({
          modalName: 'prepaidLatestVisitModal',
          onAccept: submitForm,
        });
      } else {
        submitForm();
      }
    });

    deletePrepaidBtn.addEventListener('click', (evt: Event) => {
      evt.preventDefault();

      MainModalCreate({
        modalName: 'prepaidDeleteCodeModal',
        onAccept: () => {
          // Disable buttons while processing
          formButtonElement.disabled = true;
          deletePrepaidBtn.disabled = true;

          const formData = new FormData(formElement);

          let message = '';
          let type = FlashMessageType.Success;

          API.removePrepaidRegister(getPatientIdFormUrl(), getVisitIdFormUrl(), formData)
            .then(({ data }) => {
              message = data.msgUser;
              type = data.msgType;

              // On success clear input and enable it. Enable delete visit button
              formButtonElement.disabled = false;
              formInputElement.readOnly = false;
              formInputElement.value = '';
              removeVisitBtn.classList.toggle('disabled', false);
            })
            .catch((err: unknown) => {
              // If failed, enable delete
              deletePrepaidBtn.disabled = false;
              if (axios.isAxiosError(err)) {
                message = err.response?.data.msgUser;
                type = err.response?.data.msgType;
              } else {
                console.error(err);
                throw Error('Something bad happened');
              }
            })
            .finally(() => {
              FlashMessageCreate({ message, type, isSelfDestroyable: true });
            });
        },
      });
    });
  }
};

export default PrepaidRegistration;
