import { Modal } from '../utils';

const SupportModalInit = () => {
  const modal = document.querySelector('.support-modal');

  if (modal) {
    const modalCloseBtn = modal.querySelector('#support-modal-close-btn');
    const mainNav = document.querySelector('#main-nav');
    const mainNavLink = document.querySelector('#main-nav-support-link');
    const modalListeners = new Modal(modal);

    mainNavLink.addEventListener('click', () => {
      mainNav.checked = false;
      modalListeners.addListener();
    });

    modalCloseBtn.addEventListener('click', modalListeners.removeListener);
  }
};

export default SupportModalInit;
