export const loadingScreenOn = () => {
  document.querySelector('.loading-screen').style.display = 'block';
  document.querySelector('body').style.overflow = 'hidden';
};

export const loadingScreenOff = () => {
  document.querySelector('.loading-screen').style.display = 'none';
  if (document.querySelector('body').classList.contains('base-patients-list')) {
    document.querySelector('body').style.overflow = 'hidden';
  } else {
    document.querySelector('body').style.overflow = 'unset';
  }
};
