import API from '../API';
import { MainModalCreate } from '../MainModal';
import { debounce, getPatientIdFormUrl, getVisitIdFormUrl } from '../utils';

const DoctorComment = () => {
  const doctorCommentBlockElements = Array.from<HTMLDivElement>(document.querySelectorAll('[data-doctor-comment]'));

  if (doctorCommentBlockElements.length) {
    doctorCommentBlockElements.forEach((doctorCommentBlock) => {
      const doctorCommentElement = doctorCommentBlock.querySelector<HTMLTextAreaElement>('#doctor-comment') as HTMLTextAreaElement;
      const doctorCommentDefaultValue = doctorCommentBlock.querySelector<HTMLDivElement>('#doctor-comment-default')?.innerHTML as string;
      const doctorCommentResetElement = doctorCommentBlock.querySelector<HTMLButtonElement>('#doctor-comment-reset') as HTMLSpanElement;
      const doctorCommentCountElement = doctorCommentBlock.querySelector<HTMLSpanElement>('#doctor-comment-count') as HTMLSpanElement;
      const doctorCommentLoadingElement = doctorCommentBlock.querySelector<HTMLDivElement>('#doctor-comment-loading') as HTMLDivElement;

      // Set initial values
      doctorCommentCountElement.innerHTML = `${doctorCommentElement.value.length}`;

      const checkResetBtn = () => {
        if (doctorCommentDefaultValue !== doctorCommentElement.value) {
          doctorCommentResetElement.style.display = 'flex';
        } else {
          doctorCommentResetElement.style.display = 'none';
        }
      };
      const sendDoctorComment = (value: string, type: string) => {
        const data = new FormData();
        data.set('doctorComment', value);
        data.set('doctorCommentType', type);
        API.setDoctorComment(getPatientIdFormUrl(), getVisitIdFormUrl(), data).then(() => {
          doctorCommentLoadingElement.classList.add('doctor-comment-loading__loaded');
          doctorCommentLoadingElement.classList.remove('doctor-comment-loading__active');
        });
      };
      const sendDoctorCommentDebounced = debounce(sendDoctorComment, 500);
      const inputHandler = (evt: Event) => {
        evt.preventDefault();
        doctorCommentLoadingElement.classList.remove('doctor-comment-loading__loaded');
        doctorCommentLoadingElement.classList.add('doctor-comment-loading__active');
        const target = evt.target as HTMLTextAreaElement;
        doctorCommentCountElement.innerHTML = `${target.value.length}`;
        sendDoctorCommentDebounced(target.value, doctorCommentBlock.dataset.doctorComment as string);
        checkResetBtn();
      };
      const clickHandler = (evt: MouseEvent) => {
        evt.preventDefault();
        MainModalCreate({
          modalName: 'doctorCommentChange',
          onAccept: () => {
            doctorCommentElement.value = doctorCommentDefaultValue;
            doctorCommentLoadingElement.classList.remove('doctor-comment-loading__loaded');
            doctorCommentLoadingElement.classList.add('doctor-comment-loading__active');
            doctorCommentCountElement.innerHTML = `${doctorCommentDefaultValue.length}`;
            sendDoctorCommentDebounced(doctorCommentElement.value, doctorCommentBlock.dataset.doctorComment as string);
            checkResetBtn();
          },
        });
      };

      checkResetBtn();
      doctorCommentLoadingElement.classList.remove('doctor-comment-loading__loaded');
      doctorCommentLoadingElement.classList.add('doctor-comment-loading__active');
      sendDoctorComment(doctorCommentElement.value, doctorCommentBlock.dataset.doctorComment as string);
      doctorCommentElement.addEventListener('input', inputHandler);
      doctorCommentResetElement.addEventListener('click', clickHandler);
    });
  }
};

export default DoctorComment;
