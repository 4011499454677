import React, { FC } from 'react';

import { TDataSet } from '../types';

type TPrepaidDeleteCodeModal = {
  dataset: TDataSet;
  removeModal: () => void;
};

const PrepaidDeleteCodeModal: FC<TPrepaidDeleteCodeModal> = ({ dataset, removeModal }) => {
  const { onAccept, onReject } = dataset;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
      <p style={{ padding: 0, margin: 0, textAlign: 'center' }}>
        本当に予約番号を削除しますか？
        <br />
        <br />
        <span style={{ color: 'var(--color-cerulean)' }}>※現在入力されているチケットは削除されます。</span>
      </p>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <button
          className='btn btn--additional'
          type='button'
          onClick={() => {
            if (onAccept) onAccept();
            removeModal();
          }}
        >
          はい
        </button>
        <button
          className='btn btn--main'
          type='button'
          onClick={() => {
            if (onReject) onReject();
            removeModal();
          }}
        >
          いいえ
        </button>
      </div>
    </div>
  );
};

export default PrepaidDeleteCodeModal;
