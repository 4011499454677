import { Modal } from '../utils';

const SendEmailManuallyInit = () => {
  if (document.querySelector('#send-email-manually-open-btn')) {
    const openModalBtn = document.querySelector('#send-email-manually-open-btn');
    const modal = document.querySelector('#send-email-manually');
    const closeModalBtn = modal.querySelector('#send-email-manually-close-btn');
    const modalListeners = new Modal(modal);

    openModalBtn.addEventListener('click', modalListeners.addListener);
    closeModalBtn.addEventListener('click', modalListeners.removeListener);
  }
};

export default SendEmailManuallyInit;
