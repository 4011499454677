import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Input, InputLabel, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import API from '../API';
import { FlashMessageType } from '../const';

type TEmployeeManagement = {
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  setIsBehind: (value: boolean) => void;
};

const EmployeeManagement: FC<TEmployeeManagement> = ({ isDisabled, setIsDisabled, setIsBehind }) => {
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState<any>(undefined);

  const onDeleteBtnClick = (id: number) => {
    setIsBehind(true);
    setIsDisabled(true);
    FlashMessageCreate({
      message: 'この管理ユーザーを削除しますか？',
      type: FlashMessageType.Warning,
      onAccept: () => {
        API.deleteEmployees(id)
          .then(() => API.getEmployees().then(({ data }) => setUsers(data)))
          .catch(({ response }) => {
            FlashMessageCreate({ message: response.data.error, type: FlashMessageType.Error, isSelfDestroyable: true });
          });
      },
      onReject: () => {},
      onFinally: () => {
        setIsBehind(false);
        setIsDisabled(false);
      },
    });
  };

  const onResetBtnClick = (id: number) => {
    setIsBehind(true);
    setIsDisabled(true);
    FlashMessageCreate({
      message: 'この管理ユーザーのパスワードをリセットしますか？\n',
      type: FlashMessageType.Warning,
      onAccept: () => {
        API.resetEmployees(id)
          .then(() => {
            FlashMessageCreate({
              message: 'パスワードリセット確認用メールを管理ユーザーに送信しました。',
              type: FlashMessageType.Success,
              isSelfDestroyable: true,
            });
          })
          .catch(({ response }) => {
            FlashMessageCreate({ message: response.data.error, type: FlashMessageType.Error, isSelfDestroyable: true });
          });
      },
      onReject: () => {},
      onFinally: () => {
        setIsBehind(false);
        setIsDisabled(false);
      },
    });
  };

  const onFormSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();

    API.createEmployees(email)
      .then(() => {
        API.getEmployees().then(({ data }) => setUsers(data));
      })
      .catch(({ response }) => {
        FlashMessageCreate({ message: response.data.error, type: FlashMessageType.Error, isSelfDestroyable: true });
      });
    setEmail('');
  };

  useEffect(() => {
    API.getEmployees().then(({ data }) => setUsers(data));
  }, []);

  const list = users?.map((elem: any) => (
    <ListItem key={elem.employeeId}>
      <ListItemAvatar>
        <Avatar>
          <PersonOutlineIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={elem.email} secondary={moment(elem.creationDate).format('LL')} />
      <Tooltip title='パスワードリセット' arrow>
        <IconButton aria-label='reset' onClick={() => onResetBtnClick(elem.employeeId)}>
          <LockResetIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='削除' arrow>
        <IconButton aria-label='delete' color='error' onClick={() => onDeleteBtnClick(elem.employeeId)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </ListItem>
  ));

  return (
    <>
      <h2 style={{ marginTop: 0 }}>以下のアカウントにBrainsuiteのアクセス権を追加で付与します。</h2>
      <List style={{ overflow: 'auto' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonAddAltIcon />
            </Avatar>
          </ListItemAvatar>
          <form onSubmit={onFormSubmit} style={{ display: 'flex' }}>
            <InputLabel htmlFor='react-email' style={{ margin: 'auto' }}>
              メールアドレス
            </InputLabel>
            <Input
              sx={{ ml: 1, flex: 1 }}
              placeholder='mail@example.com'
              type='email'
              inputProps={{ 'aria-label': 'register' }}
              value={email}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
              autoFocus
              required
            />
            <IconButton type='submit' color='primary' sx={{ p: '10px' }} aria-label='search' disabled={isDisabled}>
              <AddCircleOutlineIcon />
            </IconButton>
          </form>
        </ListItem>
        <Divider />
        {list}
      </List>
    </>
  );
};

export default EmployeeManagement;
