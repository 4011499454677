import { TableCell, TableRow } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { TPatientMriRow } from './PatientMriRow.types';

import { ContrastType } from '../../types';
import Link from '../Link/Link';
import { MRIPatientTableHeadCells } from '../PatientMriList/PatientMriList';
import PatientMriListStatusIcon from '../PatientMriListStatusIcon/PatientMriListStatusIcon';
import PatientMriSelector from '../PatientMriSelector/PatientMriSelector';

const PatientMriRow: FC<TPatientMriRow> = (props) => {
  const { status, normalizedContrast, mriViewerUrl, mriBrainViewerUrl } = props;
  const [contrast, setContrast] = useState<ContrastType>(normalizedContrast);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setContrast(normalizedContrast);
  }, [normalizedContrast]);

  return (
    <TableRow tabIndex={-1} hover={true} sx={{ border: 'none' }}>
      {MRIPatientTableHeadCells.map(({ data, dataAlign }) => {
        if (data === 'brainLink') {
          return (
            <TableCell key={data} align={dataAlign} sx={{ border: 'none', borderTop: '1px solid #CCCCCC' }}>
              <Link external text='開く' link={mriBrainViewerUrl} />
            </TableCell>
          );
        }

        if (data === 'link') {
          return (
            <TableCell key={data} align={dataAlign} sx={{ border: 'none', borderTop: '1px solid #CCCCCC', p: 0 }}>
              <Link external text='開く' link={mriViewerUrl} />
            </TableCell>
          );
        }

        if (data === 'status') {
          return (
            <TableCell key={data} align={dataAlign} sx={{ border: 'none', borderTop: '1px solid #CCCCCC', pr: 0, pl: 1 }}>
              <PatientMriListStatusIcon status={status} />
            </TableCell>
          );
        }

        if (data === 'contrast') {
          return (
            <TableCell key={data} align={dataAlign} padding='none' sx={{ border: 'none', borderTop: '1px solid #CCCCCC' }}>
              <PatientMriSelector {...props} contrast={contrast} setContrast={setContrast} loading={loading} setLoading={setLoading} />
            </TableCell>
          );
        }

        return (
          <TableCell key={data} align={dataAlign} sx={{ border: 'none', borderTop: '1px solid #CCCCCC' }}>
            {props[data]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default PatientMriRow;
