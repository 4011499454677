import HelpIcon from '@mui/icons-material/Help';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, FC, FocusEvent, SyntheticEvent, useEffect, useState } from 'react';

import FlashMessageCreate from './FlashMessage';

import API from '../API';
import { FlashMessageType } from '../const';

type TRegistrationModalManual = {
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  setBeforeClose: (value: { flag: boolean; message: string }) => void;
  removeModal: (value: boolean) => void;
  anonymizeDataToggle?: boolean;
};

const InputLabelNames = {
  PrepaidCode: 'チケット番号',
  HospitalPatientId: '院内ID *',
  KanjiName: '名前 *',
  Birthdate: '生年月日 *',
  Gender: '性別 *',
  Email: 'メールアドレス',
};
const genderTable = {
  male: '男性',
  female: '女性',
  other: '他',
};

const RegistrationModalManual: FC<TRegistrationModalManual> = ({ isDisabled, setIsDisabled, setBeforeClose, removeModal, anonymizeDataToggle }) => {
  const [formValues, setFormValues] = useState({
    prepaidCode: '',
    hospitalPatientId: '',
    kanjiName: '',
    gender: '',
    birthDate: '',
    email: '',
  });

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    evt.target.setCustomValidity('');
    const formattedDate = moment(value).format('YYYY-MM-DD');
    if (formattedDate === 'Invalid date') {
      evt.target.setCustomValidity('日付が正しくありません。');
      return;
    }
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };
  const onFormSubmitHandle = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setIsDisabled(true);

    const formData = new FormData();
    let message = '';
    let type = FlashMessageType.Success;

    for (const [key, value] of Object.entries(formValues)) {
      formData.set(
        key.replace(/[A-Z]/g, (value) => '-' + value.toLowerCase()),
        value,
      );
    }

    API.setPatientManual(formData)
      .then(({ data }) => {
        message = data.msgUser;
        type = data.msgType;
        window.PatientTable.update();
        removeModal(true); // Optional
      })
      .catch((err) => {
        message = err.response.data.msgUser;
        type = err.response.data.msgType;
      })
      .finally(() => {
        FlashMessageCreate({ message, type, isSelfDestroyable: true });
        setIsDisabled(false);
      });
  };
  useEffect(() => {
    if (Object.values(formValues).some((value) => value.length)) {
      setBeforeClose({ flag: true, message: '受診者登録が完了していませんが、本当にこの画面を閉じますか？' });
    }

    return () => {
      setBeforeClose({ flag: false, message: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <form className='registration-modal__manual' onSubmit={onFormSubmitHandle}>
      <div className='registration-modal__border registration-modal__border--secondary'>
        <div className='registration-modal__header registration-modal__header--secondary'>
          受診者がBrainSuiteチケットをお持ちの場合は、チケット番号を入力してください。
        </div>
        <div style={{ padding: '1rem' }}>
          <FormControl variant='outlined' size='small' disabled={isDisabled} fullWidth>
            <InputLabel htmlFor='prepaidCode'>{InputLabelNames.PrepaidCode}</InputLabel>
            <OutlinedInput
              id='prepaidCode'
              name='prepaidCode'
              value={formValues.prepaidCode}
              label={InputLabelNames.PrepaidCode}
              onInput={handleChange}
              endAdornment={
                <Tooltip
                  placement='right'
                  title={
                    <Typography maxWidth={'17rem'} sx={{ fontSize: '14px' }}>
                      事前にBrainSuiteをご購入およびお支払いしている場合、BrainSuiteチケットをお渡ししております。
                      チケット番号を入力することで、ご利用者様の窓口負担金を調整いたします。
                    </Typography>
                  }
                  arrow
                >
                  <IconButton>
                    <HelpIcon sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className='registration-modal__border registration-modal__border--primary'>
        <div className='registration-modal__header registration-modal__header--primary'>必須項目</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'space-around',
            justifyItems: 'center',
            padding: '1rem',
          }}
        >
          <FormControl variant='outlined' size='small' disabled={isDisabled}>
            <InputLabel htmlFor='hospitalPatientId'>{InputLabelNames.HospitalPatientId}</InputLabel>
            <OutlinedInput
              id='hospitalPatientId'
              name='hospitalPatientId'
              value={formValues.hospitalPatientId}
              label={InputLabelNames.HospitalPatientId}
              onInput={handleChange}
              required
            />
          </FormControl>
          <FormControl variant='outlined' size='small' disabled={isDisabled}>
            <InputLabel htmlFor='kanjiName'>
              {anonymizeDataToggle ? InputLabelNames.KanjiName.replace('*', '') : InputLabelNames.KanjiName}
            </InputLabel>
            <OutlinedInput
              id='kanjiName'
              name='kanjiName'
              value={formValues.kanjiName}
              label={anonymizeDataToggle ? InputLabelNames.KanjiName.replace('*', '') : InputLabelNames.KanjiName}
              onInput={handleChange}
              required={!anonymizeDataToggle}
            />
          </FormControl>
          <FormControl variant='outlined' size='small' disabled={isDisabled}>
            <FormLabel sx={{ fontSize: '0.75rem' }}>{InputLabelNames.Gender}</FormLabel>
            <RadioGroup sx={{ marginLeft: '1rem', justifyContent: 'start' }} row name='gender' value={formValues.gender} onChange={handleChange}>
              <FormControlLabel value='male' control={<Radio required />} label={genderTable.male} />
              <FormControlLabel value='female' control={<Radio required />} label={genderTable.female} />
              <FormControlLabel value='other' control={<Radio required />} label={genderTable.other} />
            </RadioGroup>
          </FormControl>
          <FormControl variant='outlined' size='small' disabled={isDisabled}>
            <InputLabel htmlFor='birthDate'>{InputLabelNames.Birthdate}</InputLabel>
            <OutlinedInput
              id='birthDate'
              name='birthDate'
              value={formValues.birthDate}
              label={InputLabelNames.Birthdate}
              onInput={handleChange}
              onBlur={handleBlur}
              required
            />
          </FormControl>
          <FormControl variant='outlined' size='small' disabled={isDisabled}>
            <InputLabel htmlFor='email'>{InputLabelNames.Email}</InputLabel>
            <OutlinedInput id='email' name='email' type='email' value={formValues.email} label={InputLabelNames.Email} onInput={handleChange} />
          </FormControl>
        </div>
      </div>
      <p style={{ fontSize: '0.875rem', fontWeight: 700 }}>※「登録」をクリックすると、受診者にオンラインテストの案内メールを送信します。</p>
      <button className={`btn btn--main ${isDisabled ? 'btn--loading' : ''}`} type='submit' disabled={isDisabled}>
        登録
      </button>
    </form>
  );
};

export default RegistrationModalManual;
