import React, { FC } from 'react';

import { TLink } from './Link.types';

const Link: FC<TLink> = ({ external, link, text }) => {
  return (
    <a className='react-components-link' href={link} target={external ? '_blank' : ''} rel='noreferrer'>
      {text}
    </a>
  );
};

export default Link;
