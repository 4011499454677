import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { TMRIAcquisitionRow, TMRITableHeadCell } from './PatientMriList.types';

import { LocalStorageUpdateDetail, MRIRecord } from '../../types';
import { STORAGE_KEY_PATIENT_MRI } from '../../utils';
import PatientMriRow from '../PatientMriRow/PatientMriRow';

export const MRIPatientTableHeadCells: readonly TMRITableHeadCell[] = [
  {
    data: 'status',
    dataAlign: 'left',
    label: '解析状況',
    align: 'left',
  },
  {
    data: 'link',
    dataAlign: 'left',
    label: '画像',
    align: 'left',
  },
  {
    data: 'acqdate',
    dataAlign: 'left',
    label: 'Date',
    align: 'left',
  },
  {
    data: 'contrast',
    dataAlign: 'left',
    label: 'Contrast',
    align: 'left',
  },
  {
    data: 'protocolName',
    dataAlign: 'left',
    label: 'Protocol Name',
    align: 'left',
  },
];

const PatientMriList: FC<TMRIAcquisitionRow> = () => {
  const [value, setValue] = useState<Array<MRIRecord>>([]);

  useEffect(() => {
    const handleStorageChange = (evt: StorageEvent | CustomEvent<LocalStorageUpdateDetail<Array<MRIRecord>>>) => {
      if (
        (evt instanceof StorageEvent && evt.key === STORAGE_KEY_PATIENT_MRI) ||
        (evt instanceof CustomEvent && evt.detail && evt.detail.key === STORAGE_KEY_PATIENT_MRI)
      ) {
        try {
          const item = window.localStorage.getItem(STORAGE_KEY_PATIENT_MRI);
          setValue(item ? JSON.parse(item) : []);
        } catch (error) {
          console.error(error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange as EventListener);
    window.addEventListener('localstorage-update', handleStorageChange as EventListener);

    return () => {
      window.removeEventListener('storage', handleStorageChange as EventListener);
      window.removeEventListener('localstorage-update', handleStorageChange as EventListener);
    };
  }, []);

  const paddings: { [key in Lowercase<'contrast' | 'status' | 'link'>]?: string } = {
    contrast: '16px 16px 16px 8px',
    status: '16px 0 16px 8px',
    link: '0',
  };

  return value.length ? (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
      <TableContainer component={Paper} sx={{ border: 'none', borderRadius: 'unset', boxShadow: 'unset' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ border: 'none' }}>
              {MRIPatientTableHeadCells.map(({ data, label, align }) => (
                <TableCell
                  component='th'
                  align={align}
                  key={data}
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#707070',
                    border: 'none',
                    p: data === 'contrast' || data === 'status' || data === 'link' ? paddings[data] : 2,
                  }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((mri) => {
              return <PatientMriRow {...mri} key={mri.mriId} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : null;
};

export default PatientMriList;
