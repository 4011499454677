import { createTheme, ThemeProvider } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

// REACT PART
import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import API from './API.ts';
import { MainModalCreate } from './MainModal.tsx';
// REACT PART
import FlashMessageCreate, { FlashMessageDetect } from './components/FlashMessage.tsx';
import PatientCreateVisitButton from './components/PatientCreateVisitButton/PatientCreateVisitButton.tsx';
import PatientMriList from './components/PatientMriList/PatientMriList.tsx';
import PatientMriViewerSelector from './components/PatientMriViewerSelector/PatientMriViewerSelector.tsx';
import PatientPersonalInfo from './components/PatientPersonalInfo/PatientPersonalInfo.tsx';
import PatientTable from './components/PatientTable.tsx';
import Login from './components_tmp/auth/Login.tsx';
import Mfa from './components_tmp/auth/Mfa.tsx';
import RequestReset from './components_tmp/auth/RequestReset.tsx';
import Reset from './components_tmp/auth/Reset.tsx';
import ResetDone from './components_tmp/auth/ResetDone.tsx';
import PatientRegister from './components_tmp/patient-register/PatientRegister.tsx';
import PatientRegisterVisit from './components_tmp/patient-register/PatientRegisterVisit.tsx';
import { Colors, delayForLoadingScreen, FlashMessageType, SIZE_LIMIT_BYTES } from './const';
import EmailSmsTemplateInit from './js/email-sms-template';
import MailSmsHistoryModalInit from './js/mail-sms-history-modal';
import MainNavInit from './js/main-nav';
import { getMriHeaderInfo } from './js/mri-reader';
import NiivueInit from './js/niivue';
import SendEmailManuallyInit from './js/send-email-manually';
import ShowNotificationModalInit from './js/show-notification-modal';
import ShowUnreadNotificationInit from './js/show-unread-notification';
import SupportModalInit from './js/support-modal';
import ToggleHandlerInit from './js/toggle-handler';
import PatientVisitInit from './js/toggle-visited-status';
import EmailValidationInit from './js/validate-email';
import DoctorComment from './ts/DoctorComment.ts';
import PrepaidRegistration from './ts/PrepaidRegistration.ts';

import { generateReportLink, STORAGE_KEY_PATIENT_INFO, STORAGE_KEY_PATIENT_MRI } from './utils';

import ConfirmationSheetInit from '../components/js/confirmation-sheet';

import { loadingScreenOff } from '../components/js/loading-screen';

import PasswordValidationInit from '../components/js/validate-password';

// Styles
import './sass/style.scss';

const Init = async () => {
  try {
    // MRI UPLOAD
    const errorMessageIncorrectFormat = 'DICOMファイルではありません。';
    const successMessageUploadDone = 'アップロードが完了しました。';
    const UPLOAD_BTN_MESSAGE = {
      DONE: '閉じる',
      PROCESSING: 'アップロードの準備中...',
      UPLOAD: 'アップロード',
      UPLOADING: 'アップロード中...',
    };
    const MRI_STATUS_CODE = {
      CONVERSION_FAILED: 'conversion failed',
      PROCESSING_FAILED: 'failed',
      DONE: 'done',
      INPROCESS: 'inprocess',
      RECEIVED: 'received',
    };
    const MRI_STATUS_MESSAGE = {
      CONVERSION_FAILED: '解析できませんでした。画像を確認し再度アップロードして下さい。',
      DONE: '解析完了',
      INPROCESS: '解析中...',
      INQUEUE: '待機中...',
    };

    const CORRECT_FORMAT = 'DICM';
    const CORRECT_SUFFIX_LIST = ['.nii', '.nii.gz', '.mgz', '.img', '.hdr', '.dcm'];
    const CORRECT_SUFFIX_MAX = '.nii.gz';
    const MAX_SUFFIX_LENGTH = CORRECT_SUFFIX_MAX.length;
    const MIN_SUFFIX_LENGTH = CORRECT_SUFFIX_LIST[0].length;
    let GET_HEADER_INFO_TRIGGER = true;
    let mriDataForUploading;
    let mriInfo;
    let idRewriteTrigger = true;
    let acquisitionDateRewriteTrigger = true;

    const mriStatusIcon = document.querySelector('#mri-status-icon');
    const mriStatus = document.querySelector('#mri-status');

    const mriUploadInput = document.querySelector('#mri-upload-input');
    const mriUploadDirInput = document.querySelector('#mri-upload-input-dir');

    const mriUploadModal = document.querySelector('#mri-upload-modal');
    const mriUploadModalStatusMessage = mriUploadModal?.querySelector('#mri-upload-modal-status-message');
    const mriUploadProgressBarWrapper = mriUploadModal?.querySelector('.mri-upload-modal__progress-bar-wrapper');
    const mriUploadProgressBarText = mriUploadProgressBarWrapper?.querySelector('#mri-upload-modal-progress-bar-text');
    const mriUploadProgressBar = mriUploadProgressBarWrapper?.querySelector('#mri-upload-modal-progress-bar');
    const mriUploadProgressBarValue = mriUploadProgressBar?.querySelector('div');
    const mriUploadProgressBarNumber = mriUploadProgressBar?.querySelector('span');
    const mriUploadModalConfirmBtn = mriUploadModal?.querySelector('#mri-upload-modal-submit-btn');
    const mriUploadModalCancelBtn = mriUploadModal?.querySelector('#mri-upload-modal-cancel-btn');
    const mriUploadModalId = mriUploadModal?.querySelector('#mri-upload-modal-id');
    const mriUploadModalName = mriUploadModal?.querySelector('#mri-upload-modal-name');
    const mriUploadModalBirth = mriUploadModal?.querySelector('#mri-upload-modal-birth');
    const mriUploadModalDescription = mriUploadModal?.querySelector('#mri-upload-modal-description');
    const mriUploadModalAcq = mriUploadModal?.querySelector('#mri-upload-modal-acq');
    const mriUploadModalInputs = document.querySelectorAll('[for="mri-upload-input"]');
    const mriUploadModalDirInputs = document.querySelectorAll('[for="mri-upload-input-dir"]');

    // const mriRewriteModal = document.querySelector('#mri-rewrite-modal');
    // const mriRewriteModalConfirmBtn = mriRewriteModal?.querySelector('[type="submit"]');
    // const mriRewriteModalCancelBtn = mriRewriteModal?.querySelector('#mri-rewrite-modal-cancel-btn');

    const idRewriteModal = document.querySelector('#id-rewrite-modal');
    const idRewriteModalConfirmBtn = idRewriteModal?.querySelector('[type="submit"]');
    const idRewriteModalCancelBtn = idRewriteModal?.querySelector('#id-rewrite-modal-cancel-btn');
    // const idRewriteModalPrevValueField = Array.from(idRewriteModal?.querySelectorAll('[data-type="prev"]') || []);
    const idRewriteModalNewValueField = Array.from(idRewriteModal?.querySelectorAll('[data-type="new"]') || []);

    const currentHospitalPatientId = document.querySelector('[name="hospital-patient-id"]')?.value || '';
    const currentAcquisitionDate = document.querySelector('[name="current-acquisition-date"]')?.innerText?.replace('+', '') || '';

    const acquisitionDateRewriteModal = document.querySelector('#acquisition-rewrite-modal');
    const acquisitionDateRewriteModalConfirmBtn = acquisitionDateRewriteModal?.querySelector('[type="submit"]');
    const acquisitionDateRewriteModalCancelBtn = acquisitionDateRewriteModal?.querySelector('#acquisition-rewrite-modal-cancel-btn');
    // const acquisitionDateRewriteModalPrevValueField = Array.from(acquisitionDateRewriteModal?.querySelectorAll('[data-type="prev"]') || []);
    const acquisitionDateRewriteModalNewValueField = Array.from(acquisitionDateRewriteModal?.querySelectorAll('[data-type="new"]') || []);

    const rewriteModalCloseBtns = document.querySelectorAll('#rewrite-modal-close-btn');

    const overlay = document.querySelector('#overlay');

    // Report preview/confirm buttons
    const generateReportABtn = document.querySelector('#generate-report-a');
    const generateReportBBtn = document.querySelector('#generate-report-b');
    const generateReportSBtn = document.querySelector('#generate-report-s');
    const generateReportZBtn = document.querySelector('#generate-report-z');
    const generateReportLpixel = document.querySelector('#generate-report-lpixel');
    const generateReportLpixelJpg = document.querySelector('#generate-report-lpixel-jpg');

    const generateReportAThumbnail = document.querySelector('#generate-report-a-thumbnail');
    const generateReportSThumbnail = document.querySelector('#generate-report-s-thumbnail');

    // Uploader container section
    const uploaderContainerElement = document.querySelector('#uploader-container');
    const uploaderContainerEmptyElement = document.querySelector('#uploader-container-empty');
    const uploaderContainerFillElement = document.querySelector('#uploader-container-fill');
    const uploaderContainerSpinnerElement = document.querySelector('#uploader-container-spinner');

    // Previewing report --> START
    [generateReportABtn, generateReportBBtn, generateReportSBtn, generateReportZBtn].forEach((elem) => {
      elem?.addEventListener('click', (evt) => {
        evt.preventDefault();
        const button = evt.currentTarget;
        const dataLink = button.dataset.link;
        const { defaultText, loadingText, reportStatus } = button.dataset;
        button.innerText = loadingText;
        button.classList.add('btn--loading', 'btn--disabled');

        generateReportLink(dataLink, 'get')
          .then(([content]) => {
            if (content) {
              MainModalCreate({
                modalName: 'previewReportModal',
                link: content,
                dataLink,
                reportStatus: reportStatus,
              });
            }
          })
          .catch((error) => {
            const [, , message] = error;
            FlashMessageCreate({ message, type: FlashMessageType.Error, isSelfDestroyable: true });
          })
          .finally(() => {
            button.innerText = defaultText;
            button.classList.remove('btn--loading', 'btn--disabled');
          });
      });
    });
    // --> Previewing report --> END

    // Downloading LPixel report --> START
    [generateReportLpixel, generateReportLpixelJpg].forEach((elem) => {
      elem?.addEventListener('click', (evt) => {
        evt.preventDefault();

        const { target } = evt;
        const link = target.href;
        const { defaultText, loadingText } = target.dataset;

        target.classList.add('btn--loading', 'btn--disabled');
        target.innerText = loadingText;

        generateReportLink(link, 'post')
          .then(([content, fileName]) => {
            const linkElement = document.createElement('a');
            linkElement.href = content;
            linkElement.setAttribute('download', fileName);
            linkElement.click();

            // Release URL object, remove html element, set preview button to default view.
            window.URL.revokeObjectURL(content);
            linkElement.remove();
            target.classList.remove('btn--loading', 'btn--disabled');
            target.innerText = defaultText;
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          .catch(([_, __, message]) => {
            FlashMessageCreate({ message, type: FlashMessageType.Error, isSelfDestroyable: true });
            target.classList.remove('btn--loading', 'btn--disabled');
            target.innerText = defaultText;
          });
      });
    });
    // Downloading LPixel report --> END

    // Drag&Drop --> START
    const dropAreaDefault = document.querySelector('[data-drag-drop="true"]');
    const dropInfoMessageDefault = document.querySelector('.drag-drop__message--info');
    const dropErrorMessageDefault = document.querySelector('.drag-drop__message--error');
    let dropArea = dropAreaDefault;
    const dropInfoMessage = dropInfoMessageDefault;
    let dropErrorMessage = dropErrorMessageDefault;

    const getFile = async (fileEntry) => {
      try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
      } catch (err) {
        console.error(`Problem with getting file.\n${err}`);
      }
    };

    /**
     * Retrieve all files from a DataTransferItemList with the full directory structure preserved in the filename.
     *
     * Problem:
     * Different browsers handle dragged and dropped folders differently. Firefox maintains the full directory
     * structure in the file name, whereas Chrome does not. This inconsistency can be problematic for applications
     * that rely on the full path of a file.
     *
     * Solution:
     * This function ensures that the full directory structure is preserved in the filename, regardless of the browser being used.
     * It traverses the entire directory/file structure using a BFS approach. As it goes through each file and directory,
     * it maintains the path from the root directory. When it finally reads the file, it prefixes the filename with its
     * entire path, ensuring that the filename is consistent with the behavior observed in Firefox.
     *
     * @param {DataTransferItemList} dataTransferItemList - The list of items to be processed.
     * @returns {Promise<FileList>} - A FileList with files having full paths in their filenames.
     */
    const getAllFileEntries = async (dataTransferItemList) => {
      const fileEntries = [];
      // Use BFS to traverse entire directory/file structure
      const queue = [];
      // Unfortunately dataTransferItemList is not iterable i.e. no forEach
      for (let i = 0; i < dataTransferItemList.length; i++) {
        const entry = dataTransferItemList[i].webkitGetAsEntry();
        queue.push({ entry, path: entry.name });
      }

      while (queue.length > 0) {
        const { entry, path } = queue.shift();

        if (entry.isFile) {
          fileEntries.push({ entry: entry, path });
        } else if (entry.isDirectory) {
          const directory = entry;
          const reader = directory.createReader();
          const entries = await readAllDirectoryEntries(reader);
          for (const childEntry of entries) {
            queue.push({ entry: childEntry, path: path + '/' + childEntry.name });
          }
        }
      }

      // Convert back to FileList object.
      const dt = new DataTransfer();

      for (let item of fileEntries) {
        const file = await getFile(item.entry);
        if (file) {
          const blobData = [await file.arrayBuffer()];
          const newFile = new File(blobData, item.path, { type: file.type });
          dt.items.add(newFile);
        }
      }

      return dt.files;
    };

    // Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
    const readAllDirectoryEntries = async (directoryReader) => {
      const entries = [];
      let readEntries = await readEntriesPromise(directoryReader);

      while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await readEntriesPromise(directoryReader);
      }

      return entries;
    };

    // Wrap readEntries in a promise to make working with readEntries easier
    const readEntriesPromise = async (directoryReader) => {
      try {
        return await new Promise((resolve, reject) => {
          directoryReader.readEntries(resolve, reject);
        });
      } catch (err) {
        console.error(`Problem with reading entries.\n${err}`);
      }
    };

    const highlight = (evt) => {
      evt.preventDefault();
      dropArea?.classList.add('drag-drop');
    };

    const unHighlight = (evt) => {
      evt.preventDefault();

      // Remove highlight only when you totally move out from screen.
      if (evt.clientX === 0 && evt.clientY === 0) {
        dropArea?.classList.remove('drag-drop', 'drag-drop--active');
        if (dropArea) dropArea.style.pointerEvents = 'unset';
      }

      if (evt.type === 'drop' && !dropArea?.contains(evt.target)) {
        dropArea?.classList.remove('drag-drop', 'drag-drop--active');
        if (dropArea) dropArea.style.pointerEvents = 'unset';
      }
    };

    const highlightArea = (evt) => {
      evt.preventDefault();
      dropArea.classList.add('drag-drop', 'drag-drop--active');
    };

    const unHighlightArea = async (evt) => {
      evt.preventDefault();

      const dropAreaCoordinates = dropArea.getBoundingClientRect();

      if (
        evt.clientY < dropAreaCoordinates.top ||
        evt.clientY > dropAreaCoordinates.bottom ||
        evt.clientX < dropAreaCoordinates.left ||
        evt.clientX > dropAreaCoordinates.right
      ) {
        dropArea.classList.remove('drag-drop--active');
      }

      if (evt.type === 'drop') {
        dropArea.classList.add('drag-drop', 'drag-drop--active');
        dropArea.style.pointerEvents = 'none';
        uploaderContainerSpinnerElement.style.display = 'block';

        try {
          const files = await getAllFileEntries(evt.dataTransfer.items);
          mriUploadInput.files = files;
          mriChangeInputHandler();
          dropArea.classList.remove('drag-drop', 'drag-drop--active');
          uploaderContainerSpinnerElement.style.display = 'none';
          dropArea.style.pointerEvents = 'unset';
        } catch (error) {
          dropArea.classList.remove('drag-drop--active');
          dropArea.classList.add('drag-drop', 'drag-drop--error');
          mriUploadModalStatusMessage.style.display = 'none';
          uploaderContainerSpinnerElement.style.display = 'none';
          dropArea.style.pointerEvents = 'unset';

          if (dropArea === dropAreaDefault) {
            dropInfoMessage.style.display = 'none';
            dropErrorMessage.style.display = 'flex';

            setTimeout(() => {
              dropInfoMessage.style.display = 'flex';
              dropErrorMessage.style.display = 'none';
            }, 3000);
          } else {
            dropErrorMessage.style.display = 'flex';
            dropErrorMessage.style.position = 'static';
            setTimeout(() => {
              dropErrorMessage.style.display = 'none';
            }, 3000);
          }

          setTimeout(() => dropArea.classList.remove('drag-drop', 'drag-drop--error'), 3000);
        }
      }
    };

    const changeDragDropArea = (newDropArea) => {
      // Delete events and attributes on previous area
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, highlightArea);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unHighlightArea);
      });
      dropArea.removeAttribute('data-drag-drop');

      // Set new area
      dropArea = newDropArea;
      dropErrorMessage = dropArea.querySelector('.drag-drop__message--error');
      if (!dropErrorMessage) {
        dropErrorMessage = document.querySelector('.drag-drop__message--error');
      }

      // Set events and attributes on new area
      dropArea.setAttribute('data-drag-drop', 'true');
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlightArea);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unHighlightArea);
      });
    };

    // Initializing here!
    ['dragenter', 'dragover'].forEach((eventName) => {
      document.addEventListener(eventName, highlight);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      document.addEventListener(eventName, unHighlight);
    });

    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea?.addEventListener(eventName, highlightArea);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea?.addEventListener(eventName, unHighlightArea);
    });
    // Drag&Drop --> END

    const resetModalFields = () => {
      mriUploadModalId.innerText = '';
      mriUploadModalName.innerText = '';
      mriUploadModalBirth.innerText = '';
      mriUploadModalDescription.innerText = '';
      mriUploadModalAcq.innerText = '';
      mriUploadModalStatusMessage.style.display = 'none';
      mriUploadModalConfirmBtn.innerText = UPLOAD_BTN_MESSAGE.UPLOAD;
    };

    const getMriInfo = (buffer) => {
      mriInfo = getMriHeaderInfo(buffer);

      mriUploadModalId.innerText = mriInfo.id;
      mriUploadModalName.innerText = mriInfo?.name || '';
      mriUploadModalBirth.innerText = mriInfo.birthDate;
      mriUploadModalDescription.innerText = mriInfo.description;
      mriUploadModalAcq.innerText = `${mriInfo.acquisitionDate} - ${mriInfo.acquisitionTime}`;
      GET_HEADER_INFO_TRIGGER = false;
    };

    const readFileAsync = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });

    const checkFileFormat = async (file) => {
      const contentBuffer = await readFileAsync(file);
      const array = new Uint8Array(contentBuffer);
      const start = 128;
      const end = 132;
      let s = '';

      for (let i = start; i < end; ++i) {
        s += String.fromCharCode(array[i]);
      }

      if (s === CORRECT_FORMAT) {
        if (GET_HEADER_INFO_TRIGGER) {
          getMriInfo(contentBuffer);
        }

        return false;
      }
      return true;
    };

    const checkFileSuffix = async (file) => {
      const suffixLength = file.name.includes(CORRECT_SUFFIX_MAX) ? MAX_SUFFIX_LENGTH : MIN_SUFFIX_LENGTH;
      const suffix = file.name.slice(-suffixLength);

      if (suffix === '.dcm' && GET_HEADER_INFO_TRIGGER) {
        const contentBuffer = await readFileAsync(file);
        getMriInfo(contentBuffer);
      }

      return CORRECT_SUFFIX_LIST.includes(suffix);
    };

    const checkFiles = async (files) => {
      for (let i = 0; i < files.length; i++) {
        if (!(await checkFileSuffix(files[i]))) {
          const result = await checkFileFormat(files[i]);

          if (result) {
            files = [].concat(files.slice(0, i), files.slice(i + 1));
            i = -1;
          }
        }
      }

      return files;
    };

    const getMriImage = async () => {
      const data = new FormData();
      let files = [...(mriUploadInput.files.length ? mriUploadInput.files : mriUploadDirInput.files)];
      files = await checkFiles(files);

      files.forEach((file) => {
        data.append('dicom', file);
      });

      return data;
    };

    const resetInputs = () => {
      mriUploadInput.value = '';
      mriUploadDirInput.value = '';
    };

    const disableForm = () => {
      mriUploadInput.disabled = true;
      mriUploadDirInput.disabled = true;
      mriUploadModalInputs.forEach((elem) => elem.classList.add('disabled'));
      mriUploadModalDirInputs.forEach((elem) => elem.classList.add('disabled'));
      mriUploadModalConfirmBtn.disabled = true;
      mriUploadModalCancelBtn.disabled = true;
    };

    const enableForm = () => {
      mriUploadInput.disabled = false;
      mriUploadDirInput.disabled = false;
      mriUploadModalInputs.forEach((elem) => elem.classList.remove('disabled'));
      mriUploadModalDirInputs.forEach((elem) => elem.classList.remove('disabled'));
      mriUploadModalConfirmBtn.disabled = false;
      mriUploadModalCancelBtn.disabled = false;
    };

    const disableWhenUpload = () => {
      disableForm();
    };

    const enableWhenUploaded = () => {
      resetInputs();
      enableForm();
    };

    const hideMriUploadModal = () => {
      mriUploadModal.style.display = 'none';
      overlay.style.display = 'none';
    };

    const showMriUploadModal = () => {
      mriUploadModal.style.display = 'flex';
      overlay.style.display = 'block';
    };

    const hideElement = (element) => {
      element.style.display = 'none';
    };

    const showElement = (element) => {
      element.style.display = 'flex';
    };

    const setErrorMessage = () => {
      mriUploadModalStatusMessage.innerText = errorMessageIncorrectFormat;
      mriUploadModalStatusMessage.classList.add('mri-upload-modal__status-message--error');
      mriUploadModalStatusMessage.style.display = 'flex';
    };

    const setSuccessMessage = () => {
      mriUploadModalStatusMessage.innerText = successMessageUploadDone;
      mriUploadModalStatusMessage.classList.remove('mri-upload-modal__status-message--error');
      mriUploadModalStatusMessage.style.display = 'flex';
      mriUploadModalConfirmBtn.innerText = UPLOAD_BTN_MESSAGE.DONE;
    };

    const clearMessage = () => {
      mriUploadModalStatusMessage.style.display = 'none';
      mriUploadModalStatusMessage.classList.remove('mri-upload-modal__status-message--error');
    };

    const setStatus = async (custom_message) => {
      API.getPatientInfo().then(({ data }) => {
        // Save all MRI list info to local storage so that React can retrieve it from there
        localStorage.setItem(STORAGE_KEY_PATIENT_MRI, JSON.stringify(data.mriList));
        localStorage.setItem(STORAGE_KEY_PATIENT_INFO, JSON.stringify(data.patientInfo));
        // Create a custom event to notify React that there are updates in local storage
        const mriEvent = new CustomEvent('localstorage-update', {
          detail: { key: STORAGE_KEY_PATIENT_MRI, newValue: data.mriList },
        });
        const infoEvent = new CustomEvent('localstorage-update', {
          detail: { key: STORAGE_KEY_PATIENT_INFO, newValue: data.patientInfo },
        });
        // Dispatch this event to the window
        window.dispatchEvent(mriEvent);
        window.dispatchEvent(infoEvent);

        if (mriStatus && mriStatusIcon) {
          switch (data.statusMriText) {
            case MRI_STATUS_CODE.RECEIVED:
              mriStatus.innerText = MRI_STATUS_MESSAGE.INQUEUE;
              mriStatus.style.color = '#000000';
              mriStatus.classList.add('examination-details__mri-processing--waiting');
              break;

            case MRI_STATUS_CODE.INPROCESS:
              mriStatus.innerText = MRI_STATUS_MESSAGE.INPROCESS;
              mriStatus.style.color = '#000000';
              mriStatus.classList.add('examination-details__mri-processing--waiting', 'examination-details__mri-processing--active');
              break;

            case MRI_STATUS_CODE.PROCESSING_FAILED:
            case MRI_STATUS_CODE.CONVERSION_FAILED:
              mriStatus.innerText = custom_message || MRI_STATUS_MESSAGE.CONVERSION_FAILED;
              mriStatus.style.color = '#FF0000';
              mriStatus.classList.remove('examination-details__mri-processing--waiting', 'examination-details__mri-processing--active');
              mriStatusIcon.classList.remove('examination-details__status--active');
              mriStatusIcon.classList.add('examination-details__status--arrow');
              mriStatusIcon.innerText = '→';
              enableWhenUploaded();
              break;

            case MRI_STATUS_CODE.DONE:
              mriStatus.innerText = MRI_STATUS_MESSAGE.DONE;
              mriStatus.style.color = '#000000';
              mriStatus.classList.remove('examination-details__mri-processing--waiting', 'examination-details__mri-processing--active');
              mriStatusIcon.classList.add('examination-details__status--active');
              mriStatusIcon.classList.remove('examination-details__status--arrow');
              mriStatusIcon.innerText = '✓';
              enableWhenUploaded();
              break;

            // no default
          }
        }

        if (generateReportABtn) generateReportABtn.innerText = data.reportAHasPdf ? '再ダウンロード' : '開く';
        if (generateReportAThumbnail) generateReportAThumbnail.style.opacity = data.reportAHasPdf ? 1 : 0.5;

        if (generateReportSBtn) generateReportSBtn.innerText = data.reportSHasPdf ? '再ダウンロード' : '開く';
        if (generateReportSThumbnail) generateReportSThumbnail.style.opacity = data.reportSHasPdf ? 1 : 0.5;

        // Apply MRI completion status based on reportA, reportZ, or reportS data availability.
        if (mriUploadModalConfirmBtn) {
          if (data.reportA || data.reportZ || data.reportS) {
            mriUploadModalConfirmBtn.dataset.mriDone = 'true';
          } else {
            mriUploadModalConfirmBtn.dataset.mriDone = 'false';
          }
        }

        // Update the last acquisition date on the currently active tab if provided.
        if (data.lastAcqdateStr) {
          const currentTab = document.querySelector('.examination-details__links-item--active');
          if (currentTab) {
            currentTab.innerHTML = currentTab.innerHTML.replace('---', data.lastAcqdateStr);
          }
        }

        // Toggle the reportLPIXEL button states and link activation based on report availability.
        if (data.reportLPIXEL) {
          [generateReportLpixel, generateReportLpixelJpg].forEach((element) => {
            element?.classList.remove('btn--disabled');
            if (element) element.href = element.dataset.link;
          });
        } else {
          [generateReportLpixel, generateReportLpixelJpg].forEach((element) => {
            element?.classList.add('btn--disabled');
            if (element) element.href = '#';
          });
        }

        // Toggle the visibility of the uploader elements based on the number of MRI exams in the list
        if (uploaderContainerElement && uploaderContainerEmptyElement && uploaderContainerFillElement) {
          if (data.mriList.length > 0) {
            // If there are MRI exams, show the filled uploader and hide the empty state
            uploaderContainerElement.classList.remove('examination-details__exam--empty');
            uploaderContainerEmptyElement.style.display = 'none';
            uploaderContainerFillElement.style.display = 'flex';
          } else {
            // If there are no MRI exams, show the empty uploader state and hide the filled state
            uploaderContainerElement.classList.add('examination-details__exam--empty');
            uploaderContainerEmptyElement.style.display = 'flex';
            uploaderContainerFillElement.style.display = 'none';
          }
        }
      });
    };

    // It's a hack, but it's better than rewriting everything.
    window.PatientPage = {};
    window.PatientPage.setStatus = setStatus;

    // PROGRESS BAR --> START
    const ProgressBarStages = {
      LOAD_MRI: 'MRI画像をアップロード中です',
      RESPONSE_PENDING: 'もうしばらくお待ちください',
    };
    let prevPercent = parseInt(mriUploadProgressBarNumber?.innerText || '0', 10);

    const progressBarHandler = (evt, totalSize) => {
      const { loaded } = evt;
      const currentPercent = Math.round((loaded / totalSize) * 100);
      let finalPercent = prevPercent + currentPercent;
      if (finalPercent >= 100) {
        finalPercent = 100;
      }

      mriUploadProgressBarValue.style.width = `${finalPercent}%`;
      mriUploadProgressBarNumber.innerText = `${finalPercent}%`;
      if (finalPercent >= 100) mriUploadProgressBarText.innerText = ProgressBarStages.RESPONSE_PENDING;
      else mriUploadProgressBarText.innerText = ProgressBarStages.LOAD_MRI;
      mriUploadProgressBarWrapper.style.display = 'flex';
      mriUploadModalConfirmBtn.style.display = 'none';
    };

    const hideAndResetProgressBar = () => {
      mriUploadProgressBarWrapper.style.display = 'none';
      mriUploadProgressBarValue.style.width = '0%';
      mriUploadProgressBarNumber.innerText = '0';
      mriUploadProgressBarText.innerText = '';
      mriUploadModalConfirmBtn.style.display = 'flex';
    };
    // PROGRESS BAR --> END

    const mriUploadConfirmHandler = async () => {
      // const { target } = evt;

      // Checks if MRI already exist in DB and show warning message for rewrite it.
      // if (target.dataset.mriDone === 'true') {
      //   showElement(mriRewriteModal);
      //   return;
      // }

      // Checks hospital_patient_id if it not the same as in DB and show warning message for rewrite it.
      if (idRewriteTrigger && currentHospitalPatientId !== mriInfo?.id && mriInfo?.id !== undefined) {
        idRewriteModalNewValueField.forEach((elem) => (elem.innerText = mriInfo.id));
        showElement(idRewriteModal);
        return;
      }

      // Checks acquisition if it not the same as in visit MRI, show warning message.
      const acquisitionDateFromVisit = moment(currentAcquisitionDate, true);
      const acquisitionDateFromMRI = moment(mriInfo?.acquisitionDate, true);
      if (acquisitionDateFromVisit.isValid() && acquisitionDateFromMRI.isValid()) {
        const acquisitionDateDifferenceInDays = Math.abs(acquisitionDateFromVisit.diff(acquisitionDateFromMRI, 'days'));
        if (acquisitionDateRewriteTrigger && acquisitionDateDifferenceInDays >= 90) {
          acquisitionDateRewriteModalNewValueField.forEach((elem) => (elem.innerText = mriInfo.acquisitionDate));
          showElement(acquisitionDateRewriteModal);
          return;
        }
      }

      // hideElement(mriRewriteModal);
      hideElement(idRewriteModal);
      hideElement(acquisitionDateRewriteModal);
      disableWhenUpload();

      const mriUrl = mriUploadModalConfirmBtn.dataset.link;
      mriUploadModalConfirmBtn.innerText = UPLOAD_BTN_MESSAGE.UPLOADING;

      const UUID = uuidv4();
      let size = 0;
      let totalSize = 0;
      let offset = 0;
      let dicomPartIndex = 0;
      const dicomFilesAll = mriDataForUploading.getAll('dicom');
      const dicomFilesParts = [];

      for (let i = 0; i < dicomFilesAll.length; i++) {
        size += dicomFilesAll[i].size;
        totalSize += dicomFilesAll[i].size;

        if (size >= SIZE_LIMIT_BYTES) {
          dicomFilesParts.push(dicomFilesAll.slice(offset, i));

          size = 0;
          offset = i;
        }

        if (dicomFilesAll.length - 1 === i) {
          dicomFilesParts.push(dicomFilesAll.slice(offset));
        }
      }

      const sendDicomParts = (part) => {
        const dicomData = new FormData();
        const isFinal = dicomPartIndex === dicomFilesParts.length - 1;
        part.forEach((elem) => dicomData.append('dicom', elem));

        if (isFinal) {
          const birthdate = mriDataForUploading.get('birthdate');
          const hospitalPatientId = mriDataForUploading.get('hospital-patient-id');

          if (birthdate) dicomData.append('birthdate', birthdate);
          if (hospitalPatientId) dicomData.append('hospital-patient-id', hospitalPatientId);
        }

        // Saves previous progress bar percentage.
        prevPercent = parseInt(mriUploadProgressBarNumber.innerText, 10);

        axios({
          method: 'post',
          url: mriUrl,
          data: dicomData,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Part': isFinal ? 'final' : 'part',
            'Content-Id': UUID,
          },
          onUploadProgress: (evt) => progressBarHandler(evt, totalSize),
        })
          .then(async () => {
            dicomPartIndex += 1;

            if (dicomPartIndex === dicomFilesParts.length) {
              // Reset progress bar
              hideAndResetProgressBar();

              // if (mriDataForUploading.has('hospital-patient-id')) {
              //   formIdField.value = mriInfo.id;
              //   idRewriteModalPrevValueField.forEach((elem) => {
              //     elem.innerText = mriInfo.id;
              //   });
              // }

              // if (mriDataForUploading.has('birthdate')) {
              //   formBirthDateField.value = mriInfo.birthDate;
              //   acquisitionDateRewriteModalPrevValueField.forEach((elem) => {
              //     elem.innerText = mriInfo.birthDate;
              //   });
              // }

              idRewriteTrigger = true;
              acquisitionDateRewriteTrigger = true;

              setSuccessMessage();
              setStatus(); // Set patient status MRI status/Report status
              enableWhenUploaded();
              hideMriUploadModal();
              changeDragDropArea(dropAreaDefault);
              dropArea?.classList.remove('drag-drop', 'drag-drop--active');
              uploaderContainerSpinnerElement.style.display = 'none';
              dropArea.style.pointerEvents = 'unset';
              return;
            }

            sendDicomParts(dicomFilesParts[dicomPartIndex]);
          })
          .catch(async (err) => {
            console.error(`Problem with request/response.\n${err}`);
            if (err.response && err.response.data && err.response.data.msgUser === 'dicom conversion error') {
              // Reset progress bar
              hideAndResetProgressBar();

              idRewriteTrigger = true;
              acquisitionDateRewriteTrigger = true;

              setSuccessMessage();
              setStatus(`${MRI_STATUS_MESSAGE.CONVERSION_FAILED}\n${err.response.data.hint_msg}`);
              hideMriUploadModal();
              changeDragDropArea(dropAreaDefault);
              dropArea?.classList.remove('drag-drop', 'drag-drop--active');
              uploaderContainerSpinnerElement.style.display = 'none';
              dropArea.style.pointerEvents = 'unset';
            }
          });
      };

      sendDicomParts(dicomFilesParts[dicomPartIndex]);
    };

    const mriUploadCancelHandler = () => {
      hideMriUploadModal();
      enableWhenUploaded();
      changeDragDropArea(dropAreaDefault);
      dropArea?.classList.remove('drag-drop', 'drag-drop--active');
      uploaderContainerSpinnerElement.style.display = 'none';
      dropArea.style.pointerEvents = 'unset';
    };

    const mriChangeInputHandler = async () => {
      resetModalFields();
      mriUploadModalConfirmBtn.innerText = UPLOAD_BTN_MESSAGE.PROCESSING;
      mriUploadModalConfirmBtn.disabled = true;

      mriInfo = undefined;
      clearMessage();
      showMriUploadModal();
      changeDragDropArea(mriUploadModal);

      mriDataForUploading = await getMriImage();

      mriUploadModalConfirmBtn.innerText = UPLOAD_BTN_MESSAGE.UPLOAD;
      mriUploadModalConfirmBtn.disabled = false;

      GET_HEADER_INFO_TRIGGER = true;

      if (!mriDataForUploading.get('dicom')) {
        resetInputs();
        setErrorMessage();
        mriUploadModalConfirmBtn.disabled = true;
      } else {
        mriUploadModalConfirmBtn.disabled = false;
      }
    };

    mriUploadInput?.addEventListener('change', mriChangeInputHandler);
    mriUploadDirInput?.addEventListener('change', mriChangeInputHandler);
    mriUploadModalConfirmBtn?.addEventListener('click', mriUploadConfirmHandler);
    mriUploadModalCancelBtn?.addEventListener('click', mriUploadCancelHandler);
    // mriRewriteModalConfirmBtn?.addEventListener('click', (evt) => {
    //   hideElement(mriRewriteModal);
    //   mriUploadConfirmHandler(evt);
    // });
    // mriRewriteModalCancelBtn?.addEventListener('click', () => hideElement(mriRewriteModal));
    idRewriteModalConfirmBtn?.addEventListener('click', (evt) => {
      idRewriteTrigger = false;
      // mriDataForUploading.append('hospital-patient-id', mriInfo.id);
      mriUploadConfirmHandler(evt);
      hideElement(idRewriteModal);
    });
    idRewriteModalCancelBtn?.addEventListener('click', () => {
      // idRewriteTrigger = false
      // mriUploadConfirmHandler(evt);
      idRewriteTrigger = true;
      acquisitionDateRewriteTrigger = true;
      hideElement(idRewriteModal);
      hideElement(acquisitionDateRewriteModal);
    });
    acquisitionDateRewriteModalConfirmBtn?.addEventListener('click', (evt) => {
      acquisitionDateRewriteTrigger = false;
      // mriDataForUploading.append('birthdate', mriInfo.birthDate);
      mriUploadConfirmHandler(evt);
    });
    acquisitionDateRewriteModalCancelBtn?.addEventListener('click', () => {
      // acquisitionDateRewriteTrigger = false;
      // mriUploadConfirmHandler(evt);
      idRewriteTrigger = true;
      acquisitionDateRewriteTrigger = true;
      hideElement(idRewriteModal);
      hideElement(acquisitionDateRewriteModal);
    });
    rewriteModalCloseBtns.forEach((btn) =>
      btn.addEventListener('click', () => {
        hideElement(idRewriteModal);
        hideElement(acquisitionDateRewriteModal);
        idRewriteTrigger = true;
        acquisitionDateRewriteTrigger = true;
        mriDataForUploading.delete('birthdate');
        mriDataForUploading.delete('hospital-patient-id');
      }),
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[entries.length - 1].isIntersecting) {
        mriUploadModal.style.zIndex = '98';
      } else {
        mriUploadModal.style.zIndex = '102';
      }
    });
    // if (mriRewriteModal) observer.observe(mriRewriteModal);
    if (idRewriteModal) observer.observe(idRewriteModal);
    if (acquisitionDateRewriteModal) observer.observe(acquisitionDateRewriteModal);
  } catch (err) {
    console.error(err);
  }

  // TODO: Move somewhere else or rewrite it.
  // Self registration analytics
  const analyticsRadioBtns = document.querySelectorAll('#analytics-radio-btn');
  if (analyticsRadioBtns.length) {
    analyticsRadioBtns.forEach((parentElement) => {
      const radioBtn = parentElement.querySelector('input[type="radio"]');
      const inputField = parentElement.querySelector('input[type="text"]');
      inputField.addEventListener('input', ({ target }) => (radioBtn.value = target.value));
      inputField.addEventListener('focus', () => (radioBtn.checked = true));
      radioBtn.addEventListener('click', () => inputField.focus());
    });
  }
  // Self registration analytics

  EmailValidationInit();
  PasswordValidationInit();
  SendEmailManuallyInit();
  ShowNotificationModalInit();
  ShowUnreadNotificationInit();
  MainNavInit();
  EmailSmsTemplateInit();
  ConfirmationSheetInit();
  PatientVisitInit();
  SupportModalInit();
  MailSmsHistoryModalInit();
  NiivueInit();
  ToggleHandlerInit();
  DoctorComment();
  PrepaidRegistration();

  // REACT PART
  // Sets global Japanese locale for 'moment' library.
  moment.locale('ja');

  // Sets global object of PatientTable for further work.
  window.PatientTable = {};

  // Applies listeners to all 'react' modals.
  const modals = Array.from(document.querySelectorAll('[data-modal-name]'));
  if (modals.length) {
    modals.forEach((modalElement) => {
      modalElement.addEventListener('click', ({ target }) => {
        const { dataset } = target;
        MainModalCreate(dataset);
      });
    });
  }

  // Render of patient table.
  const parent = document.querySelector('#react-table');
  if (parent) {
    ReactDOM.render(<PatientTable />, parent);
  }

  // Special case...
  const removeVisitBtnElement = document.querySelector('#removeVisitBtn');
  const SPECIAL_TIME = 2;
  if (removeVisitBtnElement) {
    removeVisitBtnElement.addEventListener('click', (evt) => {
      evt.preventDefault();
      const dataset = {
        ...removeVisitBtnElement.dataset,
        modalName: 'removeVisit',
        onAccept: (removeModalCb, disableModalCb) => {
          if (disableModalCb) disableModalCb(true);

          let flashMessage = '';
          let flashType = FlashMessageType.Success;

          API.removeVisit(dataset.patientId, dataset.visitId)
            .then((res) => {
              const { lastVisit, visitToRedirect } = res.data;
              if (lastVisit) {
                flashMessage = res.data.msgUser;
                setTimeout(() => (window.location.href = window.location.origin), SPECIAL_TIME * 1000);
              } else {
                flashMessage = res.data.msgUser;
                setTimeout(
                  () => (window.location.href = [].concat(window.location.href.split('/').slice(0, -1), visitToRedirect).join('/')),
                  (SPECIAL_TIME * 1000) / 2,
                );
              }
            })
            .catch((err) => {
              flashMessage = err.response.data.msgUser;
              flashType = FlashMessageType.Error;
            })
            .finally(() => {
              if (removeModalCb) removeModalCb();
              FlashMessageCreate({ message: flashMessage, type: flashType, isSelfDestroyable: true, time: SPECIAL_TIME * 1000 });
            });
        },
      };
      MainModalCreate(dataset);
    });
  }

  // It's not just about the authentication part anymore, it's a mechanism through which you can replace certain components with React.
  const AuthTemplates = [
    {
      id: 'react-components-tmp-auth-login',
      component: Login,
    },
    {
      id: 'react-components-tmp-auth-mfa',
      component: Mfa,
    },
    {
      id: 'react-components-tmp-auth-request-reset',
      component: RequestReset,
    },
    {
      id: 'react-components-tmp-auth-reset',
      component: Reset,
    },
    {
      id: 'react-components-tmp-auth-reset-done',
      component: ResetDone,
    },
    {
      id: 'react-components-tmp-patient-register',
      component: PatientRegister,
    },
    {
      id: 'react-components-tmp-patient-register-visit',
      component: PatientRegisterVisit,
    },
    {
      id: 'react-components-patient-mri-list',
      component: PatientMriList,
    },
    {
      id: 'react-components-patient-create-visit-button',
      component: PatientCreateVisitButton,
    },
    {
      id: 'react-components-patient-mri-viewer-selector',
      component: PatientMriViewerSelector,
    },
    {
      id: 'react-components-patient-personal-info',
      component: PatientPersonalInfo,
    },
  ];

  AuthTemplates.forEach((template) => {
    const parent = document.querySelector(`#${template.id}`);
    if (parent) {
      const theme = createTheme({
        palette: {
          primary: {
            main: Colors.cerulean,
          },
          error: {
            main: Colors.burntSienna,
          },
          warning: {
            main: Colors.orangePeel,
          },
          success: {
            main: Colors.fruitSalad,
          },
          info: {
            main: Colors.ceruleanDark,
          },
          grey: {
            main: Colors.mobster,
          },
        },
      });
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <template.component dataset={Object.assign({}, parent.dataset)} />
        </ThemeProvider>,
        parent,
      );
    }
  });
  // REACT PART
};

Init()
  .then(() => {
    // Check if the current URL matches the patient page format
    if (window.location.pathname.match(/hospital\/patient\/\d+\/\d+/)) {
      // Execute when the page initializes
      if (window?.PatientPage?.setStatus) window.PatientPage.setStatus();
      // Then repeatedly call every 10 seconds
      setInterval(() => {
        if (window?.PatientPage?.setStatus && !document.hidden) window.PatientPage.setStatus();
      }, 10000);
    }
    setTimeout(() => {
      window.scroll(0, 0);
      loadingScreenOff();
      FlashMessageDetect(); // Init here after load ALL JS.
    }, delayForLoadingScreen);
  })
  .catch((err) => {
    console.error(err);
  });
