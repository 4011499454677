const PasswordValidationInit = () => {
  const password2 = document.querySelector('#password2');

  if (password2) {
    const password1 = document.querySelector('#password');

    const validate = (evt, elem) => {
      password1.setCustomValidity('');
      password2.setCustomValidity('');

      if (evt.target.value !== elem.value && elem.value && evt.target.value) {
        evt.target.setCustomValidity('パスワードが一致しません');
      }
    };

    password1.addEventListener('input', (evt) => validate(evt, password2));
    password2.addEventListener('input', (evt) => validate(evt, password1));
  }
};

export default PasswordValidationInit;
