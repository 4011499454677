import axios from 'axios';
import moment from 'moment';

import ShowUnreadNotificationInit from './show-unread-notification';

import { Modal } from '../utils';

const ElementTypes = {
  CLOSE: 'close-btn',
  OPEN: 'notification-open',
  READ: 'read',
};

const mainNavLink = document.querySelector('#main-nav-notification-link');
const mainNav = document.querySelector('#main-nav');
const overlay = document.querySelector('#overlay');

let notifications;
let notification;
let notificationListElement;
let notificationListListeners;
let notificationElement;
let notificationListeners;
let notificationModalShow = false;

const getNotificationListTemplate = () => `
  <div class="notification-modal">
    <h2>${notifications.length ? (notificationModalShow ? '未読のお知らせ' : 'お知らせ') : '現在新しいお知らせはありません。'}</h2>
    ${
      notifications.length
        ? `
      <div class="notification-modal__table-header">
        <span class="notification-modal__number">#</span>
        <span class="notification-modal__date">日付</span>
        ${notificationModalShow ? '' : '<span class="notification-modal__flag">既読済</span>'}
        <span class="notification-modal__title">タイトル</span>
        <span class="notification-modal__description">内容</span>
      </div>
      <ul class="notification-modal__list">
        ${notifications
          .map(
            ({ created, delivered, title, message, id }, i) => `
          <li class="notification-modal__item" data-id="${id}" data-type="notification-open">
            <span class="notification-modal__number" data-type="notification-open">${i + 1}</span>
            <span class="notification-modal__date" data-type="notification-open">${moment(created).format('YYYY-MM-DD')}</span>
            ${
              notificationModalShow
                ? ''
                : `<span class="notification-modal__flag notification-modal__flag--color" data-type="notification-open">${
                    delivered ? '✓' : ''
                  }</span>`
            }
            <span class="notification-modal__title" data-type="notification-open"><div data-type="notification-open">${title}</div></span>
            <span class="notification-modal__description" data-type="notification-open"><div data-type="notification-open">${message}</div></span>
          </li>`,
          )
          .join('')}
      </ul>`
        : ''
    }
    <button class="notification-modal__close-btn btn-close btn-close--circle" type="button" data-type="close-btn"></button>
  </div>
`;

const getNotificationTemplate = () => `
  <div class="notification-modal">
    <h3>${notification.title}</h3>
    <div class="notification-modal__text-field">${notification.message}</div>
    <button class="btn btn--main" type="button" data-type="read" data-id="${notification.id}">戻る</button>
    <button class="notification-modal__close-btn btn-close btn-close--circle" data-type="close-btn" type="button"></button>
  </div>
`;

const createElement = (templateCb) => {
  const temp = document.createElement('div');
  temp.insertAdjacentHTML('beforeend', templateCb());
  return temp.lastElementChild;
};

const closeModal = () => {
  notificationListListeners?.removeListener();
  notificationListeners?.removeListener();
  overlay.style.display = 'none';
};

const modalHandler = async ({ target }) => {
  if (target.dataset.type === ElementTypes.CLOSE) {
    if (notificationModalShow) {
      notificationModalShow = false;
    }

    closeModal();
    return;
  }

  if (target.dataset.type === ElementTypes.OPEN) {
    let parent = target;
    while (parent.tagName !== 'LI') {
      parent = parent.parentNode;
    }
    notification = notifications.filter(({ id }) => id === parseInt(parent.dataset.id, 10))[0];
    notificationElement = createElement(getNotificationTemplate);
    notificationListeners = new Modal(notificationElement, true);
    notificationListListeners?.removeListener();
    notificationListeners.addListener();
    notificationElement.addEventListener('click', modalHandler);
    document.body.append(notificationElement);
  }

  if (target.dataset.type === ElementTypes.READ) {
    await axios.put('/api/hospital/notification/read', { status: true, id: target.dataset.id });
    notificationListeners?.removeListener();
    ShowUnreadNotificationInit();
    onClickNotificationBtn();
  }
};

const onClickNotificationBtn = async () => {
  const response = await axios.get('/api/hospital/notification');
  notifications = response.data;
  notifications.forEach((elem) => (elem.created = new Date(elem.created)));
  notifications.sort((a, b) => b.created - a.created);

  if (notificationModalShow && !notifications.length) {
    return;
  }

  if (notificationModalShow) {
    notifications = notifications.filter(({ delivered }) => !delivered);
  }

  notificationListElement = createElement(getNotificationListTemplate);
  notificationListListeners = new Modal(notificationListElement, true);
  notificationListListeners.addListener();
  notificationListElement.addEventListener('click', modalHandler);

  mainNav.checked = false;
  overlay.style.display = 'block';
  document.body.append(notificationListElement);
};

const ShowNotificationModalInit = async () => {
  if (mainNavLink) {
    mainNavLink.addEventListener('click', onClickNotificationBtn);

    const response = await axios.get('/api/hospital/notification/session/check');
    notificationModalShow = response.data.showNotification;

    if (notificationModalShow) {
      onClickNotificationBtn();
    }
  }
};

export default ShowNotificationModalInit;
