import { Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { TPatientMriListStatusIcon } from './PatientMriListStatusIcon.types';

import { MRIStatus } from '../../types';

const StatusText: { [key in Lowercase<keyof typeof MRIStatus>]?: string } = {
  done: '完了',
  failed: '失敗',
  inprocess: '実施中',
  received: '待機中',
};

const PatientMriListStatusIcon: FC<TPatientMriListStatusIcon> = ({ status }) => {
  const theme = useTheme();

  const StatusColor: { [key in Lowercase<keyof typeof MRIStatus>]?: any } = {
    done: theme.palette.success.main,
    failed: theme.palette.error.main,
    inprocess: '#000000',
    received: '#707070',
  };

  return <Typography sx={{ fontSize: '12px', fontWeight: 400, color: StatusColor[status] }}>{StatusText[status]}</Typography>;
};

export default PatientMriListStatusIcon;
