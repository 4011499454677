import { Modal } from '../utils';

const MailSmsHistoryModalInit = () => {
  const modal = [...document.querySelectorAll('.mshm')];

  if (modal.length) {
    const openModalBtn = [...document.querySelectorAll('.open-history-modal-btn')];

    modal.forEach((element, i) => {
      const modalCloseBtn = element.querySelector('button');
      const modalListeners = new Modal(element);

      openModalBtn[i].addEventListener('click', modalListeners.addListener);

      modalCloseBtn.addEventListener('click', modalListeners.removeListener);
    });
  }
};

export default MailSmsHistoryModalInit;
