import { Niivue } from '@niivue/niivue';

const NiivueInit = () => {
  const canvas = document.querySelector('#glcanvas');

  if (canvas) {
    const nv = new Niivue();
    nv.opts.textHeight = 0.02;
    nv.attachToCanvas(canvas);
    nv.graph.autoSizeMultiplanar = true;
    nv.setSliceType(nv.sliceTypeMultiplanar);
    nv.setCrosshairColor([0, 0.9, 1, 0.95]);
    nv.loadVolumes(window.volumeList);

    // Some stupid ui fixes...
    const header = document.querySelector('header.header');
    document.querySelector('.wrapper').style.padding = 0;
    canvas.setAttribute('height', window.innerHeight - header.offsetHeight);
  }
};

export default NiivueInit;
