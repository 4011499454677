import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';

import { TDataSet } from '../types';

type TConfirmationReport = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const ConfirmationReport: FC<TConfirmationReport> = ({ dataset, isDisabled, setIsDisabled, removeModal }) => {
  const [dScore, setDScore] = useState(dataset.dScore ? !!+dataset.dScore : false);

  return (
    <form className='confirmation-report' action={dataset.link} method='POST' onSubmit={() => setIsDisabled(true)}>
      <h2 className='confirmation-report__title'>結果レポートを確定します。</h2>
      <ul>
        <li className='confirmation-report__item'>結果レポートの内容が、受診者のMy Pageに掲載されます。</li>
        <li className='confirmation-report__item'>受診者の登録メールアドレス宛に、結果レポートの案内メールが配信されます。</li>
      </ul>
      <p style={{ margin: 0 }}>
        但し、診察所見及び検査結果等を総合的に考慮した上で、医師の判断において専門外来等への受診の必要性を認める場合には、総合判定に関してＤ判定にチェックを入れ、「確定」ボタンを押してください。
      </p>
      <FormControlLabel
        sx={{ width: 'auto' }}
        control={
          <Checkbox
            name='d-score'
            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
            checked={dScore}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => setDScore(evt.target.checked)}
          />
        }
        label='D判定を適用する'
      />
      <div className='confirmation-report__btn-wrapper'>
        <button className='btn btn--additional btn--fix' type='button' onClick={removeModal} disabled={isDisabled}>
          キャンセル
        </button>
        <button className={`btn btn--main btn--fix ${isDisabled ? 'btn--loading' : ''}`} type='submit' disabled={isDisabled}>
          {dScore ? 'D判定で確定' : '確定'}
        </button>
      </div>
    </form>
  );
};

export default ConfirmationReport;
